import { FormHelperText, Grid2, Skeleton, Stack } from '@mui/material';
import { TariffsContext } from 'contexts/tariffs/TariffsContext';
import { useField } from 'formik';
import { useContext, useEffect, useState } from 'react';
import TariffPlanTypeCard from './TariffPlanTypeCard';

const PlanTypeCardSelect = ({...props}) => {

    const [planTypes, setPlanTypes] = useState([]);
    const [, meta, ] = useField(props);
    const { getPlanTypes } = useContext(TariffsContext);
    const [loading, setLoading] = useState(true);
    
    useEffect(()=> {
        getPlanTypes().then((response) => {
            setPlanTypes(response);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }, []);

    if(loading) {
        return (
            <Stack spacing={2}>
                <Grid2 container spacing={2}>
                    {[1,2,3].map((i) => (
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4 }} key={i}>
                            <Skeleton variant="rectangular" height={300} />
                        </Grid2>
                    ))}
                </Grid2>
            </Stack>
        );
    }

    return (
        <Stack spacing={2}>
            <Grid2 container spacing={2}>
                {planTypes.map((tariffType) => (
                    <Grid2 item size={{ xs: 12, sm: 6, md: 4 }} key={tariffType.id}>
                        <TariffPlanTypeCard key={tariffType.id} tariffType={tariffType} {...props}/>
                    </Grid2>
                ))}
            </Grid2>
            {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </Stack>
    );
}

export default PlanTypeCardSelect;