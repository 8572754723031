import useAxios from "utils/useAxios";

export const useTradingFinancialDocumentsAPI = () => {

    const BASE_URL = "/core/agents/entities/findocs/";
    const BASE_ENTITY_URL = (id) => `/core/agents/entities/${id}/findocs`;

    const {nonCachedRequest} = useAxios();

    const find = (filters = [], page_size, page, sortColumns, callBack, errorCallBack) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += filter && filter.name ? `&${filter.name.replace('.','__')}=${filter.value}` : filter.id ? `&${filter.id.replace('.','__')}=${filter.value}` :"");
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const process = (id, filters, callBack, errorCallBack) => {
        if (!filters) {
            console.error("cannot process fin documents without any filters!");
            throw new Error("Cannot Process Financial Documents without filters!");
        }

        if(!id) {
            console.error("cannot process fin documents without any id!");
            throw new Error("Cannot Process Financial Documents without id!");
        }
        
        let url = `${BASE_URL}process/?`;
        filters.map((filter) => url += filter && filter.name ? `&${filter.name.replace('.','__')}=${filter.value}` : filter.id ? `&${filter.id.replace('.','__')}=${filter.value}` :"");
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const approve = (id, filters, callBack, errorCallBack) => {
        if (!filters) {
            console.error("cannot process fin documents without any filters!");
            throw new Error("Cannot Process Financial Documents without filters!");
        }

        if(!id) {
            console.error("cannot process fin documents without any id!");
            throw new Error("Cannot Process Financial Documents without id!");
        }
        
        let url = `${BASE_URL}approve?`;
        filters.map((filter) => url += filter && filter.name ? `&${filter.name.replace('.','__')}=${filter.value}` : filter.id ? `&${filter.id.replace('.','__')}=${filter.value}` :"");
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const summary = (filters, callBack, errorCallBack) => {
        if (!filters) {
            console.error("cannot process fin documents without any filters!");
            throw new Error("Cannot Process Financial Documents without filters!");
        }
        let url = `${BASE_URL}summary/?`;
        filters.map((filter) => url += filter && filter.name ? `&${filter.name.replace('.','__')}=${filter.value}` : filter.id ? `&${filter.id.replace('.','__')}=${filter.value}` :"");
        nonCachedRequest(url, callBack, errorCallBack);
    };

    return {
        find,
        process,
        summary,
        approve
    }
};