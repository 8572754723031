import { useTranslation } from "react-i18next";
import IndieStaticSelect from "./forms/IndieStaticSelect";

const SelectYesNo = ({...params}) => {

    const { t } = useTranslation();
    const options = [
        { value: false, label: t("label.form.no") },
        { value: true, label: t("label.form.yes") }
    ];
    
    return (
        <IndieStaticSelect
            {...params}
            options={options}
            sx={{ width: 300, ...params.sx }}
            />
    )
}

export default SelectYesNo;