import {
    Badge,
    Box,
    ButtonBase, IconButton, Skeleton, styled,
    useMediaQuery
} from "@mui/material";
import { H6, Small, Tiny } from "components/Typography";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBox from "components/flexbox/FlexBox";
import useAuth from "hooks/useAuth";
import { Fragment, useContext, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PopoverLayout from "./PopoverLayout"; // styled components
import { LogoutOutlined } from "@mui/icons-material";
import ThemeIcon from "icons/ThemeIcon";
import { SettingsContext } from "contexts/settingsContext";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    padding: 5,
    marginLeft: 4,
    borderRadius: 30,
    border: `1px solid ${theme.palette.divider}`,
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));
const StyledSmall = styled(Small)(({ theme }) => ({
    display: "block",
    cursor: "pointer",
    padding: "5px 1rem",
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const ProfilePopoverV2 = ( { compact } ) => {

    const { logout, user } = useAuth();
    const { settings, saveSettings } = useContext(SettingsContext);
    
    const handleChangeTheme = (value) => {
        saveSettings({ ...settings, theme: value });
    };

    if(user && !user.username) {
        console.log(JSON.stringify(user));
    }

    return (
        <Box
            sx={{
                padding: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: "auto",
                width: "90%"
            }}
        >
            <Badge
                overlap="circular"
                variant="dot"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                sx={{
                    alignItems: "center",
                    "& .MuiBadge-badge": {
                        width: 11,
                        height: 11,
                        right: "4%",
                        borderRadius: "50%",
                        border: "2px solid #fff",
                        backgroundColor: "success.main",
                    },
                    margin:"auto"
                }}
            >
                <AppAvatar
                    src={user?.avatar || "/static/avatar/001-man.svg"}
                    sx={{
                        width: 28,
                        height: 28,
                    }}
                />
            </Badge>

            {!compact && (
            <Box mx={2}>
                <H6>{user.first_name ? `${user.first_name} ${user.last_name}` : user.username}</H6>
            </Box>
            )}
            {!compact && (
            settings.theme === "light" ? (
                <StyledIconButton onClick={() => handleChangeTheme("dark")}>
                <ThemeIcon />
                </StyledIconButton>
            ) : (
                <StyledIconButton onClick={() => handleChangeTheme("light")}>
                <ThemeIcon />
                </StyledIconButton>
            ))}
            {!compact && (
            <StyledIconButton
                onClick={() => {
                    logout();
                    toast.error("You Logout Successfully");
                }}
                sx={{margin: "auto"}}
            >
                <LogoutOutlined fontSize="small"/>
            </StyledIconButton>
            )}
        </Box>
    );
}

const ProfilePopover = () => {

    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const { logout, user } = useAuth();
    const [open, setOpen] = useState(false);
    const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const { t } = useTranslation();
    const handleMenuItem = (path) => {
        navigate(path);
        setOpen(false);
    };

    const handleLogout = () => {
        logout();
        navigate("/login");
    };

    return (
        <Fragment>
            <StyledButtonBase
                disableRipple
                ref={anchorRef}
                onClick={() => setOpen(true)}
            >
                <Badge
                    overlap="circular"
                    variant="dot"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    sx={{
                        alignItems: "center",
                        "& .MuiBadge-badge": {
                            width: 11,
                            height: 11,
                            right: "4%",
                            borderRadius: "50%",
                            border: "2px solid #fff",
                            backgroundColor: "success.main",
                        },
                    }}
                >
                    {upSm && (
                        <Small mx={1} color="text.secondary">
                            {t("label.layout.greeting")},{" "}
                            <Small fontWeight="600" display="inline">
                                {user.first_name ? user.first_name : user.username}
                            </Small>
                        </Small>
                    )}
                    <AppAvatar
                        src={user?.avatar || "/static/avatar/001-man.svg"}
                        sx={{
                            width: 28,
                            height: 28,
                        }}
                    />
                </Badge>
            </StyledButtonBase>

            <PopoverLayout
                hiddenViewButton
                maxWidth={230}
                minWidth={200}
                popoverOpen={open}
                anchorRef={anchorRef}
                popoverClose={() => setOpen(false)}
                title={
                    <FlexBox alignItems="center" gap={1}>
                        <AppAvatar
                            src={user?.avatar || "/static/avatar/001-man.svg"}
                            sx={{
                                width: 35,
                                height: 35,
                            }}
                        />

                        <Box>
                            <H6>{`${user.first_name} ${user.last_name}`}</H6>
                            <Tiny display="block" fontWeight={500} color="text.disabled">
                                {user.email}
                            </Tiny>
                        </Box>
                    </FlexBox>
                }
            >
                <Box pt={1}>
                    
                    <StyledSmall
                        onClick={() => {
                            handleLogout();
                            toast.error("You Logout Successfully");
                        }}
                    >
                        {t("Logout")}
                    </StyledSmall>
                </Box>
            </PopoverLayout>
        </Fragment>
    );
};

export { ProfilePopoverV2 };
export default ProfilePopover;
