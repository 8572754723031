import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const IndieStaticSelect = ({ options, label, ...props }) => {

    if(!props.name) {
        throw new Error('IndieStaticSelect requires a name prop');
    }
    
    const [field, meta] = useField(props);
    const formik = useFormikContext();
    // The select key is used to force rerender when field.value is updated
    // issues were noted when updating formik state in other components.
    // in these situations the component was not rerendered.
    const [selectKey, setSelectKey] = useState(0);

    useEffect(() => {
        setSelectKey(selectKey + 1);
    }, [field.value]);

    useEffect(() => {
        if(field.value && !formik.values[`${props.name}_object`]) {
            formik.setFieldValue(`${props.name}_object`, options.find(option => option.value === field.value));
        }
    }, []);

    return (
        <FormControl>
            <InputLabel id={`label_${props.id}`} sx={{ml: -2, mt: field.value ? 1 : 0}} disabled={props.disabled} error={meta.error ? true : false}>{label}</InputLabel>
            <Select
                key={selectKey}
                {...props}
                {...field}
                MenuProps={MenuProps}
                onChange={(e) => {
                    formik.setFieldValue(`${props.name}_object`, options.find(option => option.value === e.target.value));
                    field.onChange(e);
                    if(props.onChange) {
                        props.onChange(e);
                    }
                }}
                defaultValue={""}
                labelId={`label_${props.id}`}
                disabled={props.disabled}
                variant="standard"
                size='small'
                error={meta.error ? true : false}
                renderValue={props.multiple && (
                    (selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, index) => (
                            <Chip key={value} id={`${props.id}_chip_${index}`} label={options.filter((o) => o.value === value)[0].label} />
                        ))}
                        </Box>
                    )
                )}
            >
                <MenuItem value="" disabled sx={{display:"none"}}></MenuItem>
                {options && options.length > 0 && options.map((option, index) => (
                    <MenuItem id={`${props.id}_menu_option_${index}`} key={index} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {meta.error ? (
                <FormHelperText id={`${props.id}_helper_text`} error sx={{ml:0}}>{meta.error}</FormHelperText>
            ) : null}
        </FormControl>
        
    );
}

export default IndieStaticSelect;