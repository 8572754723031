import IndieButton from "./IndieButton";

const { CancelRounded } = require("@mui/icons-material");
const { useTranslation } = require("react-i18next")

const IndieCancelButton = ({label, ...params}) => {
    const { t } = useTranslation();
    return <IndieButton startIcon={<CancelRounded/>} variant="outlined" color="error" {...params}>{label ? label : t("label.button.cancel")}</IndieButton>
}

export default IndieCancelButton;