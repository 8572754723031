import { CircularProgress } from "@mui/material";
import IndieButton from "./IndieButton";

const { UploadFileOutlined } = require("@mui/icons-material");
const { useTranslation } = require("react-i18next")

const IndieUploadButton = ({label, loading, ...params}) => {
    const { t } = useTranslation();
    return <IndieButton startIcon={loading ? <CircularProgress color="inherit" size={18} /> : <UploadFileOutlined/>} variant="outlined" color="success" {...params}>{label ? label : t("label.button.save")}</IndieButton>
}

export default IndieUploadButton;