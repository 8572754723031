import { createContext, useEffect, useState } from "react";
import { useCommunityInstallationsAPI } from "service/communities/CommunityInstallationsAPIService";


export const CommunityInstallationContext = createContext();

export const CommunityInstallationProvider = ({ id, children, ...props }) => {

    const [installation, setInstallation] = useState(null);
    const [gettingInstallation, setGettingInstallation] = useState(true);

    const service = useCommunityInstallationsAPI();

    useEffect(() => {
        // Fetch installation data
        // setInstallation(data);
        service.get(id).then((response) => {
            setInstallation(response.data);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setGettingInstallation(false);
        });
    }, []);

    return (
        <CommunityInstallationContext.Provider value={{ installation, gettingInstallation }}>
            {children}
        </CommunityInstallationContext.Provider>
    );
}