import { alpha, Box, ButtonBase, styled } from "@mui/material";
import { Paragraph, Span } from "components/Typography";
import AuthContext from "contexts/JWTAuth";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { navigations } from "../layout-parts/navigation";
import SidebarAccordion from "./SidebarAccordion";
const NavItemButton = styled(ButtonBase)(({ theme, active }) => ({
    height: 44,
    width: "100%",
    borderRadius: 8,
    marginBottom: 4,
    padding: "0 18px",
    justifyContent: "flex-start",
    transition: "all 0.15s ease",
    ...(active && {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
    }),
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));
const ListLabel = styled(Paragraph)(({ theme, compact }) => ({
    fontWeight: 700,
    fontSize: "12px",
    marginTop: "20px",
    marginLeft: "15px",
    marginBottom: "10px",
    textTransform: "uppercase",
    transition: "all 0.15s ease",
    color: theme.palette.text.secondary,
    ...(compact === "true" && {
        opacity: 0,
        width: 0,
    }),
}));
const ExternalLink = styled("a")(({ theme }) => ({
    overflow: "hidden",
    whiteSpace: "pre",
    marginBottom: "8px",
    textDecoration: "none",
    color: theme.palette.text.primary,
}));
const StyledText = styled(Span)(({ theme, compact, active }) => ({
    whiteSpace: "nowrap",
    paddingLeft: "0.8rem",
    transition: "all 0.15s ease",
    fontSize: "13px",
    fontWeight: 500,
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    ...(compact === "true" && {
        opacity: 0,
        width: 0,
    }),
}));
const BulletIcon = styled("div")(({ theme, active }) => ({
    width: 4,
    height: 4,
    marginLeft: "10px",
    marginRight: "8px",
    overflow: "hidden",
    borderRadius: "50%",
    background: active ? theme.palette.primary.main : theme.palette.text.disabled,
    boxShadow: active ? `0px 0px 0px 3px ${theme.palette.primary[200]}` : "none",
}));
const BadgeValue = styled(Box)(({ compact, theme }) => ({
    color: "white",
    fontSize: "12px",
    fontWeight: 500,
    padding: "1px 6px",
    overflow: "hidden",
    borderRadius: "300px",
    backgroundColor: theme.palette.primary.main,
    display: compact === "true" ? "none" : "unset",
})); // Common icon style

const iconStyle = (active) => ({
    fontSize: 18,
    marginRight: "4px",
    color: active ? "primary.main" : "text.secondary",
});

const MultiLevelMenu = (props) => {
    const { sidebarCompact } = props;
    const navigate = useNavigate();
    const { pathname } = useLocation(); // handle active current page
    const { profile } = useContext(AuthContext);
     // handle navigate to another route or page
    const { t } = useTranslation()
    const handleNavigation = (path) => navigate(path); //   RECURSIVE FUNCTION TO RENDER MULTI LEVEL MENU

    const activeRoute = (path) => {
        return (pathname === path || pathname.startsWith(path) ? 1 : 0)
    };
    const renderLevels = (data) => {

        if(!profile) {
            console.debug("profile not loaded yet!");
            return;
        }
        return data.map((item, index) => {

            let filteredPerms = [];
            let superuser = profile.is_superuser;
            let groups = profile.groups.map((group) => group.name);
            
            let hasGroups = false;
            if(groups && item.groups) {
                hasGroups = groups.some((group) => item.groups.includes(group)) && !groups.some((group) => item.groups.includes("!" + group));
            } else if (!item.groups) {
                // if no groups are defined, then the user has access
                hasGroups = true;
            }

            if(item.auth && profile) {
                filteredPerms = profile.permissions.filter((perm) => {
                    return (item.auth.includes(perm.codename) && hasGroups) || profile.is_superuser;
                })
            }

            if( item.auth !== "all" && filteredPerms.length === 0 && superuser === false) {
                return "";
            } 
            
            if (item.type === "label")
                return (
                    <ListLabel key={index} compact={sidebarCompact}>
                        {t(item.label)}
                    </ListLabel>
                );

            if (item.children) {
                return (
                    <SidebarAccordion
                        id={item.name.replaceAll(".", "_")}
                        key={index}
                        item={item}
                        sidebarCompact={sidebarCompact}
                    >
                        {renderLevels(item.children)}
                    </SidebarAccordion>
                );
            } else if (item.type === "extLink") {
                return (
                    <ExternalLink
                        key={index}
                        href={item.path}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <NavItemButton key={item.name} id={item.name.replaceAll(".", "_")} name={item.name.replaceAll(".", "_")} active={0}>
                            {(() => {
                                if (item.icon) {
                                    return <item.icon sx={iconStyle(0)} />;
                                } else {
                                    return (
                                        <span className="item-icon icon-text">{item.iconText}</span>
                                    );
                                }
                            })()}

                            <StyledText
                                compact={sidebarCompact}
                                active={activeRoute(item.path)}
                            >
                                {t(item.name)}
                            </StyledText>

                            <Box mx="auto" />

                            {item.badge && (
                                <BadgeValue compact={sidebarCompact}>
                                    {item.badge.value}
                                </BadgeValue>
                            )}
                        </NavItemButton>
                    </ExternalLink>
                );
            } else {
                return (
                    <Box key={index}>
                        <NavItemButton
                            id={item.name.replaceAll(".", "_")}
                            name={item.name.replaceAll(".", "_")}
                            key={item.name}
                            className="navItem"
                            active={activeRoute(item.path)}
                            onClick={() => handleNavigation(item.path)}
                        >
                            {item?.icon ? (
                                <item.icon sx={iconStyle(activeRoute(item.path))} />
                            ) : (
                                <BulletIcon active={activeRoute(item.path)} />
                            )}

                            <StyledText
                                compact={sidebarCompact ? "true" : "false"}
                                active={activeRoute(item.path)}
                            >
                                {t(item.name)}
                            </StyledText>

                            <Box mx="auto" />

                            {item.badge && (
                                <BadgeValue compact={sidebarCompact ? "true" : "false"}>
                                    {item.badge.value}
                                </BadgeValue>
                            )}
                        </NavItemButton>
                    </Box>
                );
            }
        });
    };

    return <>{renderLevels(navigations)}</>;
};

export default MultiLevelMenu;
