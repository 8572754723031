import { Close, PersonAddAlt1Outlined } from '@mui/icons-material';
import { Alert, Box, Dialog, DialogContent, DialogTitle, Divider, Grid2, IconButton, RadioGroup, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { Stack, useMediaQuery } from '@mui/system';
import EntityFormFields from 'components/forms/entities/EntityFormFields';
import IndieBackButton from 'components/forms/IndieBackButton';
import IndieIBANTextField from 'components/forms/IndieIBANTextField';
import IndieNextStepButton from 'components/forms/IndieNextStepButton';
import IndieRadioButton from 'components/forms/IndieRadioButton';
import IndieSaveButton from 'components/forms/IndieSaveButton';
import IndiePaymentMethodsSelect from 'components/forms/tariffs/IndiePaymentMethodsSelect';
import SelectYesNo from 'components/SelectYesNo';
import { CommunityContext } from 'contexts/communities/CommunityContext';
import { TariffsContextProvider } from 'contexts/tariffs/TariffsContext';
import { UserFeedbackContext } from 'contexts/UserFeedbackContext';
import { Form, Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import ApplicationList from 'page-sections/common/ApplicationList';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { validateFiscalNumber, validateIBAN } from 'utils/utils';
import * as Yup from 'yup';
import { IndieLabelAndValue } from './NewCommunityDialog';
import { useEntitiesAPI } from 'service/EntitiesAPIService';

const NewCommunityMemberConfigureMembersDialog = ({ open, onClose, onSuccess, installation = null }) => {

    const isMobileVersion = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([]);
    const [validationSchemas, setValidationSchemas] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const { community, updateInstallation } = useContext(CommunityContext);
    const { setAndShowErrorMessage } = useContext(UserFeedbackContext);
    const { communityId } = useParams();

    const initialValues = {
        installation_id: installation?.id,
        installation: installation,
        community_id: communityId,
        consumer_installation_owner: false,
        consumer: {
            id: null,
            name: "",
            email: "",
            phone: "",
            fiscal_number: "",
            address: "",
            zip_code: "",
            country: ""
        },
        producer_installation_owner: false,
        producer: {
            id: null,
            name: "",
            email: "",
            phone: "",
            fiscal_number: "",
            address: "",
            zip_code: "",
            country: "",
            iban: ""
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = (values) => {
        if (activeStep < steps.length - 1) {
            handleNext();
        } else {
            const installationId = values.installation_id;
            const _values = _.cloneDeep(values);
            delete _values.installation;
            
            setSubmitting(true);
            updateInstallation(installationId, values).then(() => {
                if(onSuccess) {
                    onSuccess();
                } else {
                    onClose();
                }
            }).catch((e) => {
                console.error(e);
                setAndShowErrorMessage("error.api.generic");
            }).finally(() => {
                setSubmitting(false);
            });
        }
    };

    if (!community && open) {
        console.error("community is required in NewCommunityMemberDialog");
        setAndShowErrorMessage("error.api.generic");
        onClose();
    }

    useEffect(() => {
        const _steps = [];
        const _validationSchemas = [];

        if (!installation) {
            _steps.push({
                label: t("label.step.selectInstallation"),
                component: <SelectInstallationStep />
            });

            _validationSchemas.push(Yup.object().shape({
                installation_id: Yup.number().required(t("error.forms.mandatory"))
            }));
        }

        _steps.push({
            label: t("label.step.selectConsumerMember"),
            component: <SelectConsumerMemberStep />
        });

        _validationSchemas.push(
            Yup.object().shape({
                consumer: Yup.object().shape({
                    fiscal_number: Yup.string().required(t("error.forms.mandatory"))
                        .matches(/^\d{9}$/, t('error.forms.fiscalNumberDigits'))
                        .test('valid-nif', t('error.forms.fiscalNumberInvalid'), (value) => {
                            return validateFiscalNumber(value);
                        }),
                    zip_code: Yup.string().required(t("error.forms.mandatory"))
                }),
                consumer_payment_method: Yup.string().required(t("error.forms.mandatory"))
            })
        );

        _steps.push({
            label: t("label.step.selectProducerMember"),
            component: <SelectProducerMemberStep />
        });

        _validationSchemas.push(
            Yup.object().shape({
                producer: Yup.object().shape({
                    fiscal_number: Yup.string().when('$installation', {
                        is: (installation) => installation.physical_unit.production_deliverypoint !== null,
                        then: (schema) => schema.required(t("error.forms.mandatory"))
                            .matches(/^\d{9}$/, t('error.forms.fiscalNumberDigits'))
                            .test('valid-nif', t('error.forms.fiscalNumberInvalid'), (value) => {
                                return validateFiscalNumber(value);
                            }),
                        otherwise: (schema) => schema,
                    }),
                    zip_code: Yup.string().when('$installation', {
                        is: (installation) => installation.physical_unit.production_deliverypoint !== null,
                        then: (schema) => schema.required(t("error.forms.mandatory")),
                        otherwise: (schema) => schema,
                    }),
                    iban: Yup.string().when('$installation', {
                        is: (installation) => installation.physical_unit.production_deliverypoint !== null,
                        then: (schema) => schema
                            .required(t("error.forms.mandatory"))
                            .test('valid-iban', t('error.forms.invalidIBAN'), (value) => {
                                return validateIBAN(value);
                            }
                            ),
                        otherwise: (schema) => schema,
                    })
                }),
                producer_is_auto_invoice_agreement: Yup.string().when('$installation', {
                    is: (installation) => installation.physical_unit.production_deliverypoint !== null,
                    then: (schema) => schema
                        .required(t("error.forms.mandatory")),
                    otherwise: (schema) => schema,
                }),
                producer_has_auto_invoice_agreement: Yup.string().when('$producer_is_auto_invoice_agreement', {
                    is: (producer_is_auto_invoice_agreement) => producer_is_auto_invoice_agreement === true,
                    then: (schema) => schema
                        .required(t("error.forms.mandatory")),
                    otherwise: (schema) => schema,
                })
            })
        );

        _steps.push({
            label: t("label.step.confirm"),
            component: <ConfirmStep />
        });

        _validationSchemas.push(Yup.object().shape({}));

        setSteps(_steps);
        setValidationSchemas(_validationSchemas);
    }, [installation]);

    useEffect(() => {
        setActiveStep(0);
    }, [open]);

    return (
        <TariffsContextProvider>
            <Dialog open={open} fullScreen>
                <DialogTitle>
                    <Grid2 container>
                        <Grid2 item size={{ xs: 9, sm: 6 }}>
                            <Stack spacing={2} direction="row" alignItems="center">
                                <IconButton onClick={handleClose}>
                                    <Close />
                                </IconButton>
                                <PersonAddAlt1Outlined color="primary" />
                                <Typography variant="subtitle1" color="textPrimary">{t("label.button.configureMember")}</Typography>
                            </Stack>
                        </Grid2>
                        <Grid2 item size={{ xs: 3, sm: 6 }} alignItems={"center"}>
                            <Box sx={{ display: 'flex', flexGrow: 1, height: "100%", alignItems: "center", justifyContent: "flex-end" }}>
                                <Stepper activeStep={1} orientation="horizontal">
                                    <Step key={0}>
                                        <StepLabel>{!isMobileVersion && t("label.title.configureInstallation")}</StepLabel>
                                    </Step>
                                    <Step key={1}>
                                        <StepLabel>{!isMobileVersion && t("label.button.configureMember")}</StepLabel>
                                    </Step>
                                    <Step key={2}>
                                        <StepLabel>{!isMobileVersion && t("label.title.configureTariffPlan")}</StepLabel>
                                    </Step>
                                </Stepper>
                            </Box>
                        </Grid2>
                    </Grid2>

                </DialogTitle>
                <DialogContent>
                    <Divider fullWidth sx={{ mb: 2 }} />
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchemas[activeStep]}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <Form>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index) => (
                                        <Step key={index}>
                                            <StepLabel>{step.label}</StepLabel>
                                            <StepContent>
                                                <Box maxWidth="lg">
                                                    <Stack spacing={2} direction="column">

                                                        {step.component}
                                                        <Stack spacing={2} direction="row">
                                                            {activeStep > 0 && <IndieBackButton id="back_button" disabled={submitting} onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)} />}
                                                            {activeStep < steps.length - 1 && (
                                                                <IndieNextStepButton id="next_step_button" type="submit" variant="contained" />
                                                            )}
                                                            {activeStep === steps.length - 1 && (
                                                                <IndieSaveButton id="save_button" type="submit" variant="contained" disabled={submitting} loading={submitting} />
                                                            )}
                                                        </Stack>

                                                    </Stack>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                                {/**<pre>{JSON.stringify(formik.errors, false, 2)}</pre>*/}
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </TariffsContextProvider>
    );
}

const SelectInstallationStep = ({ params }) => {

    const { getInstallationsWithMissingMembers, submitting } = useContext(CommunityContext);
    const [selectedInstallation, setSelectedInstallation] = useState(null);

    const { setFieldValue, errors } = useFormikContext();
    const { t } = useTranslation();

    const columns = useMemo(() => [
        {
            accessor: 'physical_unit.consumption_deliverypoint',
            Header: t("label.table.header.consumptionDeliverypoint")
        },
        {
            accessor: 'physical_unit.production_deliverypoint',
            Header: t("label.table.header.productionDeliverypoint")
        },
        {
            accessor: 'physical_unit.zip_code',
            Header: t("label.table.header.zipCode")
        },
        {
            accessor: 'physical_unit.address',
            Header: t("label.table.header.address")
        },
    ], []);

    const handleRowClick = (row) => {
        setSelectedInstallation(row);
        setFieldValue("installation_id", row.id);
        setFieldValue("installation", row);
    };



    return (
        <Stack spacing={2}>
            <ApplicationList
                id="community_installations_table"
                dataFetcher={getInstallationsWithMissingMembers}
                columns={columns}
                rowOnClick2={handleRowClick}
                outlined
                selectable
                showAppBar
            />
            {errors.installation_id && <Typography variant="caption" color="error">{errors.installation_id}</Typography>}
        </Stack>
    );
}

const SelectConsumerMemberStep = () => {

    const { t } = useTranslation();
    const { setFieldValue, values } = useFormikContext();

    const setConsumerSameAsInstallationOwner = (e, checked) => {
        setFieldValue("consumer_same_as", "installation_owner");
        if (checked) {
            setFieldValue("consumer", values.installation.physical_unit.owner);
        }
    }

    const setConsumerManualInput = (e, checked) => {
        setFieldValue("consumer_same_as", "");
        if (checked) {
            setFieldValue("consumer", {
                id: null,
                name: "",
                email: "",
                phone: "",
                fiscal_number: "",
                address: "",
                zip_code: "",
                country: ""
            });
        }
    }

    return (
        <Stack spacing={2}>
            <RadioGroup name='consumer_same_as' value={values.consumer_same_as}>
                <IndieRadioButton id="consumer_same_as_io" name="consumer_same_as" value="installation_owner" label={t("label.form.installationOwner")} onChange={setConsumerSameAsInstallationOwner} />
                <IndieRadioButton id="consumer_same_as_manual" name="consumer_same_as" value="" label={t("label.form.manualInput")} onChange={setConsumerManualInput} />
            </RadioGroup>
            <EntityFormFields prefix={"consumer"} disabled={values.consumer_same_as !== ""} />
            <Stack spacing={3}>
                <Typography variant="subtitle2">{t("label.title.paymentsInformation")}</Typography>
                <Stack spacing={1}>
                    <IndiePaymentMethodsSelect
                        id="consumer_payment_method"
                        name="consumer_payment_method"
                        label={t("label.form.paymentMethod")}
                        agent={values.installation.community.agent}
                        />
                </Stack>
            </Stack>
        </Stack>
    );
}

const SelectProducerMemberStep = () => {
    const { t } = useTranslation();
    const { setFieldValue, values } = useFormikContext();
    const [disableIsAutoInvoiceAgreement, setDisableIsAutoInvoiceAgreement] = useState(true);
    const [disableHasAutoInvoiceAgreement, setDisableHasAutoInvoiceAgreement] = useState(true);
    const [disableIban, setDisableIban] = useState(false);
    const api = useEntitiesAPI();

    const setProducerSameAsInstallationOwner = (e, checked) => {
        setFieldValue("producer_same_as", "installation_owner");
        if (checked) {
            setFieldValue("producer", values.installation.physical_unit.owner);
        }
    }

    const setProducerSameAsConsumer = (e, checked) => {
        setFieldValue("producer_same_as", "consumer");
        if (checked) {
            setFieldValue("producer", values.consumer);
        }
    }

    const handleOnChangeProducerIsAuthInvoice = (e) => {
        setFieldValue("producer_has_auto_invoice_agreement", undefined);
        setDisableIsAutoInvoiceAgreement(false);
        setDisableHasAutoInvoiceAgreement(!e.target.value);
    }

    const setProducerManualInput = (e, checked) => {
        setFieldValue("producer_same_as", "");
        if (checked) {
            setFieldValue("producer", {
                id: null,
                name: "",
                email: "",
                phone: "",
                fiscal_number: "",
                address: "",
                zip_code: "",
                country: "",
                iban: ""
            });
        }
    }

    useEffect(() => {
        const entityId = values.producer.id; 
        const agentEntityId = values.installation.community.managing_entity.id;
    
        if (entityId) {
            api.fetchAgreement(entityId, agentEntityId, (response) => {
                const agreement = response.data;
                if (agreement) {
                    setFieldValue("producer_is_auto_invoice_agreement", agreement.is_auto_invoice);
                    setFieldValue("producer_has_auto_invoice_agreement", agreement.has_validation_code);
                    setDisableIsAutoInvoiceAgreement(true); 
                    setDisableHasAutoInvoiceAgreement(true); 
                } else {
                    setFieldValue("producer_is_auto_invoice_agreement", undefined);
                    setFieldValue("producer_has_auto_invoice_agreement", undefined);
                    setDisableIsAutoInvoiceAgreement(false); 
                    setDisableHasAutoInvoiceAgreement(false); 
                }
            }, (error) => {
                console.error("Erro ao buscar acordo:", error);
            });
        }
    }, [values.producer.id]);

    useEffect(() => {
        if (values.producer.iban !== "" && values.producer.iban !== null) {
            setDisableIban(true);
        } else {
            setDisableIban(false);
        }
        console.log(values.producer.iban_disabled)
    }, [values.producer.id]);

    if (values.installation.physical_unit.production_deliverypoint === null) {
        return (
            <Alert severity="info">{t("label.text.noProductionDeliveryPointNotRequired")}</Alert>
        );
    }
    return (
        <Stack spacing={2}>
            {values.physical_unit?.production_deliverypoint === "" && (
                <Alert severity="info">{t("label.text.noProductionDeliveryPointNotRequired")}</Alert>
            )}
            {values.physical_unit?.production_deliverypoint !== "" && (
                <Stack spacing={2}>
                    <RadioGroup name='producer_same_as_group' value={values.producer_same_as}>
                        <IndieRadioButton id="producer_same_as_io" name='producer_same_as' value="installation_owner" label={t("label.form.installationOwner")} onChange={setProducerSameAsInstallationOwner} />
                        <IndieRadioButton id="producer_same_as_c" name='producer_same_as' value="consumer" label={t("label.form.sameAsConsumer")} onChange={setProducerSameAsConsumer} />
                        <IndieRadioButton id="producer_same_as_manual" name='producer_same_as' value="" label={t("label.form.manualInput")} onChange={setProducerManualInput} />
                    </RadioGroup>
                    <EntityFormFields prefix={"producer"} showReceiptFormFields disabled={values.producer_same_as !== ""} disableReceiptFormFields />
                    <Stack spacing={3}>
                        <Typography variant="subtitle2">{t("label.title.receivingInformation")}</Typography>
                        <Stack spacing={1}>
                            <IndieIBANTextField
                                id={`producer.iban`}
                                name={`producer.iban`}
                                label={t("label.form.paymentIBAN")} 
                                sx={{ width: 400 }}
                                disabled={disableIban}
                            />
                            <SelectYesNo id="producer_is_auto_invoice_agreement" name="producer_is_auto_invoice_agreement" label={t("label.form.isAutoInvoicingAgreement")} onChange={handleOnChangeProducerIsAuthInvoice} disabled={disableIsAutoInvoiceAgreement}/>
                            <SelectYesNo id="producer_has_auto_invoice_agreement" name="producer_has_auto_invoice_agreement" label={t("label.form.hasAutoInvoicingAgreement")} disabled={disableHasAutoInvoiceAgreement} />
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}

const ConfirmStep = () => {
    const { t } = useTranslation();
    const formik = useFormikContext();
    const { community } = useContext(CommunityContext);

    const installation = formik.values.installation;
    const consumer = formik.values.consumer;
    const producer = formik.values.producer;

    return (
        <Stack spacing={2}>
            <Stack spacing={1}>
                <Typography variant='subtitle2' color='textSecondary'>{t("label.form.community")}</Typography>
                <Divider />
                <IndieLabelAndValue label={t("label.form.community")} value={community?.name} />
            </Stack>
            <Stack spacing={1}>
                <Typography variant='subtitle2' color='textSecondary'>{t("label.step.selectInstallation")}</Typography>
                <Divider />
                <IndieLabelAndValue label={t("label.form.consumptionDeliveryPoint")} value={installation?.physical_unit.production_deliverypoint} />
                <IndieLabelAndValue label={t("label.form.productionDeliveryPoint")} value={installation?.physical_unit.consumption_deliverypoint} />
                <IndieLabelAndValue label={t("label.form.zipCode")} value={installation?.physical_unit.zip_code} />
                <IndieLabelAndValue label={t("label.form.address")} value={installation?.physical_unit.address} />
            </Stack>
            <Stack spacing={1}>
                <Typography variant='subtitle2' color='textSecondary'>{t("label.step.selectConsumerMember")}</Typography>
                <Divider />
                <IndieLabelAndValue label={t("label.form.name")} value={consumer.name} />
                <IndieLabelAndValue label={t("label.form.fiscalNumber")} value={consumer.fiscal_number} />
                <IndieLabelAndValue label={t("label.form.zipCode")} value={consumer.zip_code} />
                <IndieLabelAndValue label={t("label.form.address")} value={consumer.address} />
            </Stack>
            <Stack spacing={1}>
                <Typography variant='subtitle2' color='textSecondary'>{t("label.step.selectProducerMember")}</Typography>
                <Divider />
                <IndieLabelAndValue label={t("label.form.name")} value={producer.name} />
                <IndieLabelAndValue label={t("label.form.fiscalNumber")} value={producer.fiscal_number} />
                <IndieLabelAndValue label={t("label.form.zipCode")} value={producer.zip_code} />
                <IndieLabelAndValue label={t("label.form.address")} value={producer.address} />
                <IndieLabelAndValue label={t("label.form.paymentIBAN")} value={producer.iban} />
                <IndieLabelAndValue label={t("label.form.isAutoInvoicingAgreement")} value={formik.values.producer_is_auto_invoice_agreement ? t("label.form.yes") : t("label.form.no")} />
                <IndieLabelAndValue label={t("label.form.hasAutoInvoicingAgreement")} value={formik.values.producer_has_auto_invoice_agreement ? t("label.form.yes") : t("label.form.no")} />
            </Stack>
        </Stack>
    );
}

export default NewCommunityMemberConfigureMembersDialog;