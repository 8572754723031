import useAxios from "utils/useAxios";

const BASE_URL = `/core/physical-units/`;

export const usePhysicalUnitsAPI = () => {
    
    const {api, cachedRequest, cachedRequest2, nonCachedRequest} = useAxios();

    const fetch = (id) => {
        let url = `${BASE_URL}${id}/`;
        return api.get(url);
    };

    const find = (filters=[], page=1, page_size = 10, sortColumns, callBack, errorCallBack) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const installationTypes = () => {
        return cachedRequest2(`installation-types`, BASE_URL + 'installation-types/');
    }

    const tensionLevels = (callBack, errorCallBack) => {
        cachedRequest(`tension-levels`, BASE_URL + 'tension-levels/', callBack);
    }

    const productionTypes = async (callBack, errorCallBack) => {
        cachedRequest(`production-types`, BASE_URL + 'production-types/', callBack);
    }

    const tariffPlans = (id) => {
        return api.get(`${BASE_URL}${id}/tariff_plans`);
    }

    const energy = (id, period, date) => {
        return api.get(`${BASE_URL}${id}/energy?period=${period}&date=${date}`);
    }

    const activeContracts = (id, filters = []) => {
        let url = `${BASE_URL}${id}/active_contracts/`;
        const params = new URLSearchParams();
        filters.forEach((filter) => {
            params.append(filter.id.replace('.', '__'), filter.value);
        });
    
        if (filters.length > 0) {
            url += `?${params.toString()}`;
        }
    
        return api.get(url);
    };

    return {
        fetch,
        installationTypes,
        tensionLevels,
        productionTypes,
        find,
        tariffPlans,
        energy,
        activeContracts
    }
};

