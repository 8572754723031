import { BatteryCharging50Rounded, HouseRounded, SolarPowerRounded } from "@mui/icons-material";
import { FormControl, FormHelperText, Grid2 } from "@mui/material";
import { useField } from "formik";
import { useMemo, useState } from "react";
import InstallationTypeCard from "./InstallationTypeCard";

const InstallationTypeSelectSlider = ({ ...props }) => {

    const [field, meta, helpers] = useField(props);
    const installationTypes = useMemo(() => [
        {
            code:"ic",
            icon: <HouseRounded />,
            name: "consumptionInstallation",
            description: "consumptionInstallationDescription",
        },
        {
            code:"ip",
            icon: <SolarPowerRounded />,
            name: "productionInstallation",
            description: "productionInstallationDescription",
        },
        {
            code:"ia",
            icon: <BatteryCharging50Rounded />,
            name: "batteryInstallation",
            description: "batteryInstallationDescription",
            disabled: true
        },
    ], []);

    const [selected, setSelected] = useState(field.value ? installationTypes.filter((t) => t.code === field.value?.code)[0] : null);


    const onInstallationSelected = (installationType) => {
        setSelected(installationType);
        helpers.setValue(installationType);
        if(props.onChange) {
            props.onChange(installationType);
        }
    }

    return (
        <FormControl>
            <Grid2 container spacing={2}>
                {installationTypes.map((installationType) => (
                    <Grid2 item size={{xs: 12, md:6, lg: 4}} key={installationType.id}>
                        <InstallationTypeCard
                            id={`installation_type_${installationType.code}_card`}
                            type={installationType}
                            hideStats={true}
                            selected={selected === installationType}
                            onClick={() => onInstallationSelected(installationType)}
                        />
                    </Grid2>
                ))}
            </Grid2>
            {meta.error && meta.touched ? (
                <FormHelperText id="installation_type_helper_text" error sx={{ ml: 0, pl: 0 }}>{meta.error}</FormHelperText>
            ) : null}
        </FormControl>
    );
}

export default InstallationTypeSelectSlider;