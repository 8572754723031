import { ArrowBack, Home } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Breadcrumbs, CardContent, IconButton, Skeleton, Stack, Tab } from "@mui/material";
import BreadcrumbLink from "components/breadcrumbs/BreadcrumbLink";
import IndieCard from "components/IndieCard";
import { H3 } from "components/Typography";
import { CommunityInstallationContext, CommunityInstallationProvider } from "contexts/communities/CommunityInstallationContext";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Routes, useNavigate, useParams } from "react-router-dom";

const InstallationPage = () => {

    const { id } = useParams();

    return (
        <CommunityInstallationProvider id={id}>
            <InstallationDetailsPageContents />
        </CommunityInstallationProvider>
    );
};

const InstallationDetailsPageContents = () => {

    const navigate = useNavigate();
    // We will use this to go back one level by popping elements from the url
    const backToUrl = window.location.href.split("/").slice(0, -2).join("/");
    //const { member, gettingMember } = useContext(CommunityMemberContext);
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(null);
    const { installation, gettingInstallation } = useContext(CommunityInstallationContext);

    const tabs = [
        "profile", "communities", "installations"
    ]
    const handleChange = (e, newValue) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        let tab = window.location.pathname.split("/").pop();
        setCurrentTab(_.includes(tabs, tab) ? tab : "profile");
    }, []);

    if (gettingInstallation) {
        return (<Skeleton variant="rectangular" height={400} />);
    }

    if (installation === null || installation === undefined) {
        navigate("/error404");
    }

    return (
        <Stack spacing={1}>
            <IndieCard>
                <CardContent>
                    <Stack direction="row" spacing={2}>
                        <IconButton component={Link} to={backToUrl} size="large" color="primary" aria-label="back">
                            <ArrowBack />
                        </IconButton>
                        <Stack direction="column" spacing={1}>
                            <Breadcrumbs>
                                <BreadcrumbLink value={"label.menu.home"} href="/" icon={<Home sx={{ mr: 0.5 }} fontSize="inherit" />} />
                                <BreadcrumbLink value={"label.title.installations"} href="/communities/installations" />
                                <BreadcrumbLink value={"label.title.viewInstallation"} />
                            </Breadcrumbs>
                            <H3>{installation.physical_unit?.consumption_deliverypoint} / {installation.physical_unit?.address}</H3>
                        </Stack>
                        <Box sx={{ flexGrow: 1, display: "inline-flex", justifyContent: "flex-end" }}>
                            {installation?.status}
                        </Box>
                    </Stack>
                </CardContent>
            </IndieCard>
            <IndieCard>
                <CardContent>
                    <TabContext value={currentTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} >
                                <Tab data-testid="tab_details" label={t("label.menu.profile")} index={0} value="profile" component={Link} to={"profile"} />
                                <Tab data-testid="tab_communities" label={t("label.title.communities")} index={1} value="communities" component={Link} to={"communities"} />
                                <Tab data-testid="tab_installations" label={t("label.title.installations")} index={2} value="installations" component={Link} to={"installations"} />
                            </TabList>
                        </Box>
                        <TabPanel value={currentTab} sx={{ pt: 2 }}>
                            <Routes>
                               
                            </Routes>
                        </TabPanel>
                    </TabContext>
                </CardContent>
            </IndieCard>
        </Stack>
    )
}

export default InstallationPage;