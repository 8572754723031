import { createContext, useEffect, useState } from "react";
import { useAgentAPIService } from "service/core/AgentAPIService";
import { useTariffsAPIService } from "service/core/TariffsAPIService";

export const TariffsContext = createContext();
export const BUSINESS_TYPE_COMMUNITIES = "communities";
export const BUSINESS_TYPE_POWER_TRADING = "aggregation";

export const TariffsContextProvider = ({id, initialBusinessType, ...props}) => {

    const tariffsService = useTariffsAPIService();
    const agentService = useAgentAPIService();
    
    const [tariffModel, setTariffModel] = useState(null);
    const [loadingTariffModel, setLoadingTariffModel] = useState(true);
    const [businessType, setBusinessType] = useState(initialBusinessType);

    const [planTypes, setPlanTypes] = useState(null);
    const [planTypeProducts, setPlanTypeProducts] = useState(null);
    const [productPlans, setProductPlans] = useState(null);
    const [planFees, setPlanFees] = useState(null);
    const [energyTransactions, setEnergyTransactions] = useState(null);

    const [lastPlanType, setLastPlanType] = useState(null);
    
    const getTariffModel = (id) => {
        return new Promise((resolve, reject) => {
            if (tariffModel) {
                resolve(tariffModel);
            } else {
                tariffsService.getTariffModel(id).then((response) => {
                    const loadedTariffModel = response.data;
                    setTariffModel(loadedTariffModel);
                    setBusinessType(loadedTariffModel.business_type);
                    resolve(loadedTariffModel);
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                });
            }
        });
    }

    const updateTariffModel = (tariffModel) => {
        return new Promise((resolve, reject) => {
            tariffsService.updateTariffModel(tariffModel.id, tariffModel).then((response) => {
                const updatedTariffModel = response.data;
                setTariffModel(updatedTariffModel);
                resolve(updatedTariffModel);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }

    const copyTariffModel = () => {
        return new Promise((resolve, reject) => {
            tariffsService.copyTariffModel(tariffModel.id).then((response) => {
                const updatedTariffModel = response.data;
                setTariffModel(updatedTariffModel);
                resolve(updatedTariffModel);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }

    const deleteTariffModel = () => {
        return new Promise((resolve, reject) => {
            tariffsService.deleteTariffModel(tariffModel.id).then(() => {
                setTariffModel(null);
                resolve(null);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }

    const getPlanTypes = () => {
        return new Promise((resolve, reject) => {
            if (planTypes) {
                resolve(planTypes);
            } else {
                tariffsService.planTypes(businessType).then((response) => {
                    const loadedPlanTypes = response;
                    setPlanTypes(loadedPlanTypes);
                    resolve(loadedPlanTypes);
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                });
            }
        });
    }

    const getProducts = (planTypeId) => {

        return new Promise((resolve, reject) => {
            if (planTypeProducts && lastPlanType === planTypeId) {
                resolve(planTypeProducts);
            } else {
                tariffsService.planTypeProducts(planTypeId).then((response) => {
                    const loadedPlanTypeProducts = response.data;
                    setPlanTypeProducts(loadedPlanTypeProducts);
                    resolve(loadedPlanTypeProducts);
                    setLastPlanType(planTypeId);
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                });
            }
        });
    }

    const getProductPlans = (productId) => {
        return new Promise((resolve, reject) => {
            tariffsService.productPlans(productId).then((response) => {
                const loadedProductPlans = response;
                setProductPlans(loadedProductPlans);
                resolve(loadedProductPlans);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }

    const getPlanFees = (planId) => {
        return new Promise((resolve, reject) => {
            if (planFees) {
                resolve(planFees);
            } else {
                tariffsService.planFees(planId).then((response) => {
                    const loadedPlanFees = response.data;
                    setPlanFees(loadedPlanFees);
                    resolve(loadedPlanFees);
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                });
            }
        });
    }

    const getEnergyTransactions = () => {
        return new Promise((resolve, reject) => {
            if(energyTransactions) {
                resolve(energyTransactions);
            } else {
                tariffsService.energyTransactions().then( (response) => {
                    const loadedEnergyTransactions = response;
                    setEnergyTransactions(loadedEnergyTransactions);
                    resolve(loadedEnergyTransactions);
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                }) 
            }
        });
    }

    const getReceiptMethods = () => {
        return tariffsService.receiptMethods();
    }

    const getAgentReceiptMethods = (agentId) => {
        return agentService.getReceiptMethods(agentId);
    }

    const getReceiptTerms = () => {
        return tariffsService.receiptTerms();
    }

    const getAgentReceiptTerms = (agentId) => {
        return agentService.getReceiptTerms(agentId);
    }

    const getPaymentMethods = () => {
        return tariffsService.paymentMethods();
    }

    const getAgentPaymentMethods = (agentId) => {
        return agentService.getPaymentMethods(agentId);
    }

    const getPaymentTerms = () => {
        return tariffsService.paymentTerms();
    }

    const getInvoicingCycles = () => {
        return tariffsService.invoicingCycles();
    }

    const submitTariffModel = (tariffModel) => {
        return tariffsService.submitTariffModel(tariffModel);
    }

    const getCommunitiesTariffsList = (filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallback) => {
        tariffsService.getCommunitiesTariffsList(filters, page_size, page, sortColumns).then((response) => {
            callBack(response);
        }).catch((error) => {
            errorCallback(error);
        });
    }

    const getPowerTradingTariffsList = (filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallback) => {
        tariffsService.getPowerTradingTariffsList(filters, page_size, page, sortColumns).then((response) => {
            callBack(response);
        }).catch((error) => {
            errorCallback(error);
        });
    }

    const clearCache = () => {
        setPlanTypes(null);
        setPlanTypeProducts(null);
        setProductPlans(null);
        setPlanFees(null);
        setEnergyTransactions(null);
    }
    
    useEffect(() => {
        clearCache();
    }, [businessType]);
    
    useEffect(() => {
        setPlanTypeProducts(null);
    }, [planTypes]);

    useEffect(() => {
        setProductPlans(null);
    }, [planTypeProducts]);

    useEffect(() => {
        setPlanFees(null);
    }, [productPlans]);

    useEffect(() => {
        if(!id) {
            setLoadingTariffModel(false);
        }
        return () => {
            clearCache();
        }
    }, []);

    useEffect(() => {
        if(id) {
            getTariffModel(id).then(() => {
                setLoadingTariffModel(false);
            }).catch(() => {
                setLoadingTariffModel(false);
            });
        }
    }, [id]);

    return (
        <TariffsContext.Provider value={{
            tariffModel, loadingTariffModel, updateTariffModel, deleteTariffModel, copyTariffModel,
            getPlanTypes, getProducts, getProductPlans, getPlanFees, getEnergyTransactions, getReceiptMethods,
            getReceiptTerms, getPaymentMethods, getPaymentTerms, submitTariffModel, getInvoicingCycles,
            getAgentReceiptTerms, getAgentReceiptMethods, clearCache, setBusinessType, getCommunitiesTariffsList,
            getPowerTradingTariffsList, getAgentPaymentMethods
        }}>
            {props.children}
        </TariffsContext.Provider>
    )
}