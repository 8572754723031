import useAxios from "utils/useAxios";

const BASE_URL = `/trading/market/physical-units/`;

export const useMarketPhysicalUnitsAPI = () => {
    
    const {api, cachedRequest, nonCachedRequest} = useAxios();

    let fetch = (id, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/`;
        nonCachedRequest(url, callBack, errorCallBack);
    };

    let find = (filters=[], page_size = 10, page=1, sortColumns, callBack, errorCallBack) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    let save = (values, callBack, errorCallBack) => {
        api.post(BASE_URL, values).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        })
    };

    let update = (id, values, callBack, errorCallBack) => {
        api.patch(BASE_URL + id + "/", values).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        })
    };
    
    let physicalUnits = (id, page=1, page_size = 10, filters, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/physical-units/?page_size=${page_size}&page=${page}`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    let installations = (id, page=1, page_size = 10, filters, sortColumns, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/installations/?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    let production = (id, filters, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/production/?`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    let programming = (id, filters, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/programming/?`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    let forecasts = (id, filters, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/forecasts/?`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    return {
        find : find,
        save : save,
        update: update,
        physicalUnits: physicalUnits,
        fetch:fetch,
        installations:installations,
        production:production,
        programming:programming,
        forecasts:forecasts,
    }
};

