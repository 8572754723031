import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import IndieStaticSelect from "../IndieStaticSelect";

const PhysicalUnitTypeRadio = (props) => {

    const { t } = useTranslation();

    const options = useMemo(() => [
        {value: "upac", label: t("label.option.upac")},
        {value: "upacia", label: t("label.option.upacia")},
        {value: "ip", label: t("label.option.ip")},
        {value: "ia", label: t("label.option.ia")}
    ], []);

    return (
        <IndieStaticSelect
            {...props}
            options={options}
            label={t("label.form.physicalUnitType")}
            sx={{width: 200}}
        />
    )
}

export default PhysicalUnitTypeRadio;