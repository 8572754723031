import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "contexts/JWTAuth";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const baseURL = `${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_API_PATH}`;

const useAxios = () => {

    const { authTokens, setAuthTokens } = useContext(AuthContext);
    const navigate = useNavigate();

    const axiosInstance = axios.create({
        baseURL,
        headers: { Authorization: `Bearer ${authTokens?.access}` }
    });

    axiosInstance.interceptors.request.use(async req => {

        if(!authTokens) {
            console.log("making unauthenticated requests...")
            return req;
        }
        const user = jwt_decode(authTokens.access);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

        if (!isExpired) return req;

        const userRef = jwt_decode(authTokens.refresh);
        const isExpiredRef = dayjs.unix(userRef.exp).diff(dayjs()) < 1;

        if (isExpiredRef) {
            navigate("/logout");
            return false;
        }
        const response = await axios.post(`${baseURL}/auth/refresh/`, {
            refresh: authTokens.refresh
        });

        let newAuthTokens = {
            access: response.data.access,
            refresh: authTokens.refresh
        };

        localStorage.setItem("authTokens", JSON.stringify(newAuthTokens));
        setAuthTokens(newAuthTokens);

        req.headers.Authorization = `Bearer ${newAuthTokens.access}`;
        return req;
    });

    const nonCachedRequest = (url, callBack, errorCallBack, params = {}) => {
        axiosInstance.get(url, params).then((response) => {
            if(callBack) {
                callBack(response);
            } else if (response.data) {
                return response.data;
            } else {
                return response;
            }
            
        }).catch((e) => {
            if(errorCallBack) {
                errorCallBack(e);
            } else {
                console.error(e);
            }
            
        });
    }

    const cachedRequest = async (key, url, callBack, errorCallBack) => {
        let cache = JSON.parse(localStorage.getItem(key));
        
        if(cache) {
            let data = cache.data;
            let exp_time = cache.exp_time;

            if(moment(exp_time).isBefore(moment())){
                console.log(`key ${key} has already expired, fetching new data.`);
                axiosInstance.get(url).then((response) => {
                    localStorage.setItem(key, JSON.stringify({
                        data: response.data,
                        exp_time: moment().add(7, 'd').format('YYYY-MM-DD')
                    }));
                    callBack(response.data);
                }).catch((e) => {
                    console.error(e);
                    if(errorCallBack) {
                        errorCallBack(e)
                    }
                });
            } else {
                console.log(`returning cached version of ${key}, which will expire on ${exp_time}`);
                callBack(data);
            }
            
        } else {
            console.log(`no cache found, fetching data for the first time for ${key}`)
            axiosInstance.get(url).then((response) => {
                localStorage.setItem(key, JSON.stringify({
                    data: response.data,
                    exp_time: moment().add(7, 'd').format('YYYY-MM-DD')
                }));
                callBack(response.data);
            }).catch((e) => {
                console.error(e);
                if(errorCallBack) {
                    errorCallBack(e)
                }
            });
        }
    };

    const cachedRequest2 = (key, url) => {
        return new Promise((resolve, reject) => {
            let cache = JSON.parse(localStorage.getItem(key));
            
            if(cache) {
                let data = cache.data;
                let exp_time = cache.exp_time;

                if(moment(exp_time).isBefore(moment())){
                    console.log(`key ${key} has already expired, fetching new data.`);
                    axiosInstance.get(url).then((response) => {
                        localStorage.setItem(key, JSON.stringify({
                            data: response.data,
                            exp_time: moment().add(7, 'd').format('YYYY-MM-DD')
                        }));
                        resolve(response.data);
                    }).catch((e) => {
                        console.error(e);
                        reject(2)
                    });
                } else {
                    console.log(`returning cached version of ${key}, which will expire on ${exp_time}`);
                    resolve(data);
                }
                
            } else {
                console.log(`no cache found, fetching data for the first time for ${key}`)
                axiosInstance.get(url).then((response) => {
                    localStorage.setItem(key, JSON.stringify({
                        data: response.data,
                        exp_time: moment().add(7, 'd').format('YYYY-MM-DD')
                    }));
                    resolve(response.data);
                }).catch((e) => {
                    console.error(e);
                    reject(e);
                });
            }
        });
    };
    
    return {api:axiosInstance, cachedRequest, nonCachedRequest, cachedRequest2};
};

export default useAxios;