import { CheckBox, CheckBoxOutlineBlank, Delete, Edit } from "@mui/icons-material";
import { IconButton, Paper, Skeleton, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import IndieSearchField from "components/forms/IndieSearchField";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const IndieTableCell = styled(TableCell)(({ theme }) => ({
    paddingLeft: theme.spacing(2), // Adjust the padding value as needed
    '&:first-of-type': {
        paddingLeft: theme.spacing(2), // or whatever padding value you want
    },
}));

export const IndieSimpleTable = ({
    data, columns, loading, pageSize = 5, onRowClick, selectedEntity = null,
    showGlobalSearch = false, selectable = true, deletable, onDelete,
    editable, onEdit,
    ...props
}) => {

    const { t } = useTranslation();
    const [selectedRow, setSelectedRow] = useState(selectedEntity);
    const [rowsPerPage, setRowsPerPage] = useState(pageSize);
    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState(data);
    const [paginatedData, setPaginatedData] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginateData = (data, page, rowsPerPage) => {
        if (!data || data.length === 0) return [];
        return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }

    const filterTableData = (searchTerm) => {
        const filterData = tableData.filter((row) => {
            const rowValues =  columns.map((column) => {
                return _.get(row, column.field) || "";
            });

            console.log("rowValues", rowValues.join(" ").toLowerCase());
            console.log("searchTerm", searchTerm.toLowerCase());
            console.log("includes", rowValues.join(" ").toLowerCase().includes(searchTerm.toLowerCase()));
            return rowValues.join(" ").toLowerCase().includes(searchTerm.toLowerCase());
        });

        console.log("filterData", filterData);
        const _paginatedData = paginateData(filterData, 0, rowsPerPage);
        console.log("_paginatedData", _paginatedData);
        setPaginatedData(_paginatedData);
    }

    const renderGlobalSearch = () => {
        if (showGlobalSearch) {
            return (
                <IndieSearchField id={`${props.id || "table"}_search_field`} placeholder={t("label.form.search")} onSearch={(v) => filterTableData(v)} searchOnChange />
            )
        }
    }

    const renderCell = (rowIndex, colIndex, column, row) => {
        if(column.type === "link") {
            return (
                <IndieTableCell id={`${id}_table_row_${rowIndex}_${colIndex}`} key={colIndex} align={column.align}>
                    <Link to={`/${column.app}/${_.get(row, "id")}`}>{_.get(row, column.field)}</Link>
                </IndieTableCell>
            )
        } else if (column.type === "boolean") {
            return (
                <IndieTableCell id={`${id}_table_row_${rowIndex}_${colIndex}`} key={colIndex} align={column.align}>
                    {_.get(row, column.field) ? t("label.form.yes") : t("label.form.no")}
                </IndieTableCell>
            )
        } else {
            return (
                <IndieTableCell id={`${id}_table_row_${rowIndex}_${colIndex}`} key={colIndex} align={column.align}>
                    {_.get(row, column.field)}
                </IndieTableCell>
            )
        }
    }

    useEffect(() => {
        setTableData(data);
        setPaginatedData(paginateData(data, page, rowsPerPage));
    }, [data, page, rowsPerPage]);

    const id = props.id || "indie-simple-table";
    
    return (
        <Stack spacing={1}>
            {renderGlobalSearch()}

            <TableContainer variant={props.variant} component={Paper} {...props} id={id}>
                <Table id={`${id}_table`}>
                    <TableHead id={`${id}_table_header`} sx={{ borderBottom: "1px solid #EEE" }}>
                        <TableRow>
                            {selectable && <IndieTableCell></IndieTableCell>}
                            {columns.map((column, index) => (
                                <IndieTableCell key={index} align={column.align}>
                                    {column.label}
                                </IndieTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody id={`${id}_table_body`}>
                        {loading ? (
                            <TableRow >
                                {columns.map((column, index) => (
                                    <IndieTableCell key={index} align={column.align} sx={{ padding: "16px 0" }}>
                                        <Skeleton variant="text" />
                                    </IndieTableCell>
                                ))}
                            </TableRow>
                        ) : (
                            paginatedData.map((row, rowIndex) => (
                                <TableRow
                                    id={`${id}_table_row_${rowIndex}`}
                                    key={rowIndex}
                                    onClick={selectable ? (onRowClick ? () => {
                                        setSelectedRow(row);
                                        onRowClick(row);
                                    } : null ): null}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: selectable ? 'pointer' : '', height: 40 }}
                                    hover
                                    selected={selectedRow === row}>
                                    {selectable && <IndieTableCell>{selectedRow === row ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank color="primary" />}</IndieTableCell>}
                                    {columns.map((column, colIndex) => (
                                        renderCell(rowIndex, colIndex, column, row)
                                    ))}
                                    {editable && <IndieTableCell><IconButton size="small" sx={{p:0}} onClick={() => onEdit(row)}><Edit /></IconButton></IndieTableCell>}
                                    {deletable && <IndieTableCell><IconButton size="small" sx={{p:0}} onClick={() => onDelete(row)}><Delete /></IconButton></IndieTableCell>}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[3, 5, 10]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("lable.table.footer.rowsPerPage")}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("lable.table.footer.of")} ${count}`}
                />
            </TableContainer >
        </Stack>
    )
}
