import { Skeleton } from "@mui/material";
import IndieStaticSelect from "components/forms/IndieStaticSelect";
import TableSelectFilter from "components/tables/TableSelectFilter";
import { TariffsContext } from "contexts/tariffs/TariffsContext";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const EnergyTransactionsSelect = ({planType, product, businessType, ...props}) => {
    
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState([]);
    const [filteredValues, setFilteredValues] = useState([]);
    const { getEnergyTransactions, getProducts } = useContext(TariffsContext);
    
    const { t } = useTranslation();

    const fetchValues = () => {
        setLoading(true);
        getEnergyTransactions().then((response) => {
            const _values = response.map((v) => ({
                value: v.id, label: t(`label.energyTransaction.${v.code}`),
                product: v.product, code: v.code,
                business_type: v.business_type
            }));

            setValues(_values)
            setFilteredValues(_values);
            setLoading(false);
        }).catch((error) => {
            setValues([]);
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchValues();
    }, []);

    useEffect(() => {
        if(values && product && businessType) {
            getProducts(planType).then((response) => {
                const products = response;
                const _product = products.filter((p) => p.id === product)[0];
                setFilteredValues(values.filter(v => _.lowerCase(v.product) === _.lowerCase(_product?.id) && _.lowerCase(v.business_type) === _.lowerCase(businessType)));
            })
        }
    }, [product, businessType, values]);

    if(loading) {
        return <Skeleton variant="text" height="60" width={300} />
    }

    return (
        <IndieStaticSelect
            options={filteredValues}
            label={t("label.form.energyTransaction")}
            {...props}
            sx={{width:300}}
            />
    )
}

export const EnergyTransactionsSelectFilter = (params) => {
    const { t } = useTranslation();
    const { getEnergyTransactions } = useContext(TariffsContext);

    const optionsLoader = () => {
        return new Promise((resolve, reject) => {
            getEnergyTransactions().then((response) => {
                resolve(response.map((v) => ({value: v.id, label: t(`label.energyTransaction.${v.code}`)})));
            }).catch((error) => {
                console.log(error);
                reject([]);
            });
        });
    }

    return (
        <TableSelectFilter optionsLoader={optionsLoader} {...params}  />
    )
}

export default EnergyTransactionsSelect;