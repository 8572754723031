import { useField } from "formik";
import IndieTextField from "./IndieTextField";

const IndieIBANTextField = (props) => {
    const [field, meta, helpers] = useField(props);

    const handleInputChange = (event) => {
        const { value } = event.target;
        // Restrict input if the value contains invalid characters
        if (/^[A-Za-z0-9]*$/.test(value)) {
          helpers.setValue(value ? value.toUpperCase() : "");
        }
      };
    
      return (
        <IndieTextField
          {...props}
          onChange={handleInputChange}
        />
      );
}

export default IndieIBANTextField;