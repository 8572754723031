import { Skeleton } from "@mui/material";
import IndieStaticSelect from "components/forms/IndieStaticSelect";
import TableSelectFilter from "components/tables/TableSelectFilter";
import { TariffsContext } from "contexts/tariffs/TariffsContext";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TariffProductsSelect = ({ planType = null, ...params }) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [tariffTypeProducts, setTariffTypeProducts] = useState([]);
    const { getProducts } = useContext(TariffsContext);

    const fetchValues = () => {
        if(loading) {
            return;
        }
        setLoading(true);
        getProducts(planType.id || planType).then((response) => {
            setTariffTypeProducts(response.map((product) => ({
                value: product.id,
                label: t(`label.product.${product.code}`),
                code: product.code
            })));
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        if(planType) {
            fetchValues();
        }
        
    }, [planType]);

    if (loading) {
        return (
            <Skeleton variant="text" height={50} width={300}/>
        )
    }

    return (
        <IndieStaticSelect
            {...params}
            options={tariffTypeProducts}
            label={t("label.form.product")}
            sx={{width:300}}
        />
        
    );

}

export const TariffProductsSelectFilter = (params) => {
    const { t } = useTranslation();

    const { getProducts } = useContext(TariffsContext);

    const optionsLoader = () => {
        return new Promise((resolve, reject) => {
            getProducts().then((response) => {
                resolve(response.map((planType) => ({value: planType.id, label: t(`label.product.${planType.code}`)})));
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }

    return (
        <TableSelectFilter optionsLoader={optionsLoader} {...params}  />
    );
}

export default TariffProductsSelect;