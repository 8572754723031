import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import { createContext, useContext, useEffect, useState } from "react";
import { useAgentEntitiesAPIService } from "service/AgentEntitiesAPIService";
import { useTradingFinancialDocumentsAPI } from "service/TradingFinancialDocumentsAPIService";

export const AgentEntityContext = createContext();

export const AgentEntityContextProvider = ({ id, entity, ...props}) => {

    const service = useAgentEntitiesAPIService();
    const financialDocumentsService = useTradingFinancialDocumentsAPI();

    const { setAndShowErrorMessage, setAndShowSuccessMessage } = useContext(UserFeedbackContext);

    // State
    const [agentEntity, setAgentEntity] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const get = () => {
        if(!id) {
            console.error("missing ID to get agent entity");
            return;
        };
        setSubmitting(true);
        service.get(id).then((response) => {
            setAgentEntity(response.data);
            setSubmitting(false);
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
        })
    }

    const fetchAll = (filters=[], page_size = 10, page = 1, stortColumns = false, callBack, errorCallBack) => {
        service.fetch(filters, page_size, page, stortColumns, callBack, errorCallBack);
    }

    const fetchEGACs = (page, page_size = 10, filters=[]) => {
        return new Promise((resolve, reject) => {
            service.fetch([{ id: "agent_type", value: "egac" }].concat(filters), page_size, page, false, (response) => {
                resolve(response.data.results);
            }, (error) => {
                console.error(error);
                reject(error);
            });
        });
    }

    const fetchAggregators = (page, page_size = 10, filters=[]) => {
        return new Promise((resolve, reject) => {
            service.fetch([{ id: "agent_type", value: "aggregator" }].concat(filters), page_size, page, false, (response) => {
                resolve(response.data.results);
            }, (error) => {
                console.error(error);
                reject(error);
            });
        });
    }

    const update = async (values) => {
        setSubmitting(true);
        service.update(agentEntity.id, values).then(() => {
            setAndShowSuccessMessage("success.api.save");
            setSubmitting(false);
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
        })
    };

    const updateCertificate = async (file) => {
        setSubmitting(true);
        service.post_certificate(agentEntity.id, file).then((response) => {
            setAndShowSuccessMessage("label.message.agent.certificateUploaded");
            setSubmitting(false);
            setAgentEntity(response.data);
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
        });
    }

    const updateCertificatePassword = async (values) => {
        setSubmitting(true);
        service.update(agentEntity.id, values).then(() => {
            setAndShowSuccessMessage("label.message.agent.certificatePasswordUpdated");
            setSubmitting(false);
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
        })
    };

    const updateMarketReferenceCodes = async (values) => {
        setSubmitting(true);
        service.update(agentEntity.id, values).then((response) => {
            setAndShowSuccessMessage("label.message.agent.referenceCodesUpdated");
            setSubmitting(false);
            setAgentEntity(response.data);
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
        });
    }

    const updateRenSftpAgregatorConfig = async (values) => {
        setSubmitting(true);
        service.update(agentEntity.id, values).then((response) => {
            setAndShowSuccessMessage("label.message.agent.renSftpConfigurationUpdated");
            setSubmitting(false);
            setAgentEntity(response.data);
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
        });
    }

    const updateEredesSftpAgregatorConfig = async (values) => {
        setSubmitting(true);
        service.update(agentEntity.id, values).then((response) => {
            setAndShowSuccessMessage("label.message.agent.eredesSftpConfigurationUpdated");
            setSubmitting(false);
            setAgentEntity(response.data);
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
        });
    }

    const getSecretValue = async (fieldName, code) => {
        return service.secretValue(agentEntity?.id || id, fieldName, code);
    }

    const fetchFinancialDocuments = (filters = [], page_size, page, sortColumns, callBack, errorCallBack) => {
        filters.push({id: "agent_entity", value: agentEntity.id});
        service.financialDocuments(agentEntity.id, filters, page_size, page, sortColumns, callBack, errorCallBack);
    }

    const getFinancialDocumentsSummary = (filters, callBack, errorCallBack) => {
        filters.push({id: "agent_entity", value: agentEntity.id});
        financialDocumentsService.summary(filters, callBack, errorCallBack);
    }

    const approveFinanciaDocuments = (filters, callBack, errorCallBack) => {
        filters.push({id: "agent_entity", value: agentEntity.id});
        financialDocumentsService.approve(agentEntity.id, filters, callBack, errorCallBack);
    }

    const processFinancialDocuments = (filters, callBack, errorCallBack) => {
        filters.push({id: "agent_entity", value: agentEntity.id});
        financialDocumentsService.process(agentEntity.id, filters, callBack, errorCallBack);
    }

    useEffect(() => {
        if (id) {
            get();
        } else if (entity) {
            setAgentEntity(entity);
        } else {
            setAgentEntity(null);
        }
    }, []);

    useEffect(() => {
        if (entity) {
            setAgentEntity(entity);
        }
    }, [entity]);

    const returnObj = {
        agentEntity, get, update, updateCertificate, updateCertificatePassword,
        updateMarketReferenceCodes , submitting, getSecretValue, fetchEGACs, fetchAggregators,
        fetchAll, updateRenSftpAgregatorConfig, updateEredesSftpAgregatorConfig,
        fetchFinancialDocuments, getFinancialDocumentsSummary, approveFinanciaDocuments,
        processFinancialDocuments
    }

    return (
        <AgentEntityContext.Provider value={returnObj}>
            {props.children}
        </AgentEntityContext.Provider>
    )
}