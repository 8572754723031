import { Skeleton } from "@mui/material";
import IndieStaticSelect from "components/forms/IndieStaticSelect";
import { TariffsContext } from "contexts/tariffs/TariffsContext";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TariffPlansSelect = ({ product, ...params }) => {
    
    const [values, setValues] = useState([]);
    const { t } = useTranslation();
    const { getProductPlans } = useContext(TariffsContext);
    const [loading, setLoading] = useState(false);

    const fetchValues = () => {
        if (loading) return;
        setLoading(true);
        getProductPlans(product).then((response) => {
            setValues(response.map((plan) => ({
                value: plan.id,
                label: t(`${plan.description}`),
                "use_energy_price": plan.use_energy_price,
                "use_commission_prcnt": plan.use_commission_prcnt,
                "use_commission_total": plan.use_commission_total
            })));
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });

    }

    useEffect(() => {
        if (product) {
            fetchValues();
        }
    }, [product]);

    useEffect(() => {
        if(product) {
            fetchValues();
        }
    }, [])

    if (loading) {
        return (
            <Skeleton variant="rectangular" height={50} />
        )
    }
    return (
        <IndieStaticSelect
            {...params}
            options={values}
            label={t("label.form.plan")}
            sx={{width:300}}
        />
    );

}

export default TariffPlansSelect;