import useAxios from "utils/useAxios";

const BASE_ZIPCODE_URL = `/core/addresses/zipcodes/`;
const BASE_DISTRICTS_URL = `/core/addresses/districts/`;
const BASE_COUNTRIES_URL = `/core/addresses/countries/`;
const BASE_COUNTIES_URL = `/core/addresses/counties/`;
const BASE_PARISHES_URL = `/core/addresses/parishes`;

export const useAddressesAPI = () => {
    
    const {api, nonCachedRequest, cachedRequest} = useAxios();

    let zipcode = (zipcode, callBack, errorCallBack) => {
        if (!zipcode || zipcode == null) {
            return;
        }
        nonCachedRequest(`${BASE_ZIPCODE_URL}?zip_code=${zipcode}`, callBack, errorCallBack);
    };

    let districts = (district, callBack, errorCallBack) => {
        if (!district || district == null) {
            district="";
        }
        cachedRequest('districts', `${BASE_DISTRICTS_URL}`, callBack, errorCallBack);
    };

    let countries = (country, callBack, errorCallBack) => {
        let country_search_exp = ""
        if (!country || country == null) {
            country_search_exp=`?country__contains=${country}`; 
        }
        nonCachedRequest(`${BASE_COUNTRIES_URL}${country_search_exp}`, callBack, errorCallBack);
    }

    let counties = (district, county, callBack, errorCallBack) => {

        if(!district) {
            return;
        }

        let additionalSearch = "";
        if(county) {
            additionalSearch += "&county__contains="+county;
        }
        nonCachedRequest(`${BASE_COUNTIES_URL}?district=${district}${additionalSearch}`, callBack, errorCallBack);
    }

    let parishes = (district, county, parish, callBack, errorCallBack) => {
        if(!district || !county) {
            console.error("missing required data -> ", { district, county });
            return;
        }
        
        let additionalSearch = "";
        nonCachedRequest(`${BASE_PARISHES_URL}?district=${district}&county=${county}${additionalSearch}`, callBack, errorCallBack);
    }
    
    return {
        zipcode : zipcode,
        districts: districts,
        countries: countries,
        parishes: parishes,
        counties:counties
    }
};

