import useAxios from "utils/useAxios";

const BASE_URL = `/core/agents/entities/`;

export const useAgentEntitiesAPIService = () => {
    
    const {nonCachedRequest, cachedRequest, api} = useAxios();

    const create = (values, callBack, errorCallBack) => {
        api.post(BASE_URL, values).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        })
    }

    const fetch = (filters=[], page_size = 10, page=1, sortColumns, callBack, errorCallBack) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const fetchAggregators = (filters=[], page_size = 10, page=1, sortColumns, callBack, errorCallBack) => {
        filters.push({id: "agent_type", value: "aggregator"});
        fetch(filters, page_size, page, sortColumns, callBack, errorCallBack);
    };

    const fetchOMIEMarketAgents = (callBack, errorCallBack) => {
        const filters = [];
        filters.push({id: "agent_type", value: "aggregator"});
        filters.push({id: "is_omie_agent", value:true});
        fetch(filters, 200, 1, "entity__name", callBack, errorCallBack);
    };
    

    const fetchCommunityManagers = (filters=[], page_size = 10, page=1, sortColumns, callBack, errorCallBack) => {
        filters.push({id: "agent_type", value: "egac"});
        fetch(filters, page_size, page, sortColumns, callBack, errorCallBack);
    };

    const get = (id) => {
        const url = BASE_URL + `${id}`;
        return api.get(url);
    }

    const fetchAll = (callBack, errorCallBack) => {
        nonCachedRequest(BASE_URL, callBack, errorCallBack);
    };

    const fetchById = (id, callBack, errorCallBack) => {
        cachedRequest(`market-agent-${id}`, BASE_URL + id, callBack, errorCallBack);
    };

    const products = (id, filters=[], callBack, errorCallBack) => {
        let url = null;
        if(id) {
            url = `${BASE_URL}${id}/products/`;
        } else {
            url = `${BASE_URL}products/`;
        }
        
        filters.map((filter) => url += `&${filter.id.replace('.','__')}__contains=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    const plans = (id, filters=[], callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/plans/`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}__contains=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    const paymentMethods = (id, filters=[], callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/paymentMethods/`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}__contains=${filter.value}`);
        cachedRequest(`paymentMethods-${id}`,url, callBack, errorCallBack);
    }

    const paymentTerms = (id, filters=[], callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/paymentTerms/`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}__contains=${filter.value}`);
        cachedRequest(`paymentTerms-${id}`,url, callBack, errorCallBack);
    }
    
    const update = (id, values) => {
        return api.patch(BASE_URL + id + "/", values);
    }

    const post_certificate = (id, file) => {

        const formData = new FormData();
        formData.append('file', file);

        return api.post(
            BASE_URL + id + "/certificate/",
            formData,
            { headers: { 'Content-Type': 'multipart/form-data', }}
        );
    }

    const companies = (id, use_for_settlements, use_for_payments, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/companies?`;
        if (use_for_settlements) {
            url += `use_for_settlements=1`;
        } else if (use_for_payments) {
            url += `use_for_payments=1`;
        }
        nonCachedRequest(url, callBack, errorCallBack);
    }

    const secretValue = (id, fieldName, code) => {
        return api.get(BASE_URL + id + "/secret-values?field_name=" + fieldName + "&totp_code=" + code);
    }

    const financialDocuments = (id, filters=[], page_size=10, page=1, sortColumns, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/findocs?page_size=${page_size}&page=${page}`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        nonCachedRequest(url, callBack, errorCallBack);
    }
    
    return {
        get,
        create,
        fetch,
        fetchById,
        update,
        products,
        plans,
        paymentMethods,
        paymentTerms,
        post_certificate,
        fetchAll,
        companies,
        secretValue,
        fetchAggregators,
        fetchCommunityManagers,
        financialDocuments,
        fetchOMIEMarketAgents
    }
};

