import { Autocomplete, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export const installationClassification = [
    {
        code: "ic",
        description: "label.physicalUnitClassification.ic"
    },
    {
        code: "ic_prod",
        description: "label.physicalUnitClassification.ic_prod"
    },
    {
        code: "ic_prod_strg",
        description: "label.physicalUnitClassification.ic_prod_strg"
    },
    {
        code: "ic_strg",
        description: "label.physicalUnitClassification.ic_strg"
    },
    {
        code: "ip",
        description: "label.physicalUnitClassification.ip"
    },
    {
        code: "ip_strg",
        description: "label.physicalUnitClassification.ip_strg"
    },
    {
        code: "ia",
        description: "label.physicalUnitClassification.ia"
    }
    
];

export const InstallationClassificationTableFilter = ({ column, fetchData, state, ...params }) => {
    const { filterValue, setFilter } = column;

    const { t } = useTranslation();
    const theme = useTheme();
    const handleFilterChange = (columnId, event, value) => {
        setFilter(value && value.code ? value.code : undefined);
    };

    return (
        <Autocomplete
            size="small"
            id={`autocomplete_${column.id.replaceAll('.', '_')}`}
            options={installationClassification.map((o) => ({code: o.code, name: t(o.description)}))}
            value={filterValue ? installationClassification.filter((o) => o.code === filterValue)[0] : installationClassification.filter((o) => o.code === "")[0]}
            getOptionLabel={(option) => t(option.name)}
            onChange={(e, value) => handleFilterChange("status", e, value)}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    fetchData(1, state.filters);
                }
            }}
            renderInput={(params) => <TextField {...params} id={`textfield_${column.id.replaceAll('.', '_')}`} autoComplete='off'
                sx={{
                    backgroundColor:
                        theme.palette.mode === "light" ? "#ECEFF5" : theme.palette.divider,
                    borderRadius: "8px",
                    minWidth: "150px",
                    zIndex: (theme) => theme.zIndex.drawer - 1
                }} />}
        />
    );
};