import {FormControlLabel, Checkbox, Radio} from "@mui/material";
import { useField } from "formik";
import useAuth from "hooks/useAuth";

const IndieRadioButton = ({id, name, label, permissionGroups, ...params}) => {
    const [field, meta, helpers] = useField(name || id);

    const onChange = (e) => { 
        helpers.setValue(e.target.checked);
        params.onChange && params.onChange(e, e.target.checked);
    }

    const { hasPermissionGroup } = useAuth();

    if(permissionGroups && ! hasPermissionGroup(permissionGroups)) {
        return "";
    }

    return (
        <FormControlLabel
            id={id}
            name={name || id}
            label={label}
            control={<Radio
                id={`checkbox_${id}`}
                {...params}
                checked={field.checked || params.checked}
                onChange={onChange}
                sx={{ mr: 2 }}
            />}
        />
    )
}

export default IndieRadioButton;