import { FormControl, FormHelperText, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useField } from "formik";

const IndieDesktopDatePicker = ({...params}) => {
    let [field, meta, helpers] = [{}, {}, {}]; 
    try {
        [field, meta, helpers] = useField(params);
    } catch (error) {
    }

    const { id } = params;
    
    return (
        <FormControl sx={{width: 250}}>
            <DatePicker
                {...field}
                closeOnSelect={true}
                disabled={params.disabled}
                label={params.label}
                id={`date_picker_${id}`}
                variant="standard"
                size="small"
                inputFormat="YYYY-MM-DD"
                onChange={(v) => {
                    helpers.setValue(v)
                    if(params.onChange) {
                        params.onChange(v);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        id={`text_field_${id}`}
                        {...params}
                        variant="standard"
                        autoComplete="new-password" 
                        sx={{ mb: 1, ...params.sx}}
                    />
                )}
                {...params}
            />
            {meta.touched && meta.error ? (
                <FormHelperText error>{meta.error}</FormHelperText>
            ):null}
        </FormControl>
    )
}

export default IndieDesktopDatePicker;