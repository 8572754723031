import useAxios from "utils/useAxios";

export const useAgentAPIService = () => {
    
    const { api, nonCachedRequest, cachedRequest2} = useAxios();
    const BASE_URL = '/core/agents';

    const list = (filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const get = (id) => {
        const url = BASE_URL + `/${id}`;
        return api.get(url);
    }

    const update = (id, data) => {
        const url = BASE_URL + `/${id}/`;
        return api.put(url, data);
    }

    const preRegister = (data) => {
        const url = "/core/admin/agent/preregister/";
        return api.post(url, data);
    }

    const sendRegistrationEmail = (id) => {
        const url = `/core/admin/agent/${id}/resend-email`;
        return api.get(url);
    }

    const getReceiptBillingPeriods = (id) => {
        const url = BASE_URL + `/${id}/receipts/billing-periods/`;
        return api.get(url);
    }

    const getReceiptMethods = (id) => {
        const url = BASE_URL + `/${id}/receipts/methods/`;
        return api.get(url);
    }

    const getReceiptTerms = (id) => {
        const url = BASE_URL + `/${id}/receipts/terms/`;
        return api.get(url);
    }

    const getPaymentMethods = (id) => {
        const url = BASE_URL + `/${id}/payment/methods/`;
        return api.get(url);
    }

    return {
        list,
        get,
        update,
        preRegister,
        sendRegistrationEmail,
        getReceiptBillingPeriods,
        getReceiptMethods,
        getReceiptTerms,
        getPaymentMethods
    }
};
