import { Autocomplete, Box, CircularProgress, FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { useField } from "formik";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMarketPhysicalUnitsAPI } from "service/power-trading/MarketPhysicalUnits";

const PhysicalUnitListItem = ({ physicalUnitName, offeringUnitName, ...props }) => {
    return (
        <Box {...props}>
            <Stack spacing={0}>
                <Typography variant="body2">Unidade Física: {physicalUnitName}</Typography>
                <Typography variant="body2">Unidade de Oferta: {offeringUnitName}</Typography>
            </Stack>
        </Box>
    );
};
const PhysicalUnitSelect = ({installationType, connectionPower, ...params}) => {

    const [field, meta] = useField(params);

    const api = useMarketPhysicalUnitsAPI();
    const { t } = useTranslation();

    const [options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchPhysicalUnits = (connectionPower, installationType) => {
        // Assure we are not performing searches under n characters
        if(installationType === null || installationType === undefined) {
            console.error("PhysicalUnitSelect: installationType is required.");
            return false;
        }

        if(connectionPower === null || connectionPower === undefined) {
            console.error("PhysicalUnitSelect: connectionPower is required.");
            return false;
        }

        setLoading(true);
        
        api.find([
            {"id":"installation_type", "value":installationType},
            {"id":"aggregation", "value":parseFloat(connectionPower) < 1000}
        ], 1000, 1, null, (response) => {
            setFilteredOptions(null);
            setOptions(response.data.results);
            setLoading(false);
        }, (error) => {
            console.error(error);
            setLoading(false);
            setFilteredOptions(null);
            setOptions([]);
        });
    }

    // Search is debounced to avoid searching while user is typing.
    const debouncedFetchItemsList = useCallback(debounce(fetchPhysicalUnits, 1000), []);

    const getOptionLabel = (option) => {
        if(option && option.name) {
            return option.name;
        } else if (option) {
            const o = options.filter((o) => o?.id === option)[0];
            if (o) {
                return o.name;
            }

            return "";
        }

        return "";
    }

    const isOptionEqualToValue = (option, value) => { return option?.id === value?.id; }

    useEffect(() => {
        if (connectionPower !== null && installationType !== null) {
            console.info(`${JSON.stringify(connectionPower)}, ${JSON.stringify(installationType)}`);
            debouncedFetchItemsList(connectionPower, installationType);
        } else {
            console.info("PhysicalUnitSelect: aggregation and installationType are required.");
        }
    }, [connectionPower, installationType]);

    useEffect(() => {
        if (connectionPower !== null && installationType !== null) {
            console.info(`${JSON.stringify(connectionPower)}, ${JSON.stringify(installationType)}`);
            debouncedFetchItemsList(connectionPower, installationType);
        }
    }, []);
    
    return (
        <FormControl>
            <Autocomplete
            {...params}
            {...field}
            id={`autocomple-${params.id}`}
            disablePortal
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            options={filteredOptions || options}
            loading={loading}
            disabled={params.disabled || loading}
            onChange={params.handleOnChange}
            renderOption={(props, option) => (
                <PhysicalUnitListItem key={option.id} physicalUnitName={option?.name} offeringUnitName={option?.offering_unit.name} {...props} />
            )}
            autoComplete={false}
            filterOptions={(x) => x}
            onInputChange={(event, value) => {
                // Filter options by name
                setFilteredOptions(options.filter((o) => o.name.toLowerCase().includes(value.toLowerCase())));
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("label.form.gcsCode")}
                    autoComplete="off"
                    variant="standard"
                    sx={{maxWidth: 250}}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }} />
            )} />
            {meta.error ? (
                <FormHelperText error>{meta.error}</FormHelperText>
            ):null}
        </FormControl>
        
    )
}

export default PhysicalUnitSelect;