import { useContext, useEffect, useState } from "react";
import IndieStaticSelect from "../IndieStaticSelect";
import { Skeleton } from "@mui/material";
import { TariffsContext } from "contexts/tariffs/TariffsContext";
import { useTranslation } from "react-i18next";

const IndiePaymentMethodsSelect = ({agent, ...params}) => {

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(true);
    const { getPaymentMethods, getAgentPaymentMethods } = useContext(TariffsContext);
    const { t } = useTranslation();
    
    useEffect(() => {
        setLoading(true);
        if (agent) {
            getAgentPaymentMethods(agent.id || agent).then((response) => {
                setPaymentMethods(response.data.map((item) => ({
                    value: item.id,
                    label: item.description
                })));
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
            });
        } else {
            getPaymentMethods().then((response) => {
                setPaymentMethods(response.map((item) => ({
                    value: item.id,
                    label: item.title
                })));
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
            });
        }
    }, []);

    if(loading) {
        return <Skeleton variant="rectangular" height={56} />
    }

    return (
        <IndieStaticSelect
            {...params}
            options={paymentMethods}
            label={t("label.form.paymentMethod")}
            sx={{width: 300}}
        />
    )
}

export default IndiePaymentMethodsSelect;