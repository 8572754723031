import { Close, GroupAddOutlined } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid2, IconButton, ListItem, MenuList, Stack, Step, StepContent, StepLabel, Stepper, Typography, useMediaQuery } from '@mui/material';
import AddressFormComponents from 'components/addresses/AddressFormComponents';
import IndieBackButton from 'components/forms/IndieBackButton';
import IndieContinueButton from 'components/forms/IndieContinueButton';
import IndieSaveButton from 'components/forms/IndieSaveButton';
import IndieStaticSelect from 'components/forms/IndieStaticSelect';
import IndieTextField from 'components/forms/IndieTextField';
import EntityFormFields from 'components/forms/entities/EntityFormFields';
import InstallationClassificationSelectSlider, { installationClassificationsProduction, installationClassificationsStorage } from 'components/forms/installations/InstallationClassificationSelectSlider';
import InstallationTypeSelectSlider from 'components/forms/installations/InstallationTypeSelectSlider';
import { CommunityContext } from 'contexts/communities/CommunityContext';
import { Form, Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { validateCPE, validateZipCode } from 'utils/utils';
import * as Yup from 'yup';
import CommunitySelectSlider from '../../forms/communities/CommunitySelectSlider';
import PhysicalUnitInformationForm from '../physical-units/PhysicalUnitInformationForm';
import { IndieLabelAndValue } from './NewCommunityDialog';
import { UserFeedbackContext } from 'contexts/UserFeedbackContext';
import IndieFiscalNumberTextField from 'components/forms/IndieFiscalNumberTextField';

export const LatitudeLongitudeInput = (props) => {

    const { t } = useTranslation();
    const formik = useFormikContext();

    const handleLatitudeChange = (event) => {
        const value = event.target.value;
        if (isValidNumber(value)) {
            formik.setFieldValue('physical_unit.latitude_dd', limitDecimals(value, 5));
        }
    };

    const handleLongitudeChange = (event) => {
        const value = event.target.value;
        if (isValidNumber(value)) {
            formik.setFieldValue('physical_unit.longitude_dd', limitDecimals(value, 5));
        }
    };

    const handlePaste = (event) => {
        const paste = (event.clipboardData || window.clipboardData).getData('text');
        
        const [lat, lon] = paste.split(',').map(coord => coord.trim());
        if (isValidNumber(lat) && isValidNumber(lon)) {
            formik.setFieldValue('physical_unit.latitude_dd', limitDecimals(lat, 5));
            formik.setFieldValue('physical_unit.longitude_dd', limitDecimals(lon, 5));
            event.preventDefault();
        } 
        
    };

    const isValidNumber = (value) => {
        return /^-?\d*\.?\d*$/.test(value);
    };

    const limitDecimals = (value, decimals) => {
        const [integer, fraction] = value.split('.');
        if (fraction && fraction.length > decimals) {
            return `${integer}.${fraction.slice(0, decimals)}`;
        }
        return value;
    };

    return (
        <Stack onPaste={handlePaste} spacing={1}>
            <IndieTextField
                {...props}
                id="physical_unit_latitude_dd"
                name="physical_unit.latitude_dd"
                label={t("label.form.latitude")}
                onChange={handleLatitudeChange}
                variant="outlined"
                fullWidth
                inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
            />
            <IndieTextField
                {...props}
                id="physical_unit_longitude_dd"
                name="physical_unit.longitude_dd"
                label={t("label.form.longitude")}
                onChange={handleLongitudeChange}
                variant="outlined"
                fullWidth
                inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
            />
        </Stack>
    );
};

const NewCommunityMemberConfigureInstallationDialog = ({ open, onClose, onSuccess, communityId }) => {

    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const { community, submitting, setCommunity, createMember } = useContext(CommunityContext);
    const { setAndShowErrorMessage } = useContext(UserFeedbackContext);
    const isMobileVersion = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    const handleClose = () => {
        onClose();
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleSubmit = (values, actions) => {
        if (activeStep < validationSchemas.length - 1) {
            handleNext();
        } else {
            // Convert type, classification and tension_level to id
            const _values = _.cloneDeep(values);
            _values.physical_unit.type = _values.physical_unit.type.code;
            _values.physical_unit.classification = _values.physical_unit.classification.code;
            _values.physical_unit.tension_level_id = _values.physical_unit.tension_level;
            delete _values.physical_unit.tension_level;
            // Convert has_evcp to boolean
            _values.physical_unit.has_evcp = _values.physical_unit.has_evcp === 1;
            // Convert production_type to id
            _values.physical_unit.production_type_id = _values.physical_unit.production_type.id;
            delete _values.physical_unit.production_type;
            // Convert installation_type to id
            _values.physical_unit.installation_type_id = _values.physical_unit.installation_type.id;
            delete _values.physical_unit.installation_type;
            // Convert trading_physical_unit to id
            _values.physical_unit.trading_physical_unit_id = _values.physical_unit.trading_physical_unit.id;
            delete _values.physical_unit.trading_physical_unit;

            _values.physical_unit_owner = _.omit(_values.physical_unit_owner, ['country_object', 'district_object', 'county']);
            _values.physical_unit = _.omit(_values.physical_unit, ['country_object', 'district_object', 'county', 'tension_level_object', 'has_evcp_object']);

            // Replace all blanks with nulls
            for (const key in _values) {
                if (_values[key] === '') {
                    _values[key] = null;
                }
            }

            // System Context will determine the community
            delete _values.community;

            createMember(_values).then((response) => {
                if (onSuccess) {
                    onSuccess(response.data);
                }

                actions.resetForm();
                setActiveStep(0);
            }).catch((error) => {
                console.error(error);
                setAndShowErrorMessage(error);
            });

        }
    };

    const initialValues = {
        community: communityId,
        physical_unit_owner: {
            name: '',
            fiscal_number: '',
            phone: '',
            email: '',
            zip_code: '',
            address: '',
            country: '',
            door_number: '',
            apartment_number: '',
        },
        member_id_dgeg: '',
        member_status: '',
        physical_unit: {
            type: '',
            classification: '',
            consumption_deliverypoint: '',
            certified_power: '',
            tension_level: '',
            has_evcp: '',
            production_deliverypoint: '',
            installed_power: '',
            installed_power_kva: '',
            connection_power: '',
            connection_power_kva: '',
            generator_power_kwh: '',
            production_type: '',
            installation_type: '',
            storage_power_kwh: '',
            storage_capacity_kwh: '',
            licencing_registration_number: '',
            licencing_record_number: '',
            omie_reference_number: '',
            trading_physical_unit: '',
            zip_code: '',
            address: '',
            country: '',
            latitude_dd: '',
            longitude_dd: ''
        }
    };

    // Community Selection
    const step1ValidationSchema = Yup.object().shape({
        community: Yup.string().required(t("error.forms.mandatory")),
    });

    // Physical Unit Owner Step
    const step2ValidationSchema = Yup.object().shape({
        member_id_dgeg: Yup.string().required(t("error.forms.mandatory")),
        member_status: Yup.string().required(t("error.forms.mandatory")),
    });

    // Physical  Type Step
    const step3ValidationSchema = Yup.object().shape({
        physical_unit: Yup.object().shape({
            type: Yup.object().required(t("error.forms.mandatory")),
        })
    });

    // Physical Classification Step
    const step4ValidationSchema = Yup.object().shape({
        physical_unit: Yup.object().shape({
            classification: Yup.object().required(t("error.forms.mandatory")),
        })
    });

    // Physical Unit Address Step
    const step5ValidationSchema = Yup.object().shape({
        physical_unit: Yup.object().shape({
            zip_code: Yup.string().required(t("error.forms.mandatory")).test('isValidZipCode', t("error.forms.zipCodeFormat"), validateZipCode),
        })
    });

    // Physical Unit Details Step
    const step6ValidationSchema = Yup.object().shape({
        physical_unit: Yup.object().shape({
            consumption_deliverypoint: Yup.string().required(t("error.forms.mandatory")).test('isValidCPE', t("error.forms.invalidCPE"), validateCPE),
            certified_power: Yup.string().required(t("error.forms.mandatory")),
            tension_level: Yup.string().required(t("error.forms.mandatory")),
            has_evcp: Yup.string().required(t("error.forms.mandatory")),
            // Production Fields
            production_deliverypoint: Yup.string().when('$physical_unit', {
                is: (physical_unit) => installationClassificationsProduction.includes(physical_unit.classification.code),
                then: (schema) => schema.required(t("error.forms.mandatory")).test('isValidCPE', t("error.forms.invalidCPE"), validateCPE),

                otherwise: (schema) => schema,
            }),
            installed_power: Yup.string().when('$physical_unit', {
                is: (physical_unit) => installationClassificationsProduction.includes(physical_unit.classification.code),
                then: (schema) => schema.required(t("error.forms.mandatory")),
                otherwise: (schema) => schema,
            }),
            connection_power: Yup.string().when('$physical_unit', {
                is: (physical_unit) => installationClassificationsProduction.includes(physical_unit.classification.code),
                then: (schema) => schema.required(t("error.forms.mandatory")),
                otherwise: (schema) => schema,
            }),
            generator_power_kwh: Yup.string().when('$physical_unit', {
                is: (physical_unit) => installationClassificationsProduction.includes(physical_unit.classification.code),
                then: (schema) => schema.required(t("error.forms.mandatory")),
                otherwise: (schema) => schema,
            }),
            licencing_registration_number: Yup.string().when('$physical_unit', {
                is: (physical_unit) => installationClassificationsProduction.includes(physical_unit.classification.code),
                then: (schema) => schema.required(t("error.forms.mandatory")),
                otherwise: (schema) => schema,
            }),
            licencing_record_number: Yup.string().when('$physical_unit', {
                is: (physical_unit) => installationClassificationsProduction.includes(physical_unit.classification.code),
                then: (schema) => schema.required(t("error.forms.mandatory")),
                otherwise: (schema) => schema,
            }),
            // Storage Fields
            storage_power_kwh: Yup.string().when('$physical_unit', {
                is: (physical_unit) => installationClassificationsStorage.includes(physical_unit.classification.code),
                then: (schema) => schema.required(t("error.forms.mandatory")),
                otherwise: (schema) => schema,
            }),
            storage_capacity_kwh: Yup.string().when('$physical_unit', {
                is: (physical_unit) => installationClassificationsStorage.includes(physical_unit.classification.code),
                then: (schema) => schema.required(t("error.forms.mandatory")),
                otherwise: (schema) => schema,
            }),
        })
    });

    // Physical Unit Owner Step
    const step7ValidationSchema = Yup.object().shape({
        physical_unit_owner: Yup.object().shape({
            fiscal_number: Yup.string().required(t("error.forms.mandatory")),
            name: Yup.string().required(t("error.forms.mandatory")),
            zip_code: Yup.string().required(t("error.forms.mandatory")),
        })
    });

    const validationSchemas = [
        Yup.object().shape({}),
        step1ValidationSchema,
        step2ValidationSchema,
        step3ValidationSchema,
        step4ValidationSchema,
        step5ValidationSchema,
        step6ValidationSchema,
        step7ValidationSchema,
        Yup.object().shape({})
    ];

    useEffect(() => {
        return () => {
            setActiveStep(0);
        }
    }, []);

    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <DialogTitle>
                <Grid2 container>
                    <Grid2 item size={{ xs: 9, sm: 6 }}>
                        <Stack spacing={2} direction="row" alignItems={"center"}>
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                            <GroupAddOutlined color="primary" />
                            <Typography variant="subtitle1" color="textPrimary">{t("label.button.newMember")}</Typography>
                        </Stack>
                    </Grid2>
                    <Grid2 item size={{ xs: 3, sm: 6 }} alignItems={"center"}>
                    <Box sx={{ display: 'flex', flexGrow: 1, height: "100%", alignItems: "center", justifyContent: "flex-end"}}>
                            <Stepper activeStep={0} orientation="horizontal">
                                <Step key={0}>
                                    <StepLabel>{!isMobileVersion && t("label.title.configureInstallation")}</StepLabel>
                                </Step>
                                <Step key={1}>
                                <StepLabel>{!isMobileVersion && t("label.button.configureMember")}</StepLabel>
                                </Step>
                                <Step key={2}>
                                    <StepLabel>{!isMobileVersion && t("label.title.configureTariffPlan")}</StepLabel>
                                </Step>
                            </Stepper>
                        </Box>
                    </Grid2>
                </Grid2>
            </DialogTitle>
            <DialogContent>
                <Divider fullWidth sx={{ mb: 2 }} />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemas[activeStep]}
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                >    
                    {(formik) => (
                        <Fragment>
                            <FormikStep7 activeStep={activeStep} />
                            <Form>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    <Step key={0}>
                                        <StepLabel>{t("label.step.newCommunityMember.introduction")}</StepLabel>
                                        <StepContent>
                                            <Stack spacing={2}>
                                                <Typography variant="subtitle2">{t("label.step.newCommunityMember.introduction.subtitle")}</Typography>
                                                <Typography variant="body2">
                                                    <Trans i18nKey="label.step.newCommunityMember.introduction.text" components={{ ul: <MenuList />, li: <ListItem /> }} />
                                                </Typography>

                                                <Stack direction="row" spacing={2}>
                                                    <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                </Stack>
                                            </Stack>
                                        </StepContent>
                                    </Step>
                                    <Step key={1}>
                                        <StepLabel>{t("label.step.newCommunityMember.selectCommunityStep")}</StepLabel>
                                        <StepContent>
                                            <Box maxWidth={"lg"}>
                                                <Stack spacing={2}>
                                                    <Typography variant="subtitle2">{t("label.step.newCommunityMember.selectCommunityStep.subtitle")}</Typography>
                                                    <Stack direction={"row"} spacing={2}>
                                                        <CommunitySelectSlider name="community" onChange={(c) => setCommunity(c)} />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2}>
                                                        <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                        <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                    <Step key={2}>
                                        <StepLabel>{t("label.step.newCommunityMember.memberDataStep")}</StepLabel>
                                        <StepContent>
                                            <Stack spacing={2}>
                                                <Typography variant="subtitle2">{t("label.step.newCommunityMember.memberDataStep.subtitle")}</Typography>
                                                <IndieTextField id="member_id_dgeg" name="member_id_dgeg" label={t("label.form.dgegid")} maxLength={100} />
                                                <IndieStaticSelect id="member_status" name="member_status" label={t("label.form.status")} options={
                                                    [
                                                        { value: 'prerecord', label: t("label.status.member.prerecord") },
                                                        { value: 'registered', label: t("label.status.member.registered") },
                                                        { value: 'active', label: t("label.status.member.active") },
                                                        { value: 'suspended', label: t("label.status.member.suspended") },
                                                    ]} sx={{ width: 200 }} />
                                                <Stack direction="row" spacing={2}>
                                                    <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                    <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                </Stack>
                                            </Stack>
                                        </StepContent>
                                    </Step>
                                    <Step key={3}>
                                        <StepLabel>{t("label.step.newCommunityMember.installationTypeStep")}</StepLabel>
                                        <StepContent>
                                            <Box maxWidth="md">
                                                <Stack spacing={2}>
                                                    <Typography variant="subtitle2">{t("label.step.newCommunityMember.installationTypeStep.subtitle")}</Typography>
                                                    <InstallationTypeSelectSlider id="physical_unit_type" name="physical_unit.type" />
                                                    <Stack direction="row" spacing={2}>
                                                        <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                        <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                    <Step key={4}>
                                        <StepLabel>{t("label.step.newCommunityMember.installationClassificationStep")}</StepLabel>
                                        <StepContent>
                                            <Box maxWidth="md">
                                                <Stack spacing={2}>
                                                    <Typography variant="subtitle2">{t("label.step.newCommunityMember.installationClassificationStep.subtitle")}</Typography>
                                                    <InstallationClassificationSelectSlider id="physical_unit_classification" name="physical_unit.classification" installationType={formik.values.physical_unit?.type?.code} />
                                                    <Stack direction="row" spacing={2}>
                                                        <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                        <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                    <Step key={5}>
                                        <StepLabel>{t("label.step.newCommunityMember.installationAddressStep")}</StepLabel>
                                        <StepContent>
                                            <Box maxWidth="md">
                                                <Stack spacing={2}>
                                                    <AddressFormComponents fieldPrefix="physical_unit" disabled={false} />
                                                    <LatitudeLongitudeInput />
                                                    <Stack direction="row" spacing={2}>
                                                        <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                        <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                    <Step key={6}>
                                        <StepLabel>{t("label.step.newCommunityMember.installationDetailsStep")}</StepLabel>
                                        <StepContent>
                                            <Stack spacing={4}>
                                                <PhysicalUnitInformationForm
                                                    showConsumptionData
                                                    showProductionData={installationClassificationsProduction.includes(formik.values?.physical_unit?.classification?.code)}
                                                    showStorageData={installationClassificationsStorage.includes(formik.values?.physical_unit?.classification?.code)}
                                                />

                                                <Stack direction="row" spacing={2}>
                                                    <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} disabled={submitting} />
                                                    <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                </Stack>
                                            </Stack>
                                        </StepContent>
                                    </Step>
                                    <Step key={7}>
                                        <StepLabel>{t("label.step.newCommunityMember.physicalUnitOwnerStep")}</StepLabel>
                                        <StepContent>
                                            <Box maxHeight={"lg"}>
                                                <Stack spacing={2}>
                                                    <Typography variant="subtitle2">{t("label.step.newCommunityMember.physicalUnitOwnerStep.subtitle")}</Typography>
                                                    <Stack spacing={1}>
                                                        <IndieTextField id="physical_unit_owner_name" name='physical_unit_owner.name' label={t("label.form.name")} sx={{ width: 400 }} size="small" />
                                                        <IndieFiscalNumberTextField id="physical_unit_owner_fiscal_number" name='physical_unit_owner.fiscal_number' label={t("label.form.fiscalNumber")} sx={{ width: 200 }} />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2}>
                                                        <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} />
                                                        <IndieContinueButton id="next_step_button" variant="contained" type="submit" />
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                    <Step key={8}>
                                        <StepLabel>{t("label.step.newCommunityMember.confirmationStep")}</StepLabel>
                                        <StepContent>
                                            <Stack spacing={4}>
                                                <Typography variant="subtitle2">{t("label.step.newCommunityMember.confirmationStep.subtitle")}</Typography>
                                                <Stack spacing={2}>
                                                    <Stack spacing={1}>
                                                        <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityMember.selectCommunityStep")}</Typography>
                                                        <Divider />
                                                        <IndieLabelAndValue label={t("label.form.community")} value={community?.name} />
                                                    </Stack>
                                                    <Stack spacing={1}>
                                                        <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityMember.memberDataStep")}</Typography>
                                                        <Divider />
                                                        <IndieLabelAndValue label={t("label.form.dgegid")} value={formik.values.member_id_dgeg} />
                                                        <IndieLabelAndValue label={t("label.form.status")} value={t(`label.status.member.${formik.values.member_status}`)} />
                                                    </Stack>
                                                    <Stack spacing={1}>
                                                        <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityMember.installationTypeStep")}</Typography>
                                                        <Divider />
                                                        <IndieLabelAndValue label={t("label.form.type")} value={t(`label.installationType.${formik.values.physical_unit.type.name}`)} />
                                                    </Stack>
                                                    <Stack spacing={1}>
                                                        <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityMember.installationClassificationStep")}</Typography>
                                                        <Divider />
                                                        <IndieLabelAndValue label={t("label.form.classification")} value={t(`label.installationClassification.${formik.values.physical_unit.classification.name}`)} />
                                                    </Stack>
                                                    <Stack spacing={1}>
                                                        <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityMember.installationAddressStep")}</Typography>
                                                        <Divider />
                                                        <IndieLabelAndValue label={t("label.form.zipCode")} value={formik.values.physical_unit.zip_code} />
                                                        <IndieLabelAndValue label={t("label.form.address")} value={formik.values.physical_unit.address} />
                                                        <IndieLabelAndValue label={t("label.form.doorNumber")} value={formik.values.physical_unit.door_number} />
                                                        <IndieLabelAndValue label={t("label.form.apartmentNumber")} value={formik.values.physical_unit.apartment_number} />
                                                    </Stack>
                                                    <Stack spacing={1}>
                                                        <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityMember.installationDetailsStep")}</Typography>
                                                        <Divider />
                                                        <IndieLabelAndValue label={t("label.form.consumptionDeliveryPoint")} value={formik.values.physical_unit.consumption_deliverypoint} />
                                                        <IndieLabelAndValue label={t("label.form.certifiedPower")} value={formik.values.physical_unit.certified_power} />
                                                        <IndieLabelAndValue label={t("label.form.tensionLevel")} value={t(formik.values.physical_unit.tension_level_object?.label)} />
                                                        <IndieLabelAndValue label={t("label.form.hasEvcp")} value={t(formik.values.physical_unit.has_evcp === 1 ? "label.form.yes" : "label.form.no")} />
                                                    </Stack>
                                                    {installationClassificationsProduction.includes(formik.values?.physical_unit?.classification.code) && (
                                                        <Stack spacing={1}>
                                                            <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityMember.installationDetailsStep.productionInstallationData")}</Typography>
                                                            <Divider />
                                                            <IndieLabelAndValue label={t("label.form.productionDeliveryPoint")} value={formik.values.physical_unit.production_deliverypoint} />
                                                            <IndieLabelAndValue label={t("label.form.installedPowerKWh")} value={formik.values.physical_unit.installed_power} />
                                                            <IndieLabelAndValue label={t("label.form.installedPowerKva")} value={formik.values.physical_unit.installed_power_kva} />
                                                            <IndieLabelAndValue label={t("label.form.connectedPowerKWh")} value={formik.values.physical_unit.connection_power} />
                                                            <IndieLabelAndValue label={t("label.form.connectedPowerKva")} value={formik.values.physical_unit.connection_power_kva} />
                                                            <IndieLabelAndValue label={t("label.form.generatorPowerKWh")} value={formik.values.physical_unit.generator_power_kwh} />
                                                            <IndieLabelAndValue label={t("label.form.productionType")} value={formik.values.physical_unit.production_type?.description} />
                                                            <IndieLabelAndValue label={t("label.form.installationType")} value={formik.values.physical_unit.installation_type?.description} />
                                                            <IndieLabelAndValue label={t("label.form.registrationNumber")} value={formik.values.physical_unit.licencing_registration_number} />
                                                            <IndieLabelAndValue label={t("label.form.recordNumber")} value={formik.values.physical_unit.licencing_record_number} />
                                                        </Stack>)}
                                                    {installationClassificationsStorage.includes(formik.values?.physical_unit?.classification.code) && (
                                                        <Stack spacing={1}>
                                                            <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityMember.installationDetailsStep.storageInstallationData")}</Typography>
                                                            <Divider />
                                                            <IndieLabelAndValue label={t("label.form.storagePowerKWh")} value={formik.values.physical_unit.storage_power_kwh} />
                                                            <IndieLabelAndValue label={t("label.form.storageCapacityKWh")} value={formik.values.physical_unit.storage_capacity_kwh} />
                                                        </Stack>)}
                                                    <Stack spacing={1}>
                                                        <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityMember.physicalUnitOwnerStep")}</Typography>
                                                        <Divider />
                                                        <IndieLabelAndValue label={t("label.form.name")} value={formik.values.physical_unit_owner.name} />
                                                        <IndieLabelAndValue label={t("label.form.fiscalNumber")} value={formik.values.physical_unit_owner.fiscal_number} />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2}>
                                                        <IndieBackButton id="back_button" variant="outlined" onClick={handleBack} disabled={submitting} />
                                                        <IndieSaveButton id="save_button" variant="contained" type="submit" loading={submitting} disabled={submitting} />
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </StepContent>
                                    </Step>
                                </Stepper>
                                {/**<pre>{JSON.stringify(formik.errors)}</pre>*/}
                            </Form>
                        </Fragment>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

const FormikStep7 = ({ activeStep }) => {
    const formik = useFormikContext();

    useEffect(() => {
        if (activeStep === 6 && formik.values.physical_unit?.zip_code) {
            const { physical_unit } = formik.values;
            formik.setFieldValue("physical_unit_owner.zip_code", physical_unit.zip_code);
            formik.setFieldValue("physical_unit_owner.locality", physical_unit.locality);
            formik.setFieldValue("physical_unit_owner.address", physical_unit.address);
            formik.setFieldValue("physical_unit_owner.country", physical_unit.country);
            formik.setFieldValue("physical_unit_owner.country_object", physical_unit.country_object);
            formik.setFieldValue("physical_unit_owner.district", physical_unit.district);
            formik.setFieldValue("physical_unit_owner.district_code", physical_unit.district_code);
            formik.setFieldValue("physical_unit_owner.district_object", physical_unit.district_object);
            formik.setFieldValue("physical_unit_owner.county", physical_unit.county);
            formik.setFieldValue("physical_unit_owner.door_number", physical_unit.door_number);
            formik.setFieldValue("physical_unit_owner.apartment_number", physical_unit.apartment_number);
        }
    }, [activeStep]);

    return null;
};

export default NewCommunityMemberConfigureInstallationDialog