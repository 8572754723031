import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import FlexVerticalAlignCenter from "./FlexVerticalAlignCenter";
import FlexRowAlign from "./FlexRowAlign";

export const CenteredSmallBox = ({children}) => {

    const theme = useTheme();
    return (
        <FlexVerticalAlignCenter id="vertical_center_box">
            <FlexRowAlign id="horizontal_center_box">
                <Box id="content_box" maxWidth={theme.breakpoints.values.sm} sx={{mr:1, ml:1}}>
                    {children}
                </Box>
            </FlexRowAlign>
        </FlexVerticalAlignCenter>
    )
};