import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import { createContext, useContext, useEffect, useState } from "react";
import { useAgentAPIService } from "service/core/AgentAPIService";
import { useTariffsAPIService } from "service/core/TariffsAPIService";
import { useEntitiesAPI } from "service/EntitiesAPIService";

export const AgentContext = createContext();

export const AgentContextProvider = ({ id, entityId, ...props}) => {

    const service = useAgentAPIService();
    const tariffsService = useTariffsAPIService();
    const entitiesService = useEntitiesAPI();

    const { setAndShowErrorMessage, setAndShowSuccessMessage } = useContext(UserFeedbackContext);

    // State
    const [agent, setAgent] = useState(null);

    const [submitting, setSubmitting] = useState(false);
    const [loadingAgent, setLoadingAgent] = useState(true);

    const get = () => {
        setSubmitting(true);
        setLoadingAgent(true);
        service.get(id).then((response) => {
            setAgent(response.data);
            setSubmitting(false);
            setLoadingAgent(false);
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
            setLoadingAgent(false);
        })
    }

    const getEntityAgent = () => {
        setSubmitting(true);
        setLoadingAgent(true);
        entitiesService.agent(entityId).then((response) => {
            setAgent(response.data);
        }).catch((e) => {
            setAndShowErrorMessage(e);
        }).finally(() => {
            setSubmitting(false);
            setLoadingAgent(false);
        });
            
    }

    const list = () => {
        setLoadingAgent(true);
        const filters = [];
        if (entityId) {
            filters.push({id: "entity", value: entityId});
        }

        service.list(filters, 1, 1, null, (response) => {
            setAgent(response.data.results[0]);
            setLoadingAgent(false);
        }, (e) => {
            setAndShowErrorMessage(e);
            setLoadingAgent(false);
        });
    }

    const update = (data) => {
        setSubmitting(true);
        service.update(agent.id, data).then((response) => {
            setAgent(response.data);
            setSubmitting(false);
            setAndShowSuccessMessage("success.api.save");
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
        })
    }

    const sendRegistrationEmail = () => {
        setSubmitting(true);
        service.sendRegistrationEmail(id).then((response) => {
            setAndShowSuccessMessage("message.agent.emailSent");
            setSubmitting(false);
        }).catch((e) => {
            setAndShowErrorMessage(e);
            setSubmitting(false);
        })
    };

    const getTariffModels = (filters=[], page_size, page, sortColumns, callBack, errorCallBack) => {
        tariffsService.getTariffModels(
            [{id: "agent", value: agent.id}].concat(filters),
            page_size, page, sortColumns, callBack, errorCallBack
        );
    }

    const getPowertradingTariffModels = (filters=[], page_size, page, sortColumns, callBack, errorCallBack) => {
        tariffsService.getTariffModels(
            [{id: "agent", value: agent.id}, {id:"business_type", value: "aggregation"}].concat(filters),
            page_size, page, sortColumns, callBack, errorCallBack
        );
    }

    const getCommunitiesTariffModels = (filters=[], page_size, page, sortColumns, callBack, errorCallBack) => {
        tariffsService.getTariffModels(
            [{id: "agent", value: agent.id}, {id:"business_type", value: "communities"}].concat(filters),
            page_size, page, sortColumns, callBack, errorCallBack
        );
    }


    useEffect(() => {
        if (id) {
            get();
        } else if (entityId) {
            getEntityAgent();
        } else {
            list();
        }
    }, []);

    useEffect(() => {
        if (id) {
            get();
        }
    }, [id]);

    useEffect(() => {
        if (entityId) {
            getEntityAgent();
        }
    }, [entityId]);

    return (
        <AgentContext.Provider value={{
            agent, update, submitting, sendRegistrationEmail, loadingAgent,
            getTariffModels, getPowertradingTariffModels, getCommunitiesTariffModels
        }}>
            {props.children}
        </AgentContext.Provider>
    )
}