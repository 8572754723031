import IndieStaticSelect from "components/forms/IndieStaticSelect";

const ContractDurationSelect = ({...params}) => {

    return (
        <IndieStaticSelect
            {...params}
            options={[
                {value: 3, label: "3"},
                {value: 6, label: "6"},
                {value: 12, label: "12"},
                {value: 24, label: "24"},
                {value: 60, label: "60"}
            ]}
            sx={{width: 250}}
            />
    )
}

export default ContractDurationSelect;