import useAxios from "utils/useAxios";

const BASE_URL = `/core/entities/`;

export const useEntitiesAPI = () => {
    
    const {api,nonCachedRequest} = useAxios();

    const fetch = (page=1, page_size = 10, filters=[], callBack, errorCallBack) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}`;
        filters.map((filter) => url += `&${filter.id.replace('.','__')}__contains=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const fetchById = (id, callBack, errorCallBack) => {
        if (!id || id == null) {
            return;
        }
        nonCachedRequest(`${BASE_URL}${id}`, callBack, errorCallBack);
    }

    const fetchByUsername = (username, callBack, errorCallBack) => {
        if (!username || username == null) {
            return;
        }
        nonCachedRequest(`${BASE_URL}?username=${username}`, callBack, errorCallBack);
    }

    const fetchByFiscalNumber = (fiscalNumber, callBack, errorCallBack) => {
        if (!fiscalNumber || fiscalNumber == null) {
            return;
        }
        nonCachedRequest(`${BASE_URL}?fiscal_number=${fiscalNumber}`, callBack, errorCallBack);
    }

    const save = (values, callBack, errorCallBack) => {
        api.post(BASE_URL, values).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        })
    };

    const update = (id, values, callBack, errorCallBack) => {
        api.put(BASE_URL + id + "/", values).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        })
    };

    const fetchEntityMembers = (id, values, callBack, errorCallBack) => {
        api.get(BASE_URL + id + "/members", values).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        })
    };

    const fetchSAFTFiles = (page, id, filters = [], sortColumns, callBack, errorCallBack) => {
        const url = BASE_URL + id + "/saft?page="+page;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replace('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const fetchAgreement = (entityId, agentEntityId, callBack, errorCallBack) => {
        if (!entityId || !agentEntityId) {
            console.error("fetchAgreement: entityId e agentEntityId são obrigatórios.");
            return;
        }
        
        const url = `${BASE_URL}${entityId}/get_ai_agreement?agent_entity_id=${agentEntityId}`;
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const agent = (id) => {
        return api.get(`${BASE_URL}${id}/agent/`);
    }

    return {
        fetch,
        fetchById,
        save,
        update,
        fetchByUsername,
        fetchEntityMembers,
        fetchSAFTFiles,
        fetchByFiscalNumber,
        fetchAgreement,
        agent
    }
};

