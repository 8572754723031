import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";

const IndieEditButton = ({label, permissionGroups, ...params}) => {
    const { t } = useTranslation();
    const { hasPermissionGroup } = useAuth();

    if (permissionGroups && !hasPermissionGroup(permissionGroups)) {
        return "";
    }

    return <Button startIcon={<Edit/>} variant="outlined" {...params}>{label ? label : t("label.button.edit")}</Button>
}

export default IndieEditButton;