import { AccountBalanceOutlined, AdminPanelSettingsOutlined, BalanceOutlined, FileOpenOutlined, HouseOutlined, LocationCityOutlined, OfflineBoltOutlined, PersonOutlineOutlined, PriceChangeOutlined, ReceiptOutlined, SettingsPowerOutlined } from "@mui/icons-material";

export const navigations = [
    {
        name: "label.menu.home",
        icon: HouseOutlined,
        path: "/home",
        auth: "all",
    },
    {
        name: "label.menu.myData",
        path: "/account/member",
        groups: ["CLIENT","ENERGY_AGENT_USER", "INDIE_ADMIN", "!ENERGY_AGENT_ADMIN"],
        auth: "view_member_area",
        icon: PersonOutlineOutlined,
    },
    {
        name: "label.menu.myData",
        path: "/account/agent",
        groups: ["ENERGY_AGENT_ADMIN"],
        auth: "view_agent_area",
        icon: PersonOutlineOutlined,
    },
    {
        name: "label.menu.saftFiles",
        path: "/saft",
        groups: ["CLIENT"],
        auth: "is_superuser;view_member_area",
        icon: AccountBalanceOutlined,
    },
    {
        name: "label.menu.termsAndConditions",
        path: "/terms",
        groups: ["CLIENT"],
        auth: "!is_superuser;view_member_area",
        icon: ReceiptOutlined,
    },
    {
        name: "Administration",
        icon: AdminPanelSettingsOutlined,
        auth: "is_superuser;add_agent",
        children: [
            {
                name: "label.title.agents",
                path: "/admin/agents/",
                auth: "add_agent",
            },
            /**{
                name: "label.title.entities",
                path: "/entities/",
                auth: "view_mstentities",
            },*/
        ],
    },
    {
        name: "label.menu.tariffsPlans",
        icon: PriceChangeOutlined,
        auth: "view_tariffmodels;view_contracts",
        groups: ["INDIE_ADMIN","ENERGY_AGENT_ADMIN","ENERGY_AGENT_USER"],
        children: [
            {
                name: "label.menu.tariffModels",
                path: "/tariff-models/",
                auth: "view_tariffmodels",
            },
            {
                name: "label.menu.communities",
                path: "/contracts/communities/",
                auth: "view_contracts",
                groups: ["INDIE_ADMIN", "ENERGY_AGENT_EGAC_USER"],
            },
            {
                name: "label.menu.powertrading",
                path: "/contracts/powertrading/",
                auth: "view_contracts",
                groups: ["INDIE_ADMIN", "ENERGY_AGENT_AGGRTN_USER"],
            },
        ]
    },
    {
        name: "Energy Communities",
        icon: LocationCityOutlined,
        auth: "view_mstcommunities;view_communityinstallation",
        children: [
            {
                name: "Communities",
                path: "/communities/communities",
                auth: "view_mstcommunities",
            },
            {
                name: "Installations",
                path: "/communities/installations/",
                auth: "view_communityinstallation",
            },
            {
                name: "label.menu.invoicingClient",
                path: "/clients/",
                auth: "view_mstcommunities",
            },
        ]
    },
    {
        name: "label.menu.aggregation",
        icon: OfflineBoltOutlined,
        auth: "view_tradingmarketsessions;view_producers;view_energyfileprocessingrequest",
        children: [
            {
                name: "label.menu.dailyMarkets",
                path: "/markets/dayahead/submit/",
                auth: "view_tradingmarketsessions",
            },
            {
                name: "label.menu.intraDayMarkets",
                path: "/markets/intraday/submit/",
                auth: "view_tradingmarketsessions",
            },
            {
                name: "label.menu.settlements",
                path: "/markets/settlements/energy/",
                auth: "view_tradingmarketsessions",
            },
            {
                name: "label.menu.marketUnits",
                path: "/market-units/",
                auth: "view_tradingofferingunits",
            },
            {
                name: "label.menu.producers",
                path: "/producers/",
                auth: "view_producers",
            },
            {
                name: "label.menu.invoicingClient",
                path: "/clients/",
                auth: "view_producers",
            },
            {
                name: "label.menu.dataUpload",
                path: "/data/",
                auth: "view_energyfileprocessingrequest",
            },
        ]
    },
    {
        name: "label.menu.powerManagement",
        icon: SettingsPowerOutlined,
        auth: "view_forecasttypes",
        children: [
            {
                name: "label.menu.forecastTypes",
                path: "/energy-management/forecasts/types/",
                auth: "view_forecasttypes",
            }
        ]
    },
    /**{
        name: "Installations",
        icon: ElectricMeterTwoTone,
        auth: "view_astmeters",
        children: [
            {
                name: "Consumption Instalations",
                path: "/consumption-installations/",
                auth: "view_astmeters",
            },
            {
                name: "Production Units",
                path: "/production-units/",
                auth: "view_astproductionunits",
            },
            {
                name: "Storage Installations",
                path: "/storage-installatons/",
                auth: "view_astmeters",
            },
        ]
    },*/
    {
        name: "label.menu.invoicing",
        icon: BalanceOutlined,
        auth: "view_slsinvoices",
        children: [
            {
                name: "label.menu.powertrading",
                path: "/invoicing/powertrading/",
                groups: ["INDIE_ADMIN","ENERGY_AGENT_AGGRTN_USER"],
                auth: "view_slsinvoices",
            },
            {
                name: "label.menu.communities",
                path: "/invoicing/communities/",
                groups: ["INDIE_ADMIN","ENERGY_AGENT_EGAC_USER"],
                auth: "view_slsinvoices",
            }
        ]
    },
    {
        name: "Reports",
        icon: FileOpenOutlined,
        auth: "view_reports",
        path: "/reports/",
    }
];