import { CircularProgress, FormControl, FormHelperText, InputAdornment, Menu, MenuItem, Stack, Typography } from "@mui/material";
import IndieNumberField from "components/forms/IndieNumberField";
import { useField } from "formik";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAddressesAPI } from "service/AddressesAPIService";

const IndieZipCodeField = ({ ...props }) => {

    const { t } = useTranslation();
    const [field, meta, helpers] = useField(props);
    const addressesAPI = useAddressesAPI();
    // State
    const [zipCode, setZipCode] = useState(field.value);
    const [zipCodePart1, setZipCodePart1] = useState("");
    const [zipCodePart2, setZipCodePart2] = useState("");
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const zipCodePart2Ref = useRef(null);
    const menuRef = useRef(null);


    const fieldPrefix  = props.name.replace(".","_");

    const fetchZipCodes = (value) => {
        setLoading(true);
        addressesAPI.zipcode(value, (response) => {
            let _anchorEl = document.querySelector(`#${fieldPrefix}_part1`);
            setAnchorEl(_anchorEl);
            setOptions(response.data.results);
            setLoading(false);
        }, (error) => {
            console.log(error);
            setLoading(false);
        });
    };

    // Search is debounced to avoid searching while user is typing.
    const debounceFetchZipCodes = useCallback(debounce(fetchZipCodes, 500), []);

    const getUnifiedFieldValue = (part1, part2) => {
        if(!part1 && !part2) {
            return "";
        } else {
            return `${part1}-${part2}`;
        }
    }
    const onChangeZipCodePart1 = (e) => {
        const value = e.target.value;
        // If length is greater than 4 we don't allow more characters
        if (value.length > 4) {
            return false;
        }
        setZipCodePart1(value);

        const v = getUnifiedFieldValue(value, zipCodePart2);

        if (value.length === 4) {
            zipCodePart2Ref.current.focus(); // Move focus to the second input field
        }
        
        if (v.length === 8) {
            helpers.setValue(v);
            setZipCode(v);
        } else {
            setZipCode(null);
        }

        if(props.onChange) {
            props.onChange(v);
        }
    }

    const onChangeZipCodePart2 = (e) => {
        // If length is greater than 4 we don't allow more characters
        if (e.target.value.length > 3) {
            return false;
        }
        setZipCodePart2(e.target.value);
        const v = getUnifiedFieldValue(zipCodePart1, e.target.value);
        if (v.length === 8) {
            helpers.setValue(v);
            setZipCode(v);
        } else {
            setZipCode(null);
        }

        if(props.onChange) {
            props.onChange(v);
        }
    }

    const handleOptionClick = (option) => {
        helpers.setValue(option.zip_code);
        setZipCode(option.zip_code);
        setAnchorEl(null);
        if(props.onChange) {
            props.onChange(option);
        }
    }

    useEffect(() => {
        if (field.value && field.value.length === 8) {
            const parts = field.value.split("-");
            setZipCodePart1(parts[0] || zipCodePart1);
            setZipCodePart2(parts[1] || zipCodePart2);
        } else {
            setZipCodePart1("");
            setZipCodePart2("");
        }
    }, []);

    useEffect(() => {
        if (field.value && field.value.length === 8) {
            const parts = field.value.split("-");
            setZipCodePart1(parts[0]);
            setZipCodePart2(parts[1]);
        }
    }, [field.value]);

    useEffect(() => {
        if (zipCodePart1.length === 4 && zipCodePart2.length === 3 && zipCode.length === 8 && !props.disabled) {
            debounceFetchZipCodes(zipCode);
        }
    }, [zipCode]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                // Prevent clearing the values when clicking outside
                // You can add any additional logic here if needed
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <FormControl>
            <Stack direction={"row"} spacing={2}>
                <IndieNumberField
                    {...props}
                    id={`${fieldPrefix}_part1`} name={`${fieldPrefix}_part1`} value={zipCodePart1} label={t("label.form.zipCode")}
                    onChange={onChangeZipCodePart1}
                    sx={{ width: 100 }}
                    disabled={props.disabled || loading}
                />
                <IndieNumberField
                    {...props}
                    id={`${fieldPrefix}_part2`} name={`${fieldPrefix}_part2`} value={zipCodePart2} label={" "} 
                    onChange={onChangeZipCodePart2}
                    inputRef={zipCodePart2Ref} // Set the ref to the second input field
                    sx={{ width: 100 }}
                    disabled={props.disabled || loading}
                    slotProps={{
                        endAdornment: loading ? (
                            <InputAdornment position="end">
                                <CircularProgress size={20} />
                            </InputAdornment>
                        ) : null,
                    }}
                />
                {loading ? (
                    null
                ) : (
                    options.length > 0 && (
                        <Menu
                            id="zip_code_menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl) && options.length > 0}
                            onClose={() => setAnchorEl(null)}
                            ref={menuRef}
                            slotProps={{
                                paper: {style: {
                                    maxHeight: 200, // Set maxHeight for the dropdown
                                    width: 'auto',
                                }},
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem id={`zip_code_option_${index}`} key={index} onClick={() => handleOptionClick(option)}>
                                    <Stack spacing={0}>
                                        <Typography variant="body2">{option.zip_code} {option.zip_code_designation}</Typography>
                                        <Typography variant="body2">{option.address}</Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Menu>

                    )
                )}
            </Stack>
            {meta.error ? (<FormHelperText id={`${fieldPrefix}_helper_text`} error sx={{ml:0}}>{meta.error}</FormHelperText>) : null}
        </FormControl>
    )
}

export default IndieZipCodeField;