import { CircularProgress, FormControl, FormHelperText, InputAdornment, TextField } from "@mui/material";
import { useField } from "formik";

const IndieFiscalNumberTextField = ({ label, onChangeField, loading, ...props }) => {
    const [field, meta, helpers] = useField(props);

    const handleInputChange = (event) => {
        const { value } = event.target;
        // Restrict input if the value contains invalid characters
        if (/^[0-9]*$/.test(value)) {
            helpers.setValue(value ? value.toUpperCase() : "");

            if (onChangeField) {
                onChangeField(event);
            }
        }
    };

    return (
        <FormControl id={`form_control_${props.name}`} fullWidth={props.fullWidth}>
            <TextField
                {...field}
                {...props}
                label={label}
                onChange={handleInputChange}
                variant="standard"
                value={field.value || ""}
                sx={{ width: 150 }}
                InputProps={{
                    endAdornment: loading ? (
                        <InputAdornment position="end">
                            <CircularProgress size={20} />
                        </InputAdornment>
                    ) : null,
                }}
            />
            {meta.error ? (
                <FormHelperText id={`form_error_text_${props.name}`} error sx={{ ml: 0, pl: 0 }}>{meta.error}</FormHelperText>
            ) : null}
        </FormControl>
    );
}

export default IndieFiscalNumberTextField;