import { Skeleton } from '@mui/material';
import { AdvancedMarker, APIProvider, InfoWindow, Map, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { Fragment, useCallback, useEffect, useState } from 'react';

const MarkerWithInfoWindow = ({item, key}) => {
    // `markerRef` and `marker` are needed to establish the connection between
    // the marker and infowindow (if you're using the Marker component, you
    // can use the `useMarkerRef` hook instead).
    const [markerRef, marker] = useAdvancedMarkerRef();
  
    const [infoWindowShown, setInfoWindowShown] = useState(false);
  
    // clicking the marker will toggle the infowindow
    const handleMarkerClick = useCallback(
      () => setInfoWindowShown(isShown => !isShown),
      []
    );
  
    // if the maps api closes the infowindow, we have to synchronize our state
    const handleClose = useCallback(() => setInfoWindowShown(false), []);
    
    if(!item.latitude || !item.longitude) {
        return null;
    }

    return (
      <Fragment key={key}>
        <AdvancedMarker
          ref={markerRef}
          position={{lat: item.latitude, lng: item.longitude}}
          onClick={handleMarkerClick}
        >
            {item.Pin}
        </AdvancedMarker>
  
        {infoWindowShown && (
          <InfoWindow anchor={marker} onClose={handleClose}>
            {item.infoWindow}
          </InfoWindow>
        )}
      </Fragment>
    );
  };

const IndieApplicationListMap = ({ items }) => {

    const [loading, setLoading] = useState(true);
    const [mapItems, setMapItems] = useState([]);
    const mapStyles = {
        height: "500px",
        width: "100%"
    };

    const getDefaultCenter = () => {
        // Calculate the center of the map based on the items
        let lat = 0;
        let lng = 0;
        mapItems.forEach(item => {
            lat += item.latitude;
            lng += item.longitude;
        });

        return {
            lat: lat / mapItems.length,
            lng: lng / mapItems.length
        };
    }

    useEffect(() => {
        setMapItems(items.filter(item => item.latitude && item.longitude));
        setLoading(false);
    }, [items]);

    if (loading) {
        return <Skeleton variant="rectangular" width="100%" height={500} />;
    }

    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <div style={mapStyles}>
                <Map
                    defaultZoom={8}
                    defaultCenter={getDefaultCenter()}
                    mapId='member_installations'
                >
                    {mapItems.map((location, index) => (
                        <MarkerWithInfoWindow item={location} key={index} />
                    ))}
                </Map>
            </div>
        </APIProvider>
    );
};

export default IndieApplicationListMap;