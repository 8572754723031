import {FormControlLabel, Checkbox} from "@mui/material";
import { useField } from "formik";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";

const IndieCheckbox = ({id, name, label, permissionGroups, ...params}) => {
    const [field, , helpers] = useField(name || id);
    const [selectedKey, setSelectedKey] = useState(0);
    const { hasPermissionGroup } = useAuth();

    const onChange = (e) => { 
        helpers.setValue(e.target.checked);
        params.onChange && params.onChange(e, e.target.checked);
    }

    const checked = field.value === true || field.checked || params.checked;

    useEffect(() => {
        setSelectedKey(selectedKey + 1);
    }, [field.value]);

    if(permissionGroups && ! hasPermissionGroup(permissionGroups)) {
        return "";
    }

    return (
        <FormControlLabel
        key={selectedKey}
            id={id}
            name={name || id}
            label={label}
            control={<Checkbox
                id={`checkbox_${id}`}
                {...params}
                checked={checked}
                onChange={onChange}
                sx={{ mr: 2 }}
            />}
        />
    )
}

export default IndieCheckbox;