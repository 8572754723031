import { useTranslation } from "react-i18next";
import IndieButton from "./IndieButton";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

const IndieContinueButton = ({label, loading, ...params}) => {
    const { t } = useTranslation();
    return <IndieButton startIcon={loading ? <CircularProgress color="inherit" size={18} /> : <ArrowCircleRightOutlined/>} variant="contained" color="primary" {...params}>{label ? label : t("label.button.next")}</IndieButton>
}

export default IndieContinueButton;