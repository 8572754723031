import { Mail, MoreVert } from '@mui/icons-material';
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { CommunityContext } from 'contexts/communities/CommunityContext';
import { UserFeedbackContext } from 'contexts/UserFeedbackContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CommunityMemberActionMenu = ({member}) => {

    const { t } = useTranslation();
    const { sendRegistrationEmail } = useContext(CommunityContext);
    const { setAndShowErrorMessage, setAndShowSuccessMessage, setOpenBackdrop } = useContext(UserFeedbackContext);
    const [memberInstance, setMemberInstance] = useState(member);

    const [anchorEl, setAnchorEl] = useState(null);
    const [options, setOptions] = useState([]);
    const ITEM_HEIGHT = 48;

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const _sendRegistrationEmail = () => {
        setOpenBackdrop(true);
        handleClose();
        sendRegistrationEmail(memberInstance.id).then( (response) => {
            setAndShowSuccessMessage(response.data);
            setOpenBackdrop(false);
        }).catch((error) => {
            setAndShowErrorMessage(error);
            setOpenBackdrop(false);
            
        });
    }


    const getOptions = () => {
        let options = [];
        options.push(
            (key) =>
                <MenuItem
                    key={key}
                    onClick={_sendRegistrationEmail}
                >
                    <ListItemIcon>
                        <Mail fontSize="small" />
                    </ListItemIcon>
                    <ListItemText fontSize={"small"}>{t("label.menu.sendRegistrationEmail")}</ListItemText>
                </MenuItem>
        );
        return options;
    };

    useEffect(() => {
        if (member) {
            setOptions(getOptions());
        }
    }, [member]);

    if (!options || options.length === 0 || !member) {
        return "";
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                className="button_findoc_menu"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        maxWidth: '500px',
                    },
                }}
            >
                {options.map((option, index) => (
                    <Box>
                        {option(index)}
                    </Box>
                ))}
            </Menu>
        </div>
    );
}

export default CommunityMemberActionMenu;