import i18next from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
    en: {
        translation: {
            CRM: "CRM",
            Shop: "Shop",
            Chat: "Chat",
            Sales: "Sales",
            Results: "Results",
            Invoice: "Invoice",
            Payment: "Payment",
            Profile: "Profile",
            Account: "Account",
            Pricing: "Pricing",
            Checkout: "Checkout",
            Sessions: "Sessions",
            Ecommerce: "Ecommerce",
            Dashboards: "Dashboards",
            "Enrolled Courses": "Enrolled Courses",
            "Course Completed": "Course Completed",
            "Course in Progress": "Course in Progress",
            "Learning Management": "Learning Management",
            "Welcome Back! Watson": "Welcome Back! Watson",
            "Job Management": "Job Management",
            "User & Contact": "User & Contact",
            "User List": "User List",
            "User Grid": "User Grid",
            "Contact List": "Contact List",
            "Contact Grid": "Contact Grid",
            "Invoice List": "Invoice List",
            "Invoice Details": "Invoice Details",
            "Create Invoice": "Create Invoice",
            "Product Details": "Product Details",
            "Billing Address": "Billing Address",
            "Payment Complete": "Payment Complete",
            "Admin Ecommerce": "Admin Ecommerce",
            "Product List": "Product List",
            "Product Grid": "Product Grid",
            "Create Product": "Create Product",
            "Upcoming Task": "Upcoming Task",
            "Study Time Last Week": "Study Time Last Week",
            "Your Downloads": "Your Downloads",
            "Course Status": "Course Status",
            "Prospecting-Communities": "Prospecting",
        },
    },
    es: {
        translation: {
            CRM: "CRM",
            Chat: "Chat",
            Shop: "Tienda",
            Sales: "Ventas",
            Payment: "Pago",
            Account: "Cuenta",
            Profile: "Perfil",
            Pricing: "Precios",
            Invoice: "Factura",
            Sessions: "Sesiones",
            Results: "Resultados",
            Checkout: "Verificar",
            Dashboards: "Cuadros de mando",
            Ecommerce: "Comercio electrónico",
            "Upcoming Task": "Próxima tarea",
            "Your Downloads": "Tus descargas",
            "Course Status": "Estado del curso",
            "Course in Progress": "Curso en curso",
            "Enrolled Courses": "Cursos inscritos",
            "Course Completed": "Curso completado",
            "Job Management": "Gestión de trabajos",
            "User & Contact": "Usuario y contacto",
            "User List": "Lista de usuarios",
            "User Grid": "Cuadrícula de usuario",
            "Contact List": "Lista de contactos",
            "Contact Grid": "Cuadrícula de contacto",
            "Invoice List": "Lista de facturas",
            "Create Invoice": "Crear factura",
            "Billing Address": "Dirección de Envio",
            "Payment Complete": "Pago completo",
            "Product List": "Lista de productos",
            "Create Product": "Crear producto",
            "Product Details": "Detalles de producto",
            "Product Grid": "Cuadrícula de productos",
            "Invoice Details": "Detalles de la factura",
            "Learning Management": "Gestión del aprendizaje",
            "Welcome Back! Watson": "¡Bienvenido de nuevo! Watson",
            "Admin Ecommerce": "Administrador de comercio electrónico",
            "Study Time Last Week": "Tiempo de estudio la semana pasada",
        },
    },
    pt: {
        translation: {
            // Menu
            "Energy Communities": "Comunidades de Energia",
            "Administration": "Administração",
            "Manage Entities": "Gestão de Entidades",
            "Manage Members": "Gestão de Membros",
            "Manage Delivery Points": "Gestão de Pontos de Entrega",
            "Member": "Membro",
            "Community of": "Comunidade de",
            "Energy": "Energia",
            "Surplus": "Excedente",
            "Potência": "Power",
            "Storage": "Armazenamento",
            "Active Members": "Membros Ativos",
            "All": "Tudo",
            "Building": "Em Construção",
            "Prospecting-Communities": "Construção",
            "Add Community": "Nova Comunidade",
            "Communities": "Comunidades",
            "Reports": "Relatórios",
            "Forming": "Projeto",
            "Statistics": "Estatisticas",
            "Members": "Membros",
            "Account": "Conta",
            "All Members": "Todos",
            "Consumers": "Consumidores",
            "Add New Member": "Novo Membro",
            "Logout": "Sair",
            "Delivery Points": "Pontos de Entrega",
            "Production Units": "Produção",
            "Installations": "Instalações",
            "Invoices": "Faturação",
            "Hi": "Olá",
            "Add": "Novo",
            "Consumption Instalations": "Consumo",
            "Storage Installations": "Armazenamento",
            // Membros

            "Name": "Nome",
            "Community": "Comunidade",
            "Zip Code": "Código Postal",
            "Status": "Estado",
            "Selling Price": "Preço de Venda",
            "Start Date": "Data de Início",
            "End Date": "Data de Fecho",
            "Network Buying Price": "Preço de Compra Rede",
            "Community Buying Price": "Preço de Compra Comunidade",
            "New Price": "Novo Preço",
            "Installation": "Instalação",
            "Invoicing Entities": "Entidades de Faturação",
            "Member Installation": "Dados da Instalação",
            "Consumtion Delivery Point": "CPE de Consumo",
            "Production Delivery Point": "CPE de Produção",
            "Storage Delivery Point": "CPE de Armazenamento",
            "Billing Period": "Ciclo de faturação",
            "City": "Cidade",
            "Fiscal Number": "Número de Contribuinte",
            "Company?": "Empresa?",
            // Comunidades
            "Overview": "Sumário",
            "View Community": "Ver Comunidade",
            "Community Details": "Detalhes",
            "Sales": "Vendas",
            "Documents": "Documentos",
            
            "Document List": "Documentos Carregados",
            "Production": "Produção",
            // Delivery Points
            "Consumption Installations": "Instalações de Consumo",
            "Address": "Morada",
            "Country": "País",
            "View Consumption Installation": "Ver Instalação de Consumo",
            "Add Consumption Installation": "Nova Instalação de Consumo",
            "Meter Type": "Tipo de Contador",
            "Capacity": "Capacidade",
            "Unit of Measure": "Unidade de Medida",
            //Layout
            "label.layout.greeting":"Olá",
            // Labels Page Titles
            "label.title.testIntegration":"Testar Integração",
            "label.title.invoicing":"Modelo Tarifário e Faturação",
            "label.title.viewTariffModel":"Ver Modelo de Plano Tarifário",
            "label.title.type":"Tipo",
            "label.title.communityDetails":"Detalhes da Comunidade",
            "label.title.communityManagement":"Gestão da Comunidade",
            "label.title.consumption":"Consumo",
            "label.title.configureTariffPlan":"Configurar Plano Tarifário",
            "label.title.personalCompanyInformation":"Informação Pessoal / Empresa",
            "label.title.addressInformation":"Morada",
            "label.title.fiscalFinancialInformation":"Informação Fiscal / Financeira",
            "label.title.receiptInvoicingData":"Informação de Faturação",
            "label.title.receiptHistory":"Histórico de Recebimentos",
            "label.title.newPowertradingContract":"Novo Plano Tarifário",
            "label.title.updateOMIECertificate":"Atualizar Certificado OMIE",
            "label.title.agents":"Agentes",
            "label.title.preRegisterAgent":"Pré-Registo de Agente",
            "label.agent.newEntity.step1":"Tipo de Entidade",
            "label.agent.newEntity.step1Subtitle":"Por favor selecione o tipo de entidade que deseja criar.",
            "label.agent.newEntity.step2":"Dados da Entidade",
            "label.agent.newEntity.step2Subtitle":"Por favor preencha os campos abaixo com os dados da entidade que deseja criar.",
            "label.agent.newEntity.step3":"Dados do Responsável pela Entidade",
            "label.agent.newEntity.step3Subtitle":"Por favor preencha os campos abaixo com os dados do responsável pela entidade que deseja criar.",
            "label.agent.newEntity.step4":"Confirmação",
            "label.agent.newEntity.step4Subtitle":"Por favor confirme os dados inseridos.",
            "label.agent.preRegister.step1":"Tipo de Agente",
            "label.agent.preRegister.step1Subtitle":"Antes de começar, por favor selecione um ou mais tipos de agente para o qual deseja efetuar o registo.",
            "label.agent.preRegister.step2":"Dados do Agente",
            "label.agent.preRegister.step2Subtitle":"Por favor preencha os campos abaixo com os dados do agente que deseja registar.",
            "label.agent.preRegister.step3":"Confirmação",
            "label.agent.type.aggregator":"Agregador",
            "label.agent.type.aggregatorDescription":"Um agregador, ou agregador de mercado, é uma entidade que atua como intermediária entre os consumidores e as empresas do setor elétrico, com o objetivo de negociar melhores condições e preços.",
            "label.agent.type.egac":"EGAC",
            "label.agent.type.egacDescription":"A Entidade Gestora do Autoconsumo Coletivo (EGAC), a designar pelos respetivos membros, representa o autoconsumo coletivo perante operadores e entidades administrativas.",
            "label.agent.type.marketer":"Comercializadora",
            "label.agent.type.marketerDescription":"A comercializadora, mediadora entre produtores e consumidores, negocia com as produtoras de energia e organiza a distribuição por meio de contratos equitativos.",
            "label.agent.type.ese":"ESE",
            "label.agent.type.eseDescription":"Empresa de Serviços de Energia, reconhecida pela sigla ESE, refere-se à entidade que investe, desenvolve e gere projetos de eficiência energética.",
            "label.title.tariffModels" : "Modelos de Planos Tarifários",
            "label.title.newTariffModel":"Novo Modelo de Plano Tarifário",
            "label.step.newTariffModel.name":"Nome do Modelo",
            "label.step.newTariffModel.planType":"Tipo de Modelo de Plano Tarifário",
            "label.step.newTariffModel.transactionType":"Tipo de Transação",
            "label.title.markets":"Mercado Diário / Intradiário",
            "label.title.paymentsAndReceiptsDetails":"Detalhes de Pagamentos e Recebimentos",
            "label.title.results":"Resultados",
            "label.title.receivable":"Pagamentos e Recebimentos",
            "label.title.energy":"Energia",
            "label.title.programmeResults":"Resultados dos Programas",
            "label.title.programmeDate":"Data do Programa",
            "label.title.intradayMarkets":"Mercados Intra-Diários",
            "label.title.integrationsConfiguration":"Configuração de Integrações",
            "label.title.reports":"Relatórios",
            "label.title.preRegistration":"Pré-Registo",
            "label.title.activation":"Em Ativação",
            "label.title.activeContracts":"Ativos",
            "label.title.refused":"Recusados",
            "label.title.closed":"Fechados",
            "label.title.dgegData":"Dados de acesso à área reservada na DGEG",
            "label.title.egacEredesData":"Dados de acesso à área reservada da EGAC na E-Redes",
            "label.text.sensitiveDataAccessibleIndie":"Os dados inseridos nesta área são sensíveis e poderão ser visualizados por um administrador da plataforma Indie Energy para fins relacionados com obtenção de dados relacionados com a sua atividade.",
            "label.title.issueDate":"Data de Emissão",
            "label.title.dataUpload":"Carregamento de Dados de Energia",
            "label.title.uploadDocuments":"Carregar Documentos",
            "label.title.dialog.actionLog":"Histórico de Ações",
            "label.title.dialog.secretValue":"Visualizar Dados Sensíveis",
            "label.title.dialog.documentUpload":"Carregar Documentos",
            "label.title.dialog.validationLog":"Validação de Contrato",
            "label.title.editFinancialDocument":"Editar Documento",
            "label.title.viewFinancialDocument":"Visualizar Documento",
            "label.title.notAddress":"Sem Morada",
            "label.title.noPlanSelected":"Sem Plano",
            "label.title.noProductSelected":"Sem Produto",
            "label.title.noExternalMeterId":"Código Meter Não Disponível",
            "label.title.noInstalledPower":"N/A",
            "label.title.termsAndConditions":"Termos e Condições de Utilização da Aplicação da Indie Energy",
            "label.title.authorizedUse":"Utilização da APlicação Indie Energy",
            "label.title.lawsAndLitigations":"Lei Aplicável e Resolução de Litígios",
            "label.title.conditionModifications":"Alteração das Condições",
            "label.title.personalDataProtection":"Proteção de Dados Pessoais",
            "label.title.clientObligations":"Obrigações do Cliente",
            "label.title.responsabilities":"Responsabilidade",
            "label.title.definitions":"Definições",
            "label.title.overview":"Visão Geral",
            "label.title.ownership":"Propriedade",
            "label.title.loginForm":"Início de Sessão",
            "label.title.registrationForm":"Formulário de Registo",
            "label.title.search":"Pesquisa",
            "label.title.offers":"Ofertas",
            "label.title.offerDetails":"Detalhes da Oferta",
            "label.title.offerSubmission":"Submissão de Ofertas",
            "label.title.offerView":"Consulta de Ofertas",
            "label.title.marketAgent":"Agente de Mercado",
            "label.title.marketProgramme":"Programa de Mercado",
            "label.title.contractTerms":"Termos do Contrato",
            "label.title.contractSigningandEndDates":"Assinatura / Cessação do Contrato",
            "label.title.newReportRequest":"Novo Pedido de Relatório",
            "label.title.newForecastTypeInstance":"Nova Previsão",
            "label.title.editForecastTypeInstance":"Modificar Previsão",
            "label.title.exportSurplusData": "Descarregar Documento Excedente",
            "label.title.description": "Descrição",
            "label.title.ggsDeviations":" Gestão Global de Sistema (GGS) - Desvios",
            "label.title.deviations": "Desvios",
            "label.title.referenceStartDate": "Data de Referência Inicio",
            "label.title.referenceEndDate": "Data de Referência Fim",
            "label.title.documentsUpload": "Carregar Documentos",
            "label.title.documentType":"Tipo de Documento",
            "label.title.documentHeader":"Cabeçalho do Documento",
            "label.title.documentDetails":"Detalhes do Documento",
            "label.title.productionUnits": "Unidades de Produção",
            "label.title.producerExists": "Produtor Existente",
            "label.title.viewProductionUnit": "Ver Unidade de Produção",
            "label.title.viewProcessDetails":"Ver Detalhes do Processo",
            "label.title.energyKWh":"Energia (kWh)",
            "label.title.entities": "Entidades",
            "label.title.newEntity": "Nova Entidade",
            "label.title.newFinancialDocument":"Novo Documento",
            "label.title.viewEntity": "Ver Entidade",
            "label.title.members": "Membros",
            "label.title.newMember": "Novo Membro",
            "label.title.viewMember": "Ver Membro",
            "label.title.contactInformation": "Dados de Contacto",
            "label.title.agentInformation": "Dados do Agente",
            "label.title.consumptionInstallation": "Instalação de Consumo",
            "label.title.technicalDetails": "Informação Técnica",
            "label.title.solarPanels": "Painéis Solares",
            "label.title.inverters": "Inversores",
            "label.title.installationLicencing":"Licenciamento da Instalação",
            "label.title.identity":"Identificação",
            "label.title.productionUnit": "Unidade de Produção (UPAC)",
            "label.title.registrationInformation": "Informação de Registo",
            "label.title.receivingConditions":"Dados e Condições de Recebimentos",
            "label.title.others": "Outras Informações",
            "label.title.invoicingInformation": "Dados de Faturação",
            "label.title.paymentInformation": "Dados para Recebimentos",
            "label.title.memberCommunity": "Dados da Comunidade",
            "label.title.viewInvoice": "Consultar Fatura",
            "label.title.invoiceInfo": "Dados de Fatura",
            "label.title.invoices": "Faturas",
            "label.title.due": "Vencidas",
            "label.title.unpaid": "Por Pagar",
            "label.title.unitValue":"Valor Unit.",
            "label.title.netTotal":"Valor Líquido",
            "label.title.taxes":"Impostos",
            "label.title.programming":"Programação",
            "label.title.discountPercentage":"Desconto",
            "label.title.discountValue":"Total com Desconto",
            "label.title.totalEnergy":"Total Energia Contratada",
            "label.title.totalAmmount":"Valor a Receber/Pagar",
            "label.title.averagePrice":"Preço Médio",
            "label.title.total":"Total",
            "label.title.all":"Todas",
            "label.title.upacOwner":"Proprietário da UPAC",
            "label.title.communities":"Comunidades",
            "label.title.communityOf":"Comunidade de",
            "label.title.newPrice":"Atualizar Preços",
            "label.title.producers":"Produtores",
            "label.title.address":"Morada",
            "label.title.meter":"Meter",
            "label.title.contactInfo":"Dados de Contacto",
            "label.title.lastReceipt":"Último Recebimento",
            "label.title.goBackConfirmation":"Deseja mesmo voltar atrás?",
            "label.title.physicalUnitDetails":"Caracterização da Instalação",
            "label.title.commercialAgentData": "Dados do Agente Comercial",
            "label.title.fileName":"Nome do Ficheiro",
            "label.title.fileTitle":"Título",
            "label.title.fileType":"Tipo de Ficheiro",
            "label.title.forecastTypes":"Tipos de Previsão",
            "label.title.uploadFiles":"Carregar Documentos",
            "label.title.contractDocuments":"Lista de Documentos",
            "label.title.noPhysicalUnit":"Sem Instalação",
            "label.title.paid":"Liquidadas",
            "label.title.pending":"Pendentes",
            "label.title.processed":"Processadas",
            "label.title.viewAggregator": "Ver Agregador",
            "label.title.viewAgent": "Ver Agente de Mercado",
            "label.title.processingResult": "Resultado do Processamento de Documentos",
            "label.title.approvalResult": "Resultado do Processamento de Documentos",
            "label.title.agentCodes":"Códigos REN / OMIE",
            "label.title.omieCertificate":"Certificado OMIE",
            "label.title.certificatePassword":"Password Certificado OMIE",
            "label.title.marketPhysicalUnits":"Unidades Físicas",
            "label.title.newPhysicalUnit":"Nova Unidade Física",
            "label.title.newOfferingUnit":"Nova Unidade de Oferta",
            "label.title.viewMarketUnit": "Ver Unidade Física",
            "label.title.installations":"Instalações",
            "label.title.details": "Dados",
            "label.title.viewInstallation": "Ver Instalação",
            "label.title.production": "Produção",
            "label.title.surplus": "Excedente",
            "label.title.viewForecastType": "Ver Tipo de Previsão",
            "label.title.name":"Nome",
            "label.title.required":"Obrigatório?",
            "label.title.value":"Valor",
            "label.title.userProfile":"Dados de Acesso",
            "label.title.fiscalNumber":"NIF",
            "label.title.erpId":"Referência ERP",
            "label.title.default":"Usar por Omissão",
            "label.title.active":"Ativo",
            "label.title.fiscalEntities":"Entidades Fiscais",
            "label.title.notZipCode":"Sem Código Postal",
            "label.section.agentDetails":"Detalhes do Agente",
            "label.section.agentType":"Tipo de Agente",
            "label.section.respPartyDetails":"Detalhes do Responsável",
            "label.title.2FAAuthConfig.step1":"Autenticação de 2 Fatores",
            "label.text.2FAAuthConfig.intro":"Para garantir a segurança da sua conta, a Indie Energy disponibiliza a autenticação de 2 fatores.",
            "label.text.2FAAuthConfig.intro2":"Por favor siga os passos abaixo para configurar a autenticação de 2 fatores. Esta autenticação será usada, nesta fase, apenas na realização de algumas ações na plataforma.",
            "label.text.2FAAuthConfig.intro3":"Antes de prosseguir, confirme que tem instalado no seu telemóvel um aplicativo como Google Authenticator ou MIcrosoft Authenticator, para geração de códigos de autenticação.",
            "label.title.2FAAuthConfig.step2":"Confirmação de Password",
            "label.text.2FAAuthConfig.enterPassword":"Por favor insira a sua password atual para continuar.",
            "label.title.2FAAuthConfig.step3":"Configuração de Aplicativo de Autenticação",
            "label.title.twoFactorAuth":"Configuração de Autenticação de 2 Fatores",
            "label.text.twoFactorAuth.usage":"Na Indie Energy, a segurança dos seus dados é uma prioridade. Para garantir a segurança da sua conta, a Indie Energy disponibiliza a autenticação de 2 fatores para a realização de ações seguras.",
            "label.title.fiscalAuthorityData":"Dados de Acesso à AT",
            "label.text.fiscalAuthDataNotAccessibleIndie":"Os dados de acesso à Autoridade Tributária não são acessíveis na plataforma Indie Energy por nenhum utilizador. Serão apenas utilizados para efeitos de integração de ficheiros SAF-T com a AT, quando a funcionalidade estiver disponível.",
            "label.title.registrationData":"Dados de Registo",
            "label.title.agentData":"Dados do Agente",
            "label.title.respPartyData":"Dados do Responsável",
            "label.title.communityInvoiceDetail": "Detalhes da Fatura",
            "label.title.documentDate": "Data do Documento",
            "label.title.memberProfile":"Perfil do Membro",
            "label.title.paymentMethods":"Métodos de Pagamento",
            "label.title.contactData":"Dados de Contacto",
            // Labels Buttons
            "label.button.autoInvoicing":"Autofaturação",
            "label.button.salesDocuments":"Faturas de Venda",
            "label.button.newAutoInvoiceAgreement":"Registar Acordo Autofaturação",
            "label.button.newPaymentMethod":"Adicionar Método de Pagamento",
            "label.button.inactivate":"Inativar",
            "label.button.updateManagingEntity":"Alterar Entidade Gestora",
            "label.button.updateStatus":"Alterar Situação",
            "label.button.updateCertificate":"Atualizar Certificado",
            "label.button.configure2FA":"Configurar Dupla Autenticação",
            "label.button.registrationEmail":"Reenviar Email de Registo",
            "label.button.preRegisterAgent":"Pré-Registo de Agente",
            "label.button.downloadExcel":"Download Excel",
            "label.button.cancelOffer":"Anular Oferta",
            "label.button.submitOffer":"Submeter Oferta",
            "label.button.createOffer":"Criar Oferta",
            "label.button.confirm":"Confirmar",
            "label.button.configureMember":"Configurar Membro(s) de Instalação",
            "label.button.continue":"Continuar",
            "label.button.delete":"Apagar",
            "label.button.newReportRequest":"Novo Pedido de Relatório",
            "label.button.actionLog":"Histórico de Ações",
            "label.button.actions":"Outras Ações",
            "label.button.changeSignEndContract":"Alterar Assinatura / Cessação",
            "label.button.closeContract":"Fechar",
            "label.button.reopenContract":"Reabrir",
            "label.button.updateContractTerms":"Alterar Termos",
            "label.button.newPurchaseDocument":"Novo Documento de Compra / Autofatura",
            "label.button.newAutoInvoice":"Nova Autofatura",
            "label.button.saveNewPassword":"Guardar a nova Password",
            "label.button.recoverPassword":"Enviar Email de Recuperação de Password",
            "label.button.recoverRegistration":"Enviar Email de Recuperação de Registo",
            "label.button.agreeTermsAndConditions":"Confirmo que Li e Aceito os Termos e Condições",
            "label.button.processForecasts":"Processar Previsões",
            "label.button.newLocation":"Nova Localização",
            "label.button.process": "Processar",
            "label.button.back": "Voltar",
            "label.button.save": "Guardar",
            "label.button.savePassword":"Guardar Password",
            "label.button.stay": "Ficar",
            "label.button.doNotStay": "Prosseguir",
            "label.button.update": "Atualizar",
            "label.button.upload":"Carregar Documento",
            "label.button.remove": "Remover",
            "label.button.search": "Procurar",
            "label.button.previous": "Antes",
            "label.button.procuration": "Procuração",
            "label.button.next": "Seguinte",
            "label.button.newProductionUnit": "Nova Unidade de Produção",
            "label.button.newLine": "Nova Linha",
            "label.button.newEntity": "Nova Entidade",
            "label.button.newMember": "Novo Membro",
            "label.button.newProducer": "Novo Produtor",
            "label.button.newContract": "Novo Contrato",
            "label.button.approve": "Aprovar",
            "label.button.reprocess": "Reprocessar",
            "label.button.cancel": "Cancelar",
            "label.button.export": "Exportar",
            "label.button.newInvoice": "Novo Documento",
            "label.button.new": "Novo",
            "label.button.signin": "Iniciar Sessão",
            "label.button.register": "Registar",
            "label.button.viewProducer" : "Ver Produtor",
            "label.button.edit" : "Editar",
            "label.button.expCertificate":"Lic. Exploração",
            "label.button.viewDetails":"Ver Detalhes",
            "label.button.validateActivation":"Validar Ativação de Contrato",
            "label.button.contract":"Ver Contrato",
            "label.button.contracts":"Contratos",
            "label.button.download":"Descarregar",
            "label.button.newAggregator":"Novo Agregador",
            "label.button.close":"Fechar",
            "label.button.createNewB021PProcess":"Novo Processo de Registo na REN",
            "label.button.saveInvoicingData":"Atualizar Dados de Faturação",
            "label.button.createPhysicalUnit": "Criar Unidade Física",
            "label.button.createOfferingUnit": "Criar Unidade de Oferta",
            "label.button.copy":"Copiar",
            // Labels Forms
            "label.text.twoFactorAuth.enabled":"A autenticação de 2 fatores está ativa na sua conta.",
            "label.text.twoFactorAuth.notEnabled":"A autenticação de 2 fatores não está ativa na sua conta.",
            "label.text.noProductionDeliveryPointNotRequired":"A instalação selecionada não tem CPE de Produção. Não é necessário preeencher esta informação.",
            "label.text.2FAAuthConfig.scanQR":"Por favor leio o código QR abaixo com o aplicativo de autenticação no seu telemóvel.",
            "label.form.request":"Pedido",
            "label.form.response":"Resposta",
            "label.form.fixedPriceTariff":"Tarifa Fixa (€/MWh)",
            "label.form.contract": "Contrato",
            "label.form.energyFee":"Termo variável",
            "label.form.erpId":"ID ERP",
            "label.form.powerFee":"Termo fixo",
            "label.form.value":"Valor",
            "label.form.fee":"Tarifa",
            "label.form.energyTransaction":"Energia Transacionada",
            "label.form.duration":"Duração (Meses)",
            "label.form.manualInput":"Preencher Manualmente",
            "label.form.managingEntity":"Entidade Gestora",
            "label.form.installationOwner":"Titular da Instalação?",
            "label.form.sameAsConsumer":"Utilizar dados do Membro Consumidor?",
            "label.form.uploadPhoto":"Carregar Foto",
            "label.form.delete":"Apagar",
            "label.form.type":"Tipo",
            "label.form.billingPeriod":"Ciclo de Faturação",
            "label.form.classification":"Classificação",
            "label.form.storagePowerKWh":"Potência de Armazenamento (kWh)",
            "label.form.storageCapacityKWh":"Capacidade de Armazenamento (kWh)",
            "label.form.hasEvcp":"Tem Ponto de Carregamento de VE?",
            "label.form.dgegid":"ID DGEG",
            "label.table.header.dgegID": "ID DGEG",
            "label.table.header.puClassification": "Classificação",
            "label.form.permanentCertificate":"Código Certidão Permanente",
            "label.form.omieReferenceId":"Código OMIE",
            "label.form.omieReferenceId.helper":"Este código é o código atribuido pelo OMIE ao agregador para participação em Mercados. Será utilizado pela plataforma da Indie Energy no processamento de dados relacionados com a participação em mercados.",
            "label.form.ordReferenceId":"Código ORD",
            "label.form.ordReferenceId.helper":"Este código é o código atribuido pela REN ao agregador. Será utilizado pela plataforma da Indie Energy em integraçãoes com o ORD.",
            "label.form.2FACode":"Código de Autenticação",
            "label.form.marketAgentCompanies":"Empresa",
            "label.form.contractPowerFee":"Fee de Potência (€/MW/Dia)",
            "label.form.contracts":"Contratos",
            "label.form.id":"ID",
            "label.form.uploadedBy":"Carregado por",
            "label.form.uploadedDate":"Data de Carregamento",
            "label.form.renewContract":"Renovar Contrato",
            "label.form.code":"Código",
            "label.form.fiscalEntity":"Entidade Fiscal de Faturação",
            "label.form.marketSession":"Programa",
            "label.form.programmeDate":"Data do Programa",
            "label.form.siteid":"Site ID",
            "label.form.maxInstances":"Número Máximo de Instâncias",
            "label.form.processingResult":"Resultado",
            "label.form.singleService": "Ato Único",
            "label.form.autoinvoicing":"Autofaturação",
            "label.form.hasAutoInvoicingAgreement":"Tem Acordo de Autofaturação?",
            "label.form.isAutoInvoicingAgreement":"Autofaturação?",
            "label.title.receivingInformation":"Informação de Recebimentos",
            "label.title.paymentsInformation":"Informação de Pagamentos",
            "label.form.atcud":"ATCUD",
            "label.form.hasOpenActivity":"Atividade Aberta",
            "label.form.hasNegativePrices":"Tem Preços Negativos?",
            "label.form.contractPriceDiscount":"Desconto Comercial (%)",
            "label.form.quarterHour":"Quarto-Horário",
            "label.form.hourly":"Horário",
            "label.form.energy":"Energia",
            "label.form.endDate":"Data Fim",
            "label.form.search":"Pesquisar",
            "label.form.selectValue": "Selecionar um valor da lista.",
            "label.form.sessionNumber":"Número da Sessão",
            "label.form.programmeStartDate":"Data Início Horizonte",
            "label.form.programmeEndDate":"Data Fim Horizonte",
            "label.form.hourlyOption": "Opção Horária",
            "label.form.certifiedPower": "Potência Certificada (kW)",
            "label.form.estAnnualConsumption": "Consumo Anual Estimado",
            "label.form.title":"Título",
            "label.form.tensionLevel": "Nível Tensão",
            "label.form.deliveryPoint": "CPE",
            "label.form.zipCode": "Código Postal",
            "label.form.country": "País",
            "label.form.regulatorReferenceNumber": "Número de registo na DGEG",
            "label.form.registrationNumber":"Nº de Registo",
            "label.form.recordNumber":"Nº de Cadastro",
            "label.form.recordReferenceNumber": "Número de cadastro",
            "label.form.referenceDate":"Data de Referência",
            "label.form.referenceStartDate":"Data Início Período Faturação",
            "label.form.referenceEndDate":"Data Fim Período Faturação",
            "label.form.productionCompanyName": "Denominação do Produtor",
            "label.form.productionDeliveryPoint": "CPE de Produção",
            "label.form.productionType": "Tipo de Produção",
            "label.form.processCode":"Código de Processo",
            "label.form.oldSignDate":"Data de Assinatura Atual",
            "label.form.oldActDate":"Data de Ativação Atual",
            "label.form.newSignDate":"Nova Data de Assinatura",
            "label.form.newActDate":"Nova Data de Ativação",
            "label.form.newEndDate":"Nova Data de Fim",
            "label.form.newTermDate":"Nova Data de Terminação",
            "label.form.omieSolicitude" : "Código Solicitude OMIE",
            "label.form.consumptionDeliveryPoint": "CPE de Consumo",
            "label.form.injectsEnergy": "Injeta Energia na Rede?",
            "label.form.installationType": "Tipo de Instalação",
            "label.form.installedPowerKWp": "Potência Instalada (kWp)",
            "label.form.installedPowerKWh": "Potência Instalada (kW)",
            "label.form.installedPowerKva": "Potência Instalada (kVA)",
            "label.form.primaryRenewableSource": "Fonte Primária Renovável",
            "label.form.technologyType": "Tipo de tecnologia utilizada",
            "label.form.systemNominalPower": "Potência nominal do sistema FV(kWp)",
            "label.form.numberOfPanels": "Número de Painéis",
            "label.form.panelPower": "Potência dos Painéis (Wp)",
            "label.form.panelManufacturer": "Fabricante dos Painéis",
            "label.form.panelModel": "Modelo dos Painéis",
            "label.form.connectedPowerKWh": "Potência de ligação (kW)",
            "label.form.injectionPower": "Potência de injeção na RESP (kW)",
            "label.form.connectedPowerKva": "Potência de ligação (kVA)",
            "label.form.generatorPowerKWh": "Potência do Gerador (kW)",
            "label.form.numberOfInverters": "Número de Inversores",
            "label.form.invertersPower": "Potência dos inversores (kW)",
            "label.form.inverterManufacturer": "Fabricante do(s) inversor(es)",
            "label.form.inverterModel": "Modelo do inversor",
            "label.form.connectedToNetwork": "Instalação ligada à RESP ?",
            "label.form.expStartDate": "Data de entrada em exploração",
            "label.form.surfaceAreaAvailable": "Área da superfície disponível",
            "label.form.surfaceAreaCovered": "Área da superfície coberta pela UPAC",
            "label.form.surfaceType": "Tipo de superfície",
            "label.form.phone": "Número de Telefone",
            "label.form.short.phone": "Telefone",
            "label.form.physicalUnitType": "Tipo de Instalação",
            "label.form.email": "Email",
            "label.form.name": "Nome",
            "label.form.agentName": "Nome do Agente",
            "label.form.contractStatus": "Estado",
            "label.form.agentEntityName": "Nome da Entidade do Agente",
            "label.form.address": "Morada",
            "label.form.respPartyName":"Nome do Responsável",
            "label.form.respPartyEmail":"Email do Responsável",
            "label.form.respPartyPhone":"Telefone do Responsável",
            "label.form.aggregator": "Agregador",
            "label.form.city": "Cidade",
            "label.form.fiscalNumber": "Número Fiscal",
            "label.form.NIF": "NIF",
            "label.form.isClient": "É Cliente?",
            "label.form.isAgent": "É Agente?",
            "label.form.isManagingEntity": "É EGAC?",
            "label.form.isSupplier": "É Fornecedor?",
            "label.form.erpSupplierID": "ID Fornecedor ERP",
            "label.form.erpCompanyID": "ID Empresa ERP",
            "label.form.erpClientID": "ID Cliente ERP",
            "label.form.community": "Comunidade",
            "label.form.companyName": "Nome / Denominação Social",
            "label.form.status": "Situação",
            "label.form.add": "Criar",
            "label.form.active": "Ativo",
            "label.form.startDate": "Data de Início",
            "label.form.initialCommunityBuyingPrice": "Preço Inicial de Compra à Comunidade",
            "label.form.initialNetworkBuyingPrice": "Preço Inicial de Compra à Rede",
            "label.form.initialSellingPrice": "Preço Inicial de Venda",
            "label.form.billingPeriod": "Ciclo de Faturação",
            "label.form.invoiceNumber": "# Fatura",
            "label.form.orderDate": "Data da Fatura",
            "label.form.billTo": "Fatura para",
            "label.form.billFrom": "Fatura de",
            "label.form.itemName": "Artigo",
            "label.form.itemQuantity": "Quantidade",
            "label.form.unitPrice": "Preço Unitário",
            "label.form.useProducerData": "Usar Dados de Produtor",
            "label.form.price": "Preço",
            "label.form.activeEnergy": "Energia Ativa",
            "label.form.amount": "Valor",
            "label.form.total": "Total",
            "label.form.nextInvoiceDate": "Data da Próxima Fatura",
            "label.form.currencyCode": "Moeda",
            "label.form.power": "Potência Instalada",
            "label.form.storage": "Armazenamento",
            "label.form.prosumers": "Produtores",
            "label.form.consumers": "Consumidores",
            "label.form.findCommunity": "Procurar Comunidade",
            "label.form.invoiceIBAN": "IBAN Faturação",
            "label.form.paymentIBAN": "IBAN Recebimentos",
            "label.form.paymentMethod": "Método de Pagamento",
            "label.form.paymentTerms": "Condições de pagamento",
            "label.form.receiptMethod": "Método de Recebimento",
            "label.form.receiptTerms": "Termos de Recebimento",
            "label.form.cae": "CAE",
            "label.form.username": "Utilizador",
            "label.form.password": "Palavra-Passe",
            "label.form.rememberMe": "Guardar Acesso",
            "label.form.forgotPassword": "Não sabe a Palavra-Passe? Peça uma nova aqui",
            "label.form.eInvoiceEmail": "Email Fatura Eletrónica",
            "label.form.upload": "Carregar Documentos",
            "label.form.networkPrice": "Energia na RESP",
            "label.form.invoiceNetworkCosts": "Faturar Custos de Acesso às Redes",
            "label.form.selfConsPrice": "Autoconsumo",
            "label.form.sellingPrice": "Venda Excedente",
            "label.form.locality": "Localidade",
            "label.form.district": "Distrito",
            "label.form.longitude":"Longitude (°)",
            "label.form.latitude":"Latitude (°)",
            "label.form.description": "Descrição",
            "label.form.documentType": "Tipo de Documento",
            "label.form.documentNumber": "Código Documento",
            "label.form.doorNumber": "Porta",
            "label.form.apartmentNumber": "Apartamento",
            "label.form.stay": "Ficar",
            "label.form.goToProducer": "Ir Para Produtor",
            "label.form.gcsCode": "Código da Unidade Física",
            "label.form.product": "Produto",
            "label.form.planType": "Tipo de Plano",
            "label.form.plan": "Plano",
            "label.form.planCommission" : "Tarifa de Gestão (€/MWh)",
            "label.form.fixPlanPrice": "Preço (€/MWh)",
            "label.form.indexPlanPrcntg": "Tarifa de Gestão (%)",
            "label.form.signatureDate": "Data de Assinatura",
            "label.form.isAutoTermination": "Cessação de contrato na data de fim de contrato?",
            "label.form.contractTermntLeadDays" : "Rescisão Contratual (Dias)",
            "label.form.contractDuration" : "Duração do Contrato (Meses)",
            "label.form.contractTermntDate" : "Data de Denúncia do Contrato",
            "label.form.contractActDate" : "Data de Ativação do Contrato",
            "label.form.contractEndDate": "Data de Fim do Contrato",
            "label.form.freeTaxes": "Isento de IVA",
            "label.form.clearedFinances": "Finanças Regularizadas",
            "label.form.leadDays": "Dias",
            "label.form.internalSalesAgent": "Nome do Agente Comercial Interno",
            "label.form.externalPartnerName" : "Empresa Parceira",
            "label.form.producer": "Produtor",
            "label.form.activationDate": "Data de Ativação",
            "label.form.meter": "Meter",
            "label.form.marketAgent": "Agente de Mercado",
            "label.form.marketOfferingUnit": "Unidade de Oferta",
            "label.form.isAggregation": "É Agregação?",
            "label.form.daily":"Diário",
            "label.form.dateFrom":"De",
            "label.form.dateTo":"A",
            "label.form.weekly":"Semanal",
            "label.form.monthly":"Mensal",
            "label.form.yearly":"Anual",
            "label.form.viewBy":"Ver Por",
            "label.form.yes":"Sim",
            "label.form.no":"Não",
            "label.form.all":"Todos",
            "label.form.county":"Concelho",
            "label.form.parish":"Freguesia",
            "label.form.newPassword":"Nova Palavra Passe",
            "label.form.repeatNewPassword":"Repetir Palavra Passe",
            "label.form.forecastTypeInstance":"Previsão",
            "label.form.taxRegime":"Regime Fiscal",
            "label.form.communityType":"Tipo de Comunidade",
            "label.form.sharingType":"Modo de Partilha",
            "label.form.uploadDocuments": "Carregar Documentos",
            "label.form.contactSameAsProducer":"Dados de Contacto do Produtor?",
            "label.form.receiptSameAsProducer":"Dados de Recebimento do Produtor?",
            "label.form.physicalUnitAddressSameAsProducer":"Morada da Instalação igual à do Produtor?",
            "label.form.tariffModelType":"Tipo de Modelo Tarifário",
            "label.form.businessType":"Tipo de Negócio",
            "label.form.autoRenew": "Renovação Automática",
            "label.form.backofficeEmail": "Email Financeiro",
            "label.form.supportEmail": "Email de Contacto",
            // uom
            "label.uom.kw":"kW",
            "label.uom.mw":"MW",
            "label.uom.kwh":"kWh",
            "label.uom.mwh":"MWh",
            // Labels Side Menus
            "label.menu.inactivate":"Inativar",
            "label.menu.delete":"Remover",
            "label.menu.copy":"Copiar",
            "label.menu.tariffsPlans":"Planos Tarifários",
            "label.menu.clients":"Clientes",
            "label.menu.powertrading":"Agregação",
            "label.menu.communities":"Comunidades",
            "label.menu.energy":"Energia",
            "label.menu.tariffModels":"Modelos",
            "label.menu.entities":"Entidades",
            "label.menu.fiscalAuthority":"Autoridade Tributária",
            "label.menu.userSettings":"Definições de Utilizador",
            "label.menu.energyAgent":"Agente",
            "label.menu.sendRegistrationEmail":"Enviar Email de Registo",
            "label.menu.energyAgents":"Agentes",
            "label.menu.settlements":"Liquidações",
            "label.menu.dataUpload":"Carregamento de Dados",
            "label.menu.userDetails":"Perfil de Utilizador",
            "label.menu.invoicingDetails":"Informação de Recebimentos",
            "label.menu.voidDocument":"Anular Documento",
            "label.menu.newDocument":"Novo Documento Financeiro",
            "label.menu.marketAgent":"Agente de Mercado",
            "label.menu.viewContract":"Ver Contrato",
            "label.menu.clientArea":"Contratos",
            "label.menu.energyMarkets":"Mercados",
            "label.menu.dailyMarkets":"Mercado Diário",
            "label.menu.intraDayMarkets":"Mercado Intra-Diário",
            "label.menu.integrations":"Integrações",
            "label.menu.attributes":"Atributos",
            "label.menu.powertradingDocuments":"Faturação",
            "label.menu.locations":"Localizações",
            "label.menu.viewClient":"Ver Cliente",
            "label.menu.invoicingClients":"Clientes",
            "label.menu.details": "Detalhes",
            "label.menu.addressInfo": "Detalhes da Morada",
            "label.menu.addCommunity": "Nova Comunidade",
            "label.menu.aggregator": "Agregador",
            "label.menu.techInformation": "Informação Técnica",
            "label.menu.productionUnitDetails": "Detalhes",
            "label.menu.registrationInfo": "Dados de Registo",
            "label.menu.solarPanels": "Painéis Solares",
            "label.menu.inverters": "Inversores",
            "label.menu.identity": "Identificação",
            "label.menu.others": "Outros",
            "label.menu.profile": "Perfil",
            "label.menu.additional": "Outros",
            "label.menu.consumptionInstallation": "Instalação de Consumo",
            "label.menu.viewCommunity" : "Ver Comunidade",
            "label.menu.productionUnit": "Unidade de Produção",
            "label.menu.storageUnit": "Unidade de Armazenamento",
            "label.menu.invoicing": "Faturação",
            "label.menu.production": "Produção",
            "label.menu.consumption": "Consumo",
            "label.menu.sales": "Faturação",
            "label.menu.documents": "Documentos",
            "label.menu.members": "Membros",
            "label.menu.invoices": "Faturas",
            "label.menu.kpis": "Indicadores",
            "label.menu.payments": "Pagamentos",
            "label.menu.paymentsAndReceipts": "Pagamentos e Recebimentos",
            "label.menu.networkAccess": "Acesso à Rede",
            "label.menu.surplus": "Excedente",
            "label.menu.dashboards":"Indicadores",
            "label.menu.management":"Gestão",
            "label.menu.marketAgents":"Agentes de Mercado",
            "label.menu.marketUnits":"Unidades Físicas",
            "label.menu.powerManagement":"Gestão de Energia",
            "label.menu.forecastTypes":"Tipos de Previsão",
            "label.menu.markets":"Mercados",
            "label.menu.home":"Início",
            "label.menu.purchasing":"Compra de Energia",
            "label.menu.memberArea":"Área de Membro",
            "label.menu.promotorArea":"Área do Promotor",
            "label.menu.egacArea":"Área da EGAC",
            "label.menu.myCommunities":"Minhas Comunidades",
            "label.menu.myData":"Conta",
            "label.menu.myInvoices":"Minhas Faturas",
            "label.menu.myDocuments":"Meus Documentos",
            "label.menu.energyPrices":"Preços de Energia",
            "label.menu.energySales":"Venda de Energia",
            "label.menu.energyPurchases":"Compra de Energia",
            "label.menu.salesInvoices":"Venda de Energia",
            "label.menu.purchasesInvoices":"Compra de Energia",
            "label.menu.invoicingInformation":"Dados para Rec. / Pagamentos",
            "label.menu.egac":"Entidade Gestora (EGAC)",
            "label.menu.community":"Comunidade",
            "label.menu.receiptsPayments": "Recebimentos e Pagamentos",
            "label.menu.balance":"Conta Corrente",
            "label.menu.aggregation":"Agregação",
            "label.menu.aggregators":"Agregadores",
            "label.menu.producers":"Produtores",
            "label.menu.contracts":"Contratos",
            "label.menu.physicalUnits":"Instalações",
            "label.menu.invoicingClient":"Clientes",
            "label.menu.agents":"Agentes Comerciais",
            "label.menu.contact":"Contacto",
            "label.menu.products":"Produtos e Serviços",
            "label.menu.physicalunit":"Instalação",
            "label.menu.receipts":"Recebimentos",
            "label.menu.agent":"Agente Comercial",
            "label.menu.saftFiles":"Ficheiros SAF-T",
            "label.menu.communications":"Comunicações",
            "label.menu.installationRegistration":"Registo da Instalação",
            "label.menu.viewTable":"Ver Tabela",
            "label.menu.viewChart":"Ver Gráfico",
            "label.menu.termsAndConditions":"Termos e Condições",
            "label.menu.communitiesInvoicing": "Faturação de Comunidades",
            "label.menu.addCommunityInvoice": "Importar dados de Faturação",
            "label.menu.networkUse": "Uso de Redes",
            "label.menu.networkUseInvoices": "Importação de faturas do uso de redes",
            "label.menu.communitiesTariffPlans": "Planos Tarifários de Comunidades",
            "label.menu.addCommunityTariffPlan": "Novo Plano Tarifário",
            // Page Titles
            "label.page.producerContracts":"Contratos do Produtor",
            "label.page.newContract":"Novo Contrato",
            "label.page.forecastTypes":"Tipos de Previsão",
            "label.page.viewForecastType":"Ver Tipo de Previsão",
            "label.page.addForecastTypeInstance":"Nova Previsão",
            // Labels Table Headers
            "lable.table.footer.rowsPerPage": "Linhas por Página",
            "lable.table.footer.of":"de",
            "label.table.header.relatedDeliverypoint":"Instalação Relacionada",
            "label.table.header.relatedType":"Tipo de Membro",
            "label.table.header.consumptionDeliverypoint":"CPE de Consumo",
            "label.table.header.connectionPower": "Potência de Ligação (kW)",
            "label.table.header.productionDeliverypoint":"CPE de Produção",
            "label.table.header.zipCode":"Código Postal",
            "label.table.header.locality":"Concelho",
            "label.table.header.agent":"Agente",
            "label.table.header.address":"Morada",
            "label.table.header.atcud":"ATCUD",
            "label.table.header.reportedSerie":"Série Comunicada",
            "label.table.header.autoInvoicing":"Autofaturação",
            "label.table.header.fixPriceValue":"Tarifa (€/MWh)",
            "label.table.header.powerFee":"Taxa de Potência",
            "label.table.header.fiscalRegime":"Regime Fiscal",
            "label.table.header.community":"Comunidade",
            "label.table.header.communityType":"Tipo de Comunidade",
            "label.table.header.communityNumber":"Código da Comunidade",
            "label.table.header.totalMembers":"Número de Membros",
            "label.table.header.totalCertifiedPower":"Potência certificada das IC (kW)",
            "label.table.header.totalProductionInstallations":"Número de UPAC(s)",
            "label.table.header.totalConnectionPower":"Potência de ligação (kW)",
            "label.table.header.totalStoragePower":"Potência de Armazenamento (kW)",
            "label.table.header.totalStorageCapacity":"Capacidade de Armazenamento (kWh)",
            "label.table.header.respName":"Nome do Responsável",
            "label.table.header.registered": "Registado?",
            "label.table.header.hasIndieAccount": "Tem conta na Indie?",
            "label.table.header.phone":"Telefone",
            "label.table.header.email":"Email",
            "label.table.header.period":"Período",
            "label.table.header.reference":"Referência",
            "label.table.header.drop":"DR/OP",
            "label.table.header.receivingDate":"Data de Recebimento",
            "label.table.header.startDate":"Início Horizonte Semanal",
            "label.table.header.endDate":"Fim Horizonte Semanal",
            "label.table.header.energyTransaction":"Transação de Energia",
            "label.table.header.totalDeviationEnergy":"Total Desvio",
            "label.table.header.totalDeviationAmount":"Total Desvio (€)",
            "label.table.header.pdbf":"PDBF",
            "label.table.header.pdvd":"PDVD",
            "label.table.header.planType":"Tipo de Modelo",
            "label.table.header.product":"Produto",
            "label.table.header.plan":"Plano",
            "label.table.header.plannedEnergy":"Posição Final",
            "label.table.header.actualEnergy":"Quantidade Verificada",
            "label.table.header.deviationType":"Desvio",
            "label.table.header.deviationEnergy":"Quantidade Desvio",
            "label.table.header.receiptDate":"Data de Recebimento",
            "label.table.header.horizon":"Horizonte",
            "label.table.header.offerDate":"Data da Oferta",
            "label.table.header.offerCode":"Código Oferta",
            "label.table.header.offerUnit":"Unidade de Oferta",
            "label.table.header.totalOffer":"Total da Oferta (MWh)",
            "label.table.header.programme":"Programa",
            "label.table.header.requestDate":"Data do Pedido",
            "label.table.header.createdBy":"Criado Por",
            "label.table.header.actions":"",
            "label.table.header.referenceStartDate":"Início de Período",
            "label.table.header.referenceEndDate":"Fim de Período",
            "label.table.header.price":"Preço Energia (€/MW)",
            "label.table.header.physicalUnit":"Unidade Física",
            "label.table.header.forecastInstance":"Previsão",
            "label.table.header.regulatorRefID": "# DGEG",
            "label.table.header.companyName": "Empresa",
            "label.table.header.deliveryPoint": "CPE",
            "label.table.header.title": "Título",
            "label.table.header.billingPeriodStart": "Início Período de Faturação",
            "label.table.header.total": "Valor s/ IVA (€)",
            "label.table.header.status": "Estado",
            "label.table.header.step": "Passo",
            "label.table.header.description":"Descrição",
            "label.table.header.message":"Mensagem",
            "label.table.header.fiscalNumber":"NIF",
            "label.table.header.issueDate":"Data de Emissão",
            "label.table.header.cpe":"CPE",
            "label.table.header.invoiceNumber":"Fatura Nº",
            "label.table.header.dueDate":"Data de Vencimento",
            "label.table.header.name":"Nome",
            "label.table.header.createdOn":"Criado em",
            "label.table.header.contractActData":"Data Ativação",
            "label.table.header.contractEndData":"Data Fim",
            "label.table.header.cycle":"Ciclo",
            "label.table.header.documentNumber":"Documento",
            "label.table.header.documentType":"Tipo Documento",
            "label.table.header.details":"Detalhes",
            "label.table.header.month":"Mês",
            "label.table.header.surplus":"Excedente",
            "label.table.header.surplusEuro":"Excedente (€)",
            "label.table.header.corrections":"Correções (€)",
            "label.table.header.pending":"Pendente (€)",
            "label.table.header.paymentDate":"Data de Pagamento",
            "label.table.header.settlementDocument": "Nota de Pagamento",
            "label.table.header.pendingSettlement": "Pendente c/ IVA (€)",
            "label.table.header.omieReference":"Ref. OMIE",
            "label.table.header.orm":"Ref. REN",
            "label.table.header.processCode":"Código de Processo",
            "label.table.header.createDate":"Criado Em",
            "label.table.header.offeringUnit":"Unidade de Oferta",
            "label.table.header.maxPowerMW":"Potência Máxima (MW)",
            "label.table.header.maxSites":"Nº Máximo de Instâncias",
            "label.table.header.totalSites":"Total de Instâncias",
            "label.table.header.installationType":"Tipo de Instalação",
            "label.table.header.installedPowerkWh":"Potência Instalada (kW)",
            "label.table.header.aggregation":"É Agregação?",
            "label.table.rowsPerPage":"Linhas por Página",
            "label.table.header.dateTime":"Data / Hora",
            "label.table.header.marketHour":"Hora de Mercado",
            "label.table.header.marketSession":"Sessão de Mercado",
            "label.table.header.dcop":"DC/OP",
            "label.table.header.totalValue (€)":"Total",
            "label.table.header.magnitude":"Energia (MWh)",
            "label.table.header.date":"Data",
            "label.table.header.sessionName":"Programa",
            "label.table.header.forecastValue":"Previsão (MWh)",
            "label.table.header.programmingValue":"Programação (MWh)",
            "label.table.header.productionValue":"Produção (MWh)",
            "label.table.header.deviationsValue":"Desvios (MWh)",
            "label.table.market.session.MD":"Mercado Diário",
            "label.table.market.session.MI1":"Mercado Intra-Diário: Sessão 1",
            "label.table.market.session.MI2":"Mercado Intra-Diário: Sessão 2",
            "label.table.market.session.MI3":"Mercado Intra-Diário: Sessão 3",
            "label.table.market.session.MI4":"Mercado Intra-Diário: Sessão 4",
            "label.table.market.session.MI5":"Mercado Intra-Diário: Sessão 5",
            "label.table.market.session.MI6":"Mercado Intra-Diário: Sessão 6",
            "label.table.market.programme.pdbf":"PDBF",
            "label.table.market.programme.pdvd":"PDVD",
            "label.table.market.programme.pdbc":"PDBC",
            "label.table.market.programme.phf":"PHF",
            "label.table.market.programme.pibci":"PIBCI",
            "label.table.header.egacName":"EGAC",
            "label.table.header.communityName":"Comunidade",
            "label.table.header.documentDate":"Data do Documento",
            "label.table.header.invoicingStartDate":"Data Inicial",
            "label.table.header.invoicingEndDate":"Data Final",
            "label.table.header.valueExcludingIVA":"Valor (Sem IVA)",
            "label.table.header.selfConsumptionRESP":"Autoconsumo RESP",
            "label.table.header.processId": "ID Fatura",
            "label.table.header.unit": "Unidade",
            "label.table.header.quantity": "Quantidade",
            "label.table.header.invoicePrice": "Preço (€)",
            "label.table.header.iva": "Taxa de IVA (%)",
            // Labels KPIs
            "label.kpi.averageExcessPrice":"Preço Médio dos Desvios por Excesso",
            "label.kpi.averageDefectPrice":"Preço Médio dos Desvios por Defeito",
            "label.kpi.totalExcessQuantity": "Desvios por Excesso de Energia (MWh)",
            "label.kpi.totalDefectQuantity": "Desvios por Defeito de Energia (MWh)",
            "label.kpi.totalExcessSettlement": "Desvios por Excesso de Energia (€)",
            "label.kpi.totalDefectSettlement": "Desvios por Defeito de Energia (€)",
            "label.kpi.totalDeviationEnergy": "Saldo (MWh)",
            "label.kpi.totalDeviationSettlement": "Saldo (€)",
            "label.kpi.total": "Total",
            "label.kpi.salesTotal": "Total",
            "label.kpi.dueInvoices": "Faturas Vencidas",
            "label.kpi.expiredInvoices": "Faturas Expiradas",
            "label.kpi.salesSurplus": "Excedente",
            "label.kpi.salesCommunity": "Comunidade",
            "label.kpi.salesSelfConsumption": "Autoconsumo",
            "label.kpi.costsTotal": "Custos",
            "label.kpi.capacity" : "Potência",
            "label.kpi.internal" : "Rede Interna",
            "label.kpi.externalNetwork" : "Rede Externa",
            "label.kpi.prosumers" : "Produtores",
            "label.kpi.members" : "Membros",
            "label.kpi.storage" : "Armazenamento",
            "label.kpi.sharedEnergy" : "Energia Partilhada",
            "label.kpi.surplus": "Excedente",
            "label.kpi.networkCosts": "Acesso Redes",
            "label.kpi.managementCosts": "Custos de Gestão",
            "label.kpi.selfConsumption":"Autoconsumo",
            "label.kpi.community":"Comunidade",
            "label.kpi.supplier":"Comercializador",
            "label.kpi.totalConsumption":"Consumo Total",
            "label.kpi.totalProduction":"Produção Total",
            // I2 - Produção Total na UPAC
            "label.kpi.i2":"Produção Total",
            // I4 - Energia Partilhada pela IC
            "label.kpi.i4":"Energia Partilhada",
            // I8 - Excedente de Energia na IC
            "label.kpi.i8":"Excedente",
            // I9 - Consumo Fornecido à IC pelo Comercializador
            "label.kpi.i9":"Comercializador",
            // I10 - Autoconsumo na IC
            "label.kpi.i10":"Autoconsumo",
            // I11 - Autoconsumo através da RESP
            "label.kpi.i11":"Comunidade",
            // I13 - Faturação do Autoconsumo na IC
            "label.kpi.i13":"Autoconsumo (€)",
            // I14 - Faturação do Autoconsumo através da RESP na IC
            "label.kpi.i14":"Comunidade (€)",
            // I17 - Faturação de Energia Autoconsumida pela CE
            "label.kpi.i17":"Comunidade (€)",
            // I19 - Faturação do Excedente de Energia na CE
            "label.kpi.i19":"Excedente (€)",
            // I21 - Custos de Acesso à Rede
            "label.kpi.i21":"Custos com Acesso às Redes (€)",
            // I22 - Custos de Gestão da Comunidade
            "label.kpi.i22":"Custos com Gestão da Comunidade (€)",
            // I23 - Pagamento de Energia Vendida
            "label.kpi.i23":"Venda de Energia (€)",
            // I26 - Excedente de Energia na CE partilhada pela IC
            "label.kpi.i26":"Energia Partilhada através da RESP (MWh) Excedente",
            
            // Chart Labels
            "label.chart.networkAccessConsumption":"Custos com Acesso às Redes da Energia Consumida (€)",
            "label.chart.networkAccessShared":"Custos com Acesso às Redes da Energia Partilhada (€)",
            "label.chart.surplus":"Excedente (€)",
            "label.chart.sales":"Compra de Energia (€)",
            "label.chart.payments":"Venda de Energia (€)",
            "label.chart.receipts":"Recebimentos (€)",
            "label.chart.revenueCosts":"Faturação de Energia Partilhada e Custos (€)",
            "label.chart.title.programmingVsForecasts":"Comparação de Previsão e Programação (MWh)",
            "label.chart.title.receipts":"Recebimentos (€)",
            "label.chart.title.production":"Produção (MWh)",
            "label.chart.series.forecasts":"Previsão (MWh)",
            "label.chart.series.programming":"Programação (MWh)",
            "label.chart.series.prices":"Preço (€/MWh)",
            "label.chart.series.production":"Energia Produzia (MWh)",
            "label.chart.series.deviations":"Desvios (MWh)",
            // Navigation Labels
            "label.nav.home": "Início",
            "label.nav.consumptionInstallations": "Instalações de Consumo",
            // Fixed Options
            "label.option.upac": "UPAC",
            "label.option.upacia":"UPAC+IA",
            "label.option.ia": "IA",
            "label.option.ip": "IP",
            // Deviation Types
            "label.deviation.defect":"Defeito",
            "label.deviation.excess":"Excesso",
            "label.drop.payment":"Pagamento",
            "label.drop.receipt":"Recebimento",
            // Document Void Stepper
            "label.step.confirmDocument":"Confirmar Documento a Anular",
            "label.step.warningConfirmation":"Confirme que este é o documento a anular, depois de realizada esta ação não será possível voltar atrás. Será criada uma nota de crédito que anula este documento na totalidade.",
            // Document Create Stepper
            "label.step.confirmDocumentToCreate":"Confirmar Dados Documento",
            "label.step.confirmDocumentDates":"Confirmar Datas do Documento",
            // Registration Form
            "label.step.termsAndConditions":"Termos e Condições",
            "label.step.userRegistration":"Dados de Registo",
            "label.step.userRegistrationComplete":"Registo Completo",
            "label.step.chooseReport":"Escolher o Relatório",
            "label.step.configureReport":"Configurar o Relatório",
            "label.step.confirmReport":"Confirmar o Relatório",
            // Create Autoinvoice Agreement
            "label.step.newAutoinvoiceAgreement.selectAgentEntity":"Selecionar Entidade",
            // New Community Form
            "label.step.newCommunity.communityDataStep":"Identificação da Comunidade",
            "label.step.newCommunity.communityDataStep.subtitle":"Introduza os dados de identificação da comunidade na DGEG.",
            "label.step.newCommunity.communityTypeStep":"Tipo de Comunidade",
            "label.step.newCommunity.communityTypeStep.subtitle":"Vamos começar por definir o tipo de comunidade a criar. Que tipo de comunidade pretende criar?",
            "label.step.newCommunity.communitySharingTypeStep":"Modo de Partilha",
            "label.step.newCommunity.communitySharingTypeStep.subtitle":"As As comunidades podem ter diferentes modos de partilha. Qual o modo de partilha que pretende selecionar?",
            "label.step.newCommunity.communityStatusStep":"Estado da Comunidade",
            "label.step.newCommunity.communityStatusStep.subtitle":"Identifique por favor em que estado se encontra o processo de registo da comunidade.",
            "label.step.newCommunity.communityEGACStep":"Entidade Gestora",
            "label.step.newCommunity.communityEGACStep.subtitle":"Quem será a entidade gestora desta comunidade?",
            "label.step.newCommunity.confirmationStep":"Confirmação",
            "label.step.newCommunity.confirmationStep.subtitle":"Confirme os dados da comunidade a criar.",
            "label.step.newCommunity.respParty":"Responsável",
            "label.step.newCommunity.bannerUploadStep":"Imagem de Perfil",
            "label.step.newCommunity.bannerUpload.subtitle":"Adicione uma imagem de perfil para a comunidade.",
            "label.step.newCommunity.businessModelStep":"Tipo de Modelo de Plano Tarifário",
            "label.step.newCommunity.businessModelStep.subtitle":"Selecione o tipo de modelo de plano tarifário que deverá ser aplicado à energia partilhada na comunidade.",
            "label.step.newCommunity.communityNetworkCostsStep":"Custos de Acesso às Redes",
            "label.step.newCommunity.communityNetworkCostsStep.subtitle":"Indique se os Custos de Acesso às serão faturados separadamente ou se já se encontram incluídos no preço da energia para os consumidores.",
            // New Community Member Form
            "label.step.newCommunityMember.introduction":"Introdução",
            "label.step.newCommunityMember.introduction.subtitle":"Antes de começar, confirme por favor que tem acesso a todos os dados necessários para completar o registo de um novo membro de comunidade.",
            "label.step.newCommunityMember.introduction.text":"<ul><li>Dados de Registo do Cliente na DGEG</li><li>Tipo de Instalação / Tipo de Produção</li><li>Morada da Instalação</li><li>Dados de Licenciamento da Instalação</li></ul>",
            "label.step.newCommunityMember.selectCommunityStep":"Comunidade",
            "label.step.newCommunityMember.selectCommunityStep.subtitle":"Selecione a comunidade à qual pretende adicionar um novo membro.",
            "label.step.newCommunityMember.memberDataStep":"Dados do Membro",
            "label.step.newCommunityMember.memberDataStep.subtitle":"Introduza por favor a informação base relativa ao novo membro da comunidade..",
            "label.step.newCommunityMember.installationClassificationStep":"Classificação da Instalação",
            "label.step.newCommunityMember.installationClassificationStep.subtitle":"Classifique a instalação do novo membro da comunidade.",
            "label.step.newCommunityMember.installationAddressStep":"Morada da Instalação",
            "label.step.newCommunityMember.installationAddressStep.subtitle":"Introduza por favor a morada da instalação do novo membro da comunidade.",
            "label.step.newCommunityMember.installationDetailsStep":"Detalhes da Instalação",
            "label.step.newCommunityMember.installationDetailsStep.subtitle":"Introduza por favor os detalhes da instalação do novo membro da comunidade.",
            "label.step.newCommunityMember.installationDetailsStep.installationData":"Instalação",
            "label.step.newCommunityMember.installationDetailsStep.productionInstallationData":"Produção",
            "label.step.newCommunityMember.installationDetailsStep.storageInstallationData":"Armazenamento",
            "label.step.newCommunityMember.physicalUnitOwnerStep":"Titular da Instalação",
            "label.step.newCommunityMember.physicalUnitOwnerStep.subtitle":"Introduza por favor os dados do titular da instalação do novo membro da comunidade.",
            "label.step.newCommunityMember.installationDetailsStep.licensingDetails":"Licenciamento da Instalação",
            "label.step.newCommunityMember.elctricityMarketDataStep":"Dados de Mercado de Eletricidade",
            "label.step.newCommunityMember.elctricityMarketDataStep.subtitle":"Introduza por favor os dados de mercado de eletricidade do novo membro da comunidade.",
            "label.step.newCommunityMember.confirmationStep":"Confirmação",
            "label.step.newCommunityMember.confirmationStep.subtitle":"Confirme os dados do novo membro da comunidade.",
            "label.step.newCommunityMember.installationTypeStep":"Tipo de Instalação",
            "label.step.newCommunityMember.installationTypeStep.subtitle":"Selecione o tipo de instalação do novo membro da comunidade.",
            // New Community Tariff Plan Form
            "label.step.newCommunityTariffPlan.communityCodeStep":"Comunidade",
            "label.step.newCommunityTariffPlan.communityCodeStep.subtitle":"Selecione a Comunidade onde vai criar o plano tarifário.",
            "label.step.newCommunityTariffPlan.tariffModelStep":"Modelo de Plano Tarifário",
            "label.step.newCommunityTariffPlan.tariffModelStep.subtitle":"Selecione o modelo de plano tarifário pretendido.",
            "label.step.newCommunityTariffPlan.communityMembersStep":"Membros da Comunidade",
            "label.step.newCommunityTariffPlan.communityMembersStep.subtitle":"Para que membros da comunidade pretende associar este plano?",
            "label.step.newCommunityTariffPlan.planConfigStep":"Configuração do Plano",
            "label.step.newCommunityTariffPlan.confirmationStep":"Confirmação",
            "label.step.newCommunityTariffPlan.confirmationStep.subtitle":"Confirme os dados do plano a criar.",
            
            "label.step.selectInstallation":"Instalação",
            "label.step.selectConsumerMember":"Membro Consumidor",
            "label.step.selectProducerMember":"Membro Produtor",
            "label.step.confirm":"Confirmar Dados",
            
            "label.installationType.consumptionInstallation":"Instalação de Consumo (IC)",
            "label.installationType.consumptionInstallationDescription":"Instalação elétrica que pode consumir, armazenar ou produzir energia elétrica para autoconsumo.",
            "label.installationType.productionInstallation":"Instalação de Produção (IPr)",
            "label.installationType.productionInstallationDescription":"Instalação elétrica licenciada para efeitos de produção de energia elétrica.",
            "label.installationType.batteryInstallation":"Instalação de Armazenamento (IA)",
            "label.installationType.batteryInstallationDescription":"Instalação elétrica licenciada para efeitos de armazenamento de energia renovável",

            "label.installationClassification.consumptionInstallationNoProductionWithStorage":"Instalação de Consumo com Armazenamento",
            "label.installationClassification.consumptionInstallationNoProductionWithStorageDescription":"Consome e armazena energia elétrica da rede.",
            "label.installationClassification.consumptionInstallationNoProduction":"Instalação de Consumo",
            "label.installationClassification.consumptionInstallationNoProductionDescription":"Apenas consome energia elétrica da rede",
            "label.installationClassification.consumptionInstallationWithProduction":"Instalação de Consumo com UPAC",
            "label.installationClassification.consumptionInstallationWithProductionDescription":"Consome e produz energia elétrica para autoconsumo.",
            "label.installationClassification.consumptionInstallationWithProductionAndStorage":"Instalação de Consumo com UPAC e Armazenamento",
            "label.installationClassification.consumptionInstallationWithProductionAndStorageDescription":"Consome, armazena e produz energia elétrica para autoconsumo.",
            "label.installationClassification.productionInstallationNoConsumption":"Instalação de Produção",
            "label.installationClassification.productionInstallationNoConsumptionDescription":"Instalação que apenas produz energia.",
            "label.installationClassification.productionInstallation":"Instalação de Produção",
            "label.installationClassification.productionInstallationDescription":"Produz energia elétrica para injeção total na rede.",
            "label.installationClassification.productionInstallationWithStorage":"Instalação de Produção com Armazenamento",
            "label.installationClassification.productionInstallationWithStorageDescription":"Produz e armazena energia elétrica para injeção total na rede.",
            "label.installationClassification.storageInstallation":"Instalação de Armazenamento",
            "label.installationClassification.storageInstallationDescription":"Instalação que armazena energia.",
            "label.form.selectInstallationTypeFirst":"Selecione o Tipo de Instalação",
            "label.form.installationClassification":"Classificação da Instalação",
            "label.form.totalMembers": "Número de Membros",
            // Viewable Secret 
            "label.step.secret.insertCode":"Inserir Código de Autenticação Dois Fatores",
            "label.step.secret.viewData":"Visualizar Informação",
            // Success Messages
            "success.api.delete":"Registo removido com sucesso!",
            "success.api.create": "Registo criado com sucesso!",
            "success.api.save": "Registo atualizado com sucesso!",
            "api.success.save": "Registo atualizado com sucesso!",
            "api.success.sentRegistrationEmail":"Email de registo enviado com sucesso!",
            "success.message.foundProgramme":"Programa já existente foi encontrado.",
            "success.message.programProcessed":"Programa processado e informação atualizada.",
            "success.message.passwordResetEmail":"Foi enviado um email com as instruções para reset de password.",
            "success.message.registrationEmail":"Foi enviado um email com as instruções para registo de utilizador.",
            "success.message.offerCreated":"Oferta criada com sucesso.",
            "success.message.marketSessionInfoLoaded":"Carregada informação de sessões de mercado.",
            "success.message.agentEntityCreated":"Entidade criada com sucesso.",
            "success.message.filesUploaded":"Ficheiros enviados com sucesso.",
            "message.agent.emailSent":"Email de registo enviado com sucesso.",
            // Error Messages
            "error.api.marketSessionLoadingError": "Não foi possível carregar informação de sessões de mercado.",
            "error.pages.notFound":"A página que procura não foi encontrada!",
            "error.forms.latitudeFormat":"A latitude deve ter no máximo 5 casas decimais e ser um valor entre 90 e -90",
            "error.forms.longitudeFormat":"A latitude deve ter no máximo 5 casas decimais e ser um valor entre 180 e -180",
            "error.forms.errors": "Existem erros no formulário. Corrija-os e volte a tentar!",
            "error.forms.mandatory": "Este campo é obrigatório!",
            "error.forms.numeric": "Este campo é numérico!",
            "error.forms.positiveNumber": "Este campo deve ser um número positivo!",
            "error.forms.emailFormat": "Formato de Email inválido!",
            "error.forms.caeFormat": "Formato do CAE inválido!",
            "error.forms.zipCodeFormat": "Formato do Código Postal inválido!",
            "error.api.save": "Ocorreu um erro ao gravar este registo!",
            "error.api.loadingList": "Ocorreu um erro ao carregar a lista!",
            "error.api.generic": "Ocorreu um erro na comunicação com o servidor.",
            "error.forms.contracts.deliveryPointContractExists":"Já existe um contrato ativo para o CPE/Produto selecionados e não é possível ter dois contratos ativos. Corrija o CPE ou o Produto.",
            "error.forms.contracts.minActDate":"Data de Ativação tem de ser posterior à data de assinatura.",
            "error.form.findocs.notProcessed" : "Não foi possível processar os documentos.",
            "error.form.positiveNumber":"Deve ser um número superior a 0",
            "error.forms.installationTypeRequired":"Tipo de Instalação é de preenchimento obrigatório.",
            "error.forms.aggregationTypeRequired":"Indicação de Agregação é de preenchimento obrigatório.",
            "error.forms.datesMandatory":"As datas de Início e fim são de preenchimento obrigatório.",
            "error.forms.maxPeriodTwelveMonths":"O período máximo de pesquisa são 12 meses.",
            "error.forms.endDateSuperiorStartDate":"A Data Fim deve ser superior à Data de Início.",
            "error.forms.maxPeriodOneMonth":"O período máximo de pesquisa é de 1 mês.",
            "error.api.contract.productionDeliveryPointExists":"O CPE de Produção já se encontra associado a outra instalação.",
            "error.api.contract.consumptionDeliveryPointExists":"O CPE de Consumo já se encontra associado a outra instalação.",
            "error.api.mandatoryClosingDate":"Data de Fecho é de preenchimento obrigatório.",
            "error.api.closingDateInFuture":"Data de Fecho deve ser menor ou igual à data atual.",
            "error.forms.marketagent.passwordIdentical":"As passwords têm de ser idênticas.",
            "error.api.contract.physicalUnitAlreadyAtUse":"Unidade Física já se encontra associada a outra Instalação.",
            "error.forms.findocs.noContractInfo":"Sem dados de contrato. A redirecionar para a página de contrato.",
            "error.forms.dateInFuture":"Esta data não pode estar no futuro",
            "error.forms.register.fiscalNumberNotFound":"Dados de Registo Inválidos.",
            "error.forms.fiscalNumberDigits":"NIF tem de ter 9 dígitos.",
            "error.forms.fiscalNumberInvalid":"NIF é inválido.",
            "error.forms.register.tokenFiscalNumberCombinationNotValid":"Autorização de registo expirada ou inválida.",
            "errors.forms.minimumLengthTen":"A password deve ter um minimo de 10 caracteres.",
            "error.forms.invalidIBAN":"IBAN inválido. Verifique pff que inseriu corretamente o IBAN.",
            "error.forms.invalidCPE":"CPE inválido.",
            "error.forms.missingContractID":"ID de Contrato é obrigatório para atualizar o contrato.",
            "errors.forms.passwordComplexity":"A password deve conter pelo menos: uma letra minuscula/maiscula, um digito e um caracter especial (!@#\$%^&*_). ",
            "error.forms.newCommunity.noEGACEntities":"Não existem entidades gestoras disponíveis. Aceda à sua área de Agente e configure entidades gestoras de comunidades.",
            "error.forms.newProducer.noAggregatorEntities":"Não existem entidades agregadoras disponíveis. Aceda à sua área de Agente e configure entidades agregadoras.",
            "error.api.loadingContract":"Erro a carregar os dados do contrato. Atualize a página e tente novamente.",
            "error.forms.onlyOneItemcanBeDefault":"Só pode escolher uma entrada por omissão!",
            "error.forms.mustHaveOneDefault":"Tem de escolher uma entrada por omissão!",
            "error.api.trading.physicalUnitNameExists":"Já existe uma unidade fisíca com o mesmo nome.",
            "error.api.trading.offerAlreadyExists":"Oferta já existente para a Unidade de Oferta selecionada.",
            "error.api.trading.offerTotalValueZero":"Para submeter em mercado, o valor total da oferta não pode ser 0.",
            "error.api.trading.offerSessionAlreadyClosed":"Sessão de mercado já fechada. Não é possível submeter a oferta.",
            "error.api.trading.phfNotAvailable":"Resultados de Programa ainda não estão disponíveis, não é possível criar a oferta.",
            "error.api.fiscalNumberNotFound":"NIF não identificado. Contacte o seu Agente de Mercado.",
            "error.api.registrationProcessNotStarted":"Processo de Registo não concluído para o NIF em causa. Solicite uma nova Chave de Registo.",
            "error.api.userAlreadyRegistered":"Processo de Registo já realizado para este NIF. Avance para o Login ou Pedido de nova Senha",
            "error.api.tokenExpired":"Chave de Registo expirada. Utilize o link para Pedir Email de Registo.",
            "error.api.unexpectedError":"Ocorreu um erro inesperado. Contacte o suporte da Plataforma.",
            "error.api.registrationNotComplete":"Processo de Registo não concluído para o NIF em causa. Solicite uma nova Chave de Registo.",
            "error.api.loginNotAuthorized":"Utilizador não registado ou combinação utilizador/password inválida.",
            "error.api.loginUnknown":"Ocorreu um erro desconhecido ao realizar o início de sessão. Contacte pff o nosso Suporte.",
            "error.api.fiscalNumbernoContracts":"Não foi encontrado nenhum contrato associado ao NIF. Contacte o seu agente de mercado.",
            "error.api.contractHasNoReceiptEntity":"É obrigatório definir uma entidade de recebimentos para o contrato.",
            "error.api.receiptEntityNoTaxRegime":"É obrigatório selecionar um Regime de Impostos para a entidade de recebimentos.",
            "error.api.contractTermsAfterClosingDate":"A data de fecho tem de ser superior ao ínicio dos últimos termos contratuais.",
            "error.api.contractFinancialDocsAfterClosingDate":"A data de fecho tem de ser superior ao último período faturado.",
            "error.api.activationDateBeforeSignDate":"Data de ativação tem de ser superior à data de assinatura.",
            "error.api.cannotUpdateActDateHasRenewal":"Não é possível alterar a data de ativação pois o contrato já foi renovado.",
            "error.api.contractTermsStartBeforeContractStart":"Data de ínicio não pode ser inferior à data de ativação do contrato.",
            "error.api.moreThanOneActiveTermsInPeriod":"Não é possível alterar termos e condições que já foram renovados",
            "error.api.trading.noDeliveryPointsFoundPhysicalUnit":"Não existem pontos de entrega associados à unidade física.",
            "error.api.financialDocumentsAfterClosingDate":"Existem documentos financeiros emitidos após a data de encerramento pretendida.",
            "error.api.termsExistAfterStartDate":"Termos existem após a data selecionada.",
            "error.api.cannotHaveGapBetweenTerms":"Existe um período de tempo sem termos contratuais entre os existentes e os novos. Por favor corrija este erro.",
            "error.api.b021p.missingCae":"É obigatório preencher o CAE do Produtor ou da Unidade Fisíca.",
            "error.api.b021p.missingConnectionPowerInformation":"É obrigatório preencher a informação de potência de ligação.",
            "error.api.b021p.missingInstalledPowerInformation":"É obrigatório preencher a informação de potência instalada.",
            "error.api.b021p.missingProductionType":"É obrigatório preencher o tipo de produção.",
            "error.api.b021p.missingTechnologyType":"É obrigatório preencher o tipo de tecnologia.",
            "error.api.b021p.producerMissingCompleteAddressInformation":"É obrigatório preencher a informação completa de morada do produtor.",
            "error.api.b021p.missingContractActDate":"É obrigatório preencher a data de ativação pretendida para o contrato.",
            "error.api.b021p.missingAggregatorDsoId":"Falta preencher o código de referência do agregador.",
            "error.api.trading.communicationErrorWithOMIE":"Ocorreu um erro na comunicação com o OMIE. Contacte o suporte ou volte a tentar mais tarde.",
            "error.api.trading.noOffersFound":"Não foram encontradas ofertas para a Unidade de Oferta selecionada ou estas não se encontram no estado que permite a pesquisa de programa.",
            "error.api.trading.offerNotLatestVersion":"A oferta selecionada não é a última versão. Por favor atualize a página e tente novamente.",
            "error.forms.newCommunityTariffPlan.noCommunities":"Não existem comunidades disponíveis. Aceda à área de Comunidades de Energia e configure uma nova comunidade.",
            "error.forms.newCommunityTariffPlan.noTariffModels":"Não existem modelos tarifários disponíveis. Aceda à área de Modelos Tarifários e configure um novo modelo.",
            "error.forms.newCommunityTariffPlan.noConsumers": "Não existem membros consumidores. Aceda à área de Membros dentro das Comunidades e crie um novo membro consumidor.",
            "error.forms.newCommunityTariffPlan.noProducers": "Não existem membros produtores. Aceda à área de Membros dentro das Comunidades e crie um novo membro produtor.",
            "error.forms.newCommunityTariffPlan.atLeastOneConsumer": "É necessário selecionar pelo menos 1 consumidor.",
            "error.forms.newCommunityTariffPlan.consumersRequired": "São necessários consumidores.",
            "error.forms.newCommunityTariffPlan.atLeastOneProducer": "É necessário selecionar pelo menos 1 produtor.",
            "error.forms.newCommunityTariffPlan.producersRequired": "São necessários produtores.",
            "error.forms.newCommunityTariffPlan.exactlyOneConsumerOneProducer": "Tem de haver exatamente 1 consumidor e 1 produtor.",
            // Agent Validation Messages
            "error.validation.agentType":"Antes de avançar, selecione pelo menos um tipo de Agente.",
            // Contract Validation Messages
            "error.api.contract.activate.contractNotInPreStatus":"O contrato já não se encontra em estado de Pré-Registo ou Em Ativação.",
            "error.api.contract.activate.activationDateNotReached":"Data de Ativação ainda não foi atingida.",
            "error.api.contract.activate.physicalUnitNotFound":"Unidade Física não encontrada.",
            "error.api.contract.activate.connectionPowerNotFound":"A Potência de Ligação na Unidade Física ainda não foi preenchida.",
            "error.api.contract.activate.connectionGreater1MWAndNoMeterId":"Potência de Ligação superior ou igual a 1MW e sem identificação GGS.",
            "error.api.contract.activate.receiptEntityNotFound":"Cliente de Faturação não encontrado.",
            "error.api.contract.activate.noContractTerms":"Não existem termos contratuais para o contrato.",
            // Member Installation Errors
            "error.api.communities.memberAlreadyInCommunity":"A instalação que se encontra a registar já se encontra associada a outra comunidade.",
            // 2Fa Errors
            "error.api.verificationCodeFailed":"Código de autenticação inválido. Por favor tente novamente.",
            // Axios Errors
            "Request failed with status code 500":"Ocorreu um erro na comunicação com o servidor. Contacte o suporte.",

            // Messages
            "message.forms.noFiles" : "Não foi selecionado nenhum ficheiro!",
            "message.forms.files" : "Ficheiros Selecionados:",
            "message.form.contracts.lodadedDeliveryPoint" : "Informação da Unidade Física carregada automaticamente.",
            "message.form.contracts.loadedProducerData":"Informação carregada a partir do Produtor.",
            "message.form.contracts.entityData":"Entidade já existe, dados carregados a partir da entidade.",
            "message.form.editModeActive": "Modo de Edição ativo",
            "message.form.recordSaved": "Alterações gravadas!",
            "message.form.recordCreated": "Alterações gravadas!",
            "message.form.findocs.processed" : "Documentos Processados e enviados para o ERP",
            "message.table.noData":"Ainda não realizou qualquer consulta ou a consulta realizada não devolveu resultados. Altere os parâmetros de pesquisa e procure novamente.",
            "message.info.noContracts":"Não tem nenhum contrato associado.",
            "message.info.noProgrammeFound":"Não foi encontrado nenhum programa para o período selecionado.",
            "message.info.dataLoaded":"Dados carregados com sucesso.",
            "message.form.login.sucess":"Login Realizado com Sucesso",
            "message.forms.fileSizeExceeded":"Tamanho do ficheiro excede o limite permitido de 10MB.",
            "message.dialog.2FACode":"Por favor insira o seu código de autenticação de dois fatores. Caso não tenha ainda configurado a autenticação de dois fatores, terá de configurar em Conta > Definições de Utilizador.",
            "message.form.xmlfiles":"Apenas ficheiros com a extensão .xml são permitidos.",
            "message.form.250kbfilesize":"O ficheiro deve ter um tamanho inferior a 250KB.",
            // Backend Options
            "Quarterly": "Trimestral",
            "Monthly": "Mensal",
            "Monthly (15th)": "Mensal / Meio do Mês",
            "Monthly (2nd)": "Mensal / Início do Mês",
            "No Billing Period":"Sem Ciclo / Ato Único",
            "Weekly":"Semanal",
            "Yearly":"Anual",
            "Validation": "Em Validação",
            "Approved":"Aprovada",
            "Prospecting":"Construção",
            "Operating":"Em Operação",
            "label.status.member.prerecord":"Pré-Registo",
            "label.status.member.active":"Ativo",
            "label.status.member.registered":"Registado",
            "label.status.member.suspended":"Suspenso",
            "label.status.member.inactive":"Inativo",
            "label.status.receipt.DUE": "Por Pagar",
            "label.status.report.PENDING":"Pendente",
            "label.status.report.COMPLETED":"Concluído",
            "label.status.report.ERROR":"Com Erro",
            "label.status.report.RUNNING":"Em Curso",
            "label.status.offer.UNSENT":"Não Enviada",
            "label.status.offer.SENT":"Enviada",
            "label.status.offer.SUBMITTED":"Submetida",
            "label.status.offer.VOIDED":"Anulada",
            "label.status.offer.REJECTED":"Recusada",
            "label.status.offer.REFUSED":"Recusada",
            "label.status.offer.UPDATED":"Pendente",
            "label.status.offer.PENDING":"Pendente",
            "label.status.offer.ARCHIVED":"Arquivada",
            "label.status.contract.ATI":"Ativo",
            "label.status.contract.PRE":"Pré-Registo",
            "label.status.contract.PEN":"Pendente",
            "label.status.contract.REC":"Recusado",
            "label.status.contract.FEC":"Fechado",
            "label.type.offer.C":"Compra",
            "label.type.offer.V":"Venda",
            "label.status.agent.PREREG":"Pré-Registo",
            "label.status.agent.ACTIVE":"Ativo",
            // Community Status
            "label.community.status.dgegRegistrationgGroup":"Registo na DGEG",
            "label.community.status.prerecord":"Pré-Registo",
            "label.community.status.active":"Ativa",
            "label.community.status.submitted":"Projeto submetido",
            "label.community.status.analyzing":"Em Análise",
            "label.community.status.partialReview":"Pronúncia parcial do ORD",
            "label.community.status.totalReview":"Pronúncia total do ORD",
            "label.community.status.waitingAcceptance":"Aguarda aceitação p/ Cadastro",
            "label.community.status.cadastreAssignment":"Atribuição de Cadastro",
            "label.community.status.certificationDGEG":"Certificação DGEG",
            "label.community.status.certificationORD":"Certificação ORD",
            "label.community.status.suspended":"Partilha Suspensa",
            "label.community.status.certification":"Certificação",
            "label.community.status.registering":"Em Registo na DGEG",
            "label.community.type.acc":"Autoconsumo Coletivo",
            "label.community.type.cer":"Comunidade de Energia Renovável",
            // Invoices Status
            "Paid":"Liquidada",
            "Paid Off":"Liquidada",
            "Pending":"Pendente",
            "Void":"Anulada",
            "Processed":"Processada",
            "Issued":"Emitida",
            "Unpaid":"Por Pagar",
            // Producer Status
            "Pre-Record":"Pré-Registo",
            "Active":"Ativo",
            "Inactive":"Inativo",
            "Refused":"Recusado",
            "Pending Contract":"Pendente",
            "No Contracts":"Sem Contrato",
            "Activating":"Em Ativação",
            // Financial Document Types
            "Auto Invoice":"Autofatura",
            "Purchase Document":"Documento de Compra",
            "Sales Document":"Documento de Venda",
            "Credit Note":"Nota de Crédito",
            // Payment TErms
            "20 Days":"20 Dias",
            "30 Days":"30 Dias",
            "60 Days":"60 Dias",
            // Long Text Messages
            "label.message.producerExists": "Um produtor com o NIF inserido já existe e não é possível criar dois registos de produtor para o mesmo NIF. Deseja inserir outro NIF ou ser redirecionado para a página do produtor existente?",
            "label.message.goBackConfirmation": "Ao voltar atrás irá perder todas as alterações realizadas, este registo não foi gravado!",
            "label.message.recordsProcessedSuccess":"Registos processados com sucesso.",
            "label.message.noActiveAutoInvoiceAgreements":"Não existem acordos de autofaturação ativos para este cliente.",
            "label.message.noActivePaymentMethods":"Não existem métodos de Pagamento ativos para este cliente.",
            "label.contract.action.REOPEN":"Reabertura",
            "label.contract.action.RENEW":"Renovação",
            "label.contract.action.CLOSE":"Cessação",
            "label.contract.action.CREATE":"Criação",
            "label.contract.action.UPDATE":"Atualização",
            "label.contract.action.UPDATE_ACTDATE":"Alterar Assinatura / Cessação Contrato",
            "label.contract.action.ACTION_UPD_TERMS":"Atualização Termos",
            "label.contract.action.SCHED_CLOSE":"Agendamento Cessação",

            "label.message.agent.referenceCodesUpdated":"Códigos de Referência atualizados com sucesso.",
            "label.message.agent.certificateUploaded": "Certificado carregado com sucesso.",
            "label.message.agent.certificatePasswordUpdated": "Password do Certificado atualizada com sucesso.",

            "label.message.dialog.closingIn":"Esta janela irá fechar em {{seconds}} segundos.",
            "message.dialog.valueCopied":"Valor copiado para a área de transferência.",
            "label.message.noData":"Sem dados.",

            "label.dialog.confirmContinueToMemberConfiguration.message":"Parabéns! Concluiu a configuração da instalação na comunidade. Deseja continuar para a configuração do(s) membro(s) associado(s) à instalação?",
            "label.dialog.confirmContinueToTariffPlanConfigurationDialog.message":"Concluiu a configuração dos membros da instalação! Deseja continuar para a configuração do plano tarifário?",
            "label.tariffPlanType.consumer":"Consumidor",
            "label.tariffPlanType.producer":"Produtor",
            "label.tariffPlanType.consumer.description":"Plano tarifário de venda aplicado à energia consumida pelos consumidores, partilhada pela Comunidade ou fornecida diretamente pela UPAC.",
            "label.tariffPlanType.producer.description":"Plano tarifário de compra aplicado à energia produzida pelos produtores, partilhada com a Comunidade ou injetada na rede para transação nos mercados de energia.",
            "label.tariffPlanType.producers_2_consumer":"Todos os Produtores para Consumidor",
            "label.tariffPlanType.producers_2_consumer.description":"Plano tarifário aplicado à energia partilhada entre cada Produtor e cada Consumidor.",
            "label.tariffPlanType.pairs":"Par Consumidor - Produtor",
            "label.tariffPlanType.pairs.description":"Tarifário aplicado a consumidores e produtores, em que o consumidor tem uma tarifa aplicada a um produtor específico da comunidade e em que o produtor recebe o valor indicado ao consumidor, deduzido das taxas a aplicar.",
            "label.step.newTariffModel.tariffPlanType.subtitle":"Selecione o tipo de modelo de plano tarifário.",
            "label.step.newTariffModel.tariffs":"Tarifas",
            "label.step.newTariffModel.duration":"Duração",
            "label.step.newTariffModel.duration.subtitle":"Defina a duração do plano tarifário e o número de dias de antecedência com os quais deverá ser comunicada a rescisão de contrato.",
            "label.step.newTariffModel.tariffType":"Tipo de Plano",
            "label.step.newTariffModel.tariffType.subtitle":"Selecione o tipo de plano tarifário.",
            "label.step.newTariffModel.fees":"Taxas",
            "label.step.newTariffModel.fees.subtitle":"Selecione as taxas de gestão. As taxas de gestão podem ter um termo fixo e um termo variável.",
            "label.step.newTariffModel.paymentsAndReceipts":"Pagamentos e Recebimentos",
            "label.step.newTariffModel.payments":"Pagamentos",
            "label.step.newTariffModel.payments.subtitle":"Esta secção permite configurar os métodos de pagamento, as condições de pagamento e os ciclos de faturação que podem ser disponibilizados ao Cliente.",
            "label.step.newTariffModel.receipts":"Recebimentos",
            "label.step.newTariffModel.receipts.subtitle":"Esta secção permite configurar os métodos de recebimento, as condições de recebimento e os ciclos de faturação que podem ser disponibilizados ao Cliente.",
            "label.step.newTariffModel.paymentsAndReceipts.subtitles":"Defina os valores por omissão para pagamentos e recebimentos.",
            "label.step.newTariffModel.confirmationStep":"Confirmação dos Dados",
            "label.step.newTariffModel.confirmationStep.subtitle":"Confirme os dados do plano tarifário.",

            "label.step.newProducer.agentEntityStep":"Agente",
            "label.step.newCommunity.newProducer.subtitle":"Vamos começar por definir qual a entidade de agregação responsável pela gestão do produtor.",
            "label.step.newProducer.entityData":"Dados do Produtor",
            "label.step.newCommunity.newProducer.entityData":"Pode pesquisar um entidade já existente através do NIF / NIPC ou criar uma nova entidade.",
            "label.step.newProducer.confirmData":"Validar Dados",
            "label.step.newTariffPlan.confirmationStep.subtitle":"Confirme os dados do plano tarifário a criar.",
            "label.product.CVE":"Compra e Venda de Energia",
            "label.product.VE":"Venda de Energia",
            "label.product.CE":"Compra de Energia",
            "label.product.CAR":"Custos de Acesso às Redes",
            "label.product.CGE":"Custos de Gestão da EGAC",
            "label.product.CGA":"Custos de Gestão do Agregador",
            "label.energyTransaction.shared":"Energia Partilhada",
            "label.energyTransaction.surplus":"Energia Excedente",
            "label.energyTransaction.production":"Energia Produzida",
            "label.energyTransaction.selfconsumption":"Autoconsumo na IC da UPAC Integrada",
            "label.energyTransaction.consumption":"Autoconsumo através da RESP + RI",
            "label.energyTransaction.trader":"Consumo fornecido pelo Comercializador",
            "label.energyTransaction.shared_consumed":"Energia partilhada consumida",
            "label.energyTransaction.shared_surplus":"Energia partilhada excedente",
            // New Powertrading Contract Stepper
            "label.newPowerTradingTariff.introductionStep":"Introdução",
            "label.newPowerTradingTariff.agentEntityStep":"Agente",
            "label.newPowerTradingTariff.physicalUnitDataStep":"Dados da Instalação",
            "label.newPowerTradingTariff.physicalUnitAddressStep":"Morada da Instalação",
            "label.newPowerTradingTariff.contactDataStep":"Dados de Contacto",
            "label.newPowerTradingTariff.receiptsStep":"Recebimentos e Faturação",
            "label.newPowerTradingTariff.documentsStep":"Documentos",
            "label.newPowerTradingTariff.confirmationStep":"Validar Dados",
            "label.form.renRegistration":"Dados de Registo na REN",
            "label.form.geoCoding":"Geo Localização",

            "label.dialog.confirmContinueToInstallationConfigurationDialog.message":"Parabéns! Concluiu a configuração da comunidade. Deseja continuar para a configuração de uma instalação para esta comunidade?",


            "label.communities.tariffModel.title.independent":"Tarifários Independentes",
            "label.communities.tariffModel.description.independent":"este modelo permite aplicar planos tarifários independentes à energia partilhada com cada consumidores e à energia partilhada por cada produtor.",

            "label.communities.tariffModel.title.pairs":"Pares Consumidor - Produtor",
            "label.communities.tariffModel.description.pairs":"este modelo permite aplicar um plano tarifário à energia partilhada entre cada produtor e cada consumidor.",

            "label.communities.tariffModel.title.producers_2_consumer":"Todos os Produtores para um Consumidor",
            "label.communities.tariffModel.description.producers_2_consumer":"Neste modelo todos os produtores aplicam o mesmo plano tarifário à energia partilhada com um determinado consumidor.",
        
            "label.businessType.aggregation":"Agregação",
            "label.businessType.communities":"Comunidades",

            "label.step.newPaymentMethod.selectAgentEntity":"Selecionar Agente",
            "label.step.newPaymentMethod.selectPaymentMethod":"Selecionar Método de Pagamento",

            "label.title.configureInstallation":"Configurar Instalação",
            "api.error.alreadyHasTariffPlan":"Já existe um plano tarifário ativo para esta instalação e para a transação de energia selecionada.",
            "api.error.datesGreaterThanToday":"Por favor selecione uma data igual ou inferior à data atual.",
            "api.error.physicalUnitNotFound":"Não foi possível encontrar a instalação selecionada.",

            "label.title.energyProduction":"Produção de Energia",
            "label.title.energyConsumption":"Consumo de Energia",
            "label.title.sharedEnergy":"Energia Partilhada",
            "label.title.sharedEnergyConsumed":"Energia Partilhada Consumida",
            "label.title.sharedEnergySurplus":"Energia Partilhada Excedente",
            "label.title.selfConsumptionResp":"Autoconsumo através da RESP",
            "label.title.selfConsumptionRi":"Autoconsumo através da RI",
            "label.title.consumptionByCommercializer":"Consumo fornecido pelo Comercializador",
            "label.title.totalConsumption":"Consumo da Rede",
            "label.title.networkConsumption":"Autoconsumo através da Rede",
            "label.title.totalProduction": "Produção Total",
            "label.title.totalNetworkInjection": "Total Injetado na Rede",
            "label.title.surplusEnergy": "Energia Excedente",
            "label.title.UPACConsumptionEnergy": "Autoconsumo da UPAC Integrada",

            "label.relatedType.P":"Produtor",
            "label.relatedType.C":"Consumidor",
            
            "api.error.activeEnergyPurchasePlan":"Já existe um plano tarifário ativo de compra de energia para esta instalação.",
            "api.error.activeEnergySalePlan":"Já existe um plano tarifário ativo de venda de energia para esta instalação.",
            "api.error.noConsumptionData": "Não existem dados para o período indicado ou não tem acesso.",
            "api.info.noProductionData": "Não existem dados de produção para o período indicado ou não tem acesso.",
            "label.title.renSFTPConfiguration":"Configuração Ligação SFTP REN",
            "label.title.eredesSFTPConfiguration":"Configuração Ligação SFTP E-Redes",
            "label.form.host":"Host",
            "label.form.port":"Porta",
            "label.form.sourcePath":"Pasta de Origem",
            "label.form.destinationPath":"Pasta de Destino",

            "label.message.agent.renSftpConfigurationUpdated":"Configuração SFTP REN atualizada com sucesso.",
            "label.message.agent.eredesSftpConfigurationUpdated":"Configuração SFTP E-Redes atualizada com sucesso.",
            "label.newPowerTradingTariff.productsServicesStep":"Produtos e Serviços",

            "label.form.autoinvoicingAgreements":"Acordo prévio de Autofaturação",
            "label.table.header.useAutoInvoicing":"Regime de Autofaturação?",
            "label.table.header.hasValidationCode":"Séries Comunicadas?",

            "label.tab.approval":"Aprovação",
            "label.tab.pending":"Pendentes",
            "label.tab.processed":"Processadas",
            "label.tab.issued":"Emitidas",
            "label.tab.void":"Anuladas",
            "label.tab.settled":"Liquidadas",
            "label.title.selectAggregator":"Selecionar Agregador",
            "label.title.editAutoInvoiceAgreement":"Alterar Acordo de Autofaturação",
            "label.title.marketSessionConfiguration":"Configuração da Sessão de Mercado",

            "api.error.agentEntityDoesNotHaveCertificate":"A entidade selecionada não tem um certificado digital do OMIE associado. Por favor, carregue um certificado digital para esta entidade na sua conta.",
            "error.api.trading.cantSendRejectedOffer":"Não é possível enviar uma oferta recusada. Edite a oferta para corrigir os erros antes de enviar.",
            "api.error.errorFetchingOpenMarketSession":"Não foi possível confirmar se existe uma sessão de mercado aberta. Por favor, tente novamente mais tarde.",
            "error.api.trading.voidOfferNotSuccessful":"Não foi possível anular a oferta. Por favor, tente novamente mais tarde.",
            "error.api.trading.offerAlreadySent":"A oferta já foi submetida para o mercado. Para enviar novamente crie uma nova versão alterando os dados da oferta.",
            "api.error.noOmieReferenceId":"O agente de mercado selecionado não tem o ID de referência do OMIE preenchido. Não é possível completar a ação.",

            // Physical Unit Classifications
            "label.physicalUnitClassification.ip":"IP",
            "label.physicalUnitClassification.ic":"IC",
            "label.physicalUnitClassification.ic_prod":"IC / Produção",
            "label.physicalUnitClassification.ic_prod_strg":"IC / Produção / Armazenamento",
            "label.physicalUnitClassification.ic_strg":"IC / Armazenamento",
            "label.physicalUnitClassification.ip_strg":"IP / Armazenamento",
            "label.physicalUnitClassification.ia":"Instalação de  Armazenamento",

            "error.api.trading.notLatestVersionOffer":"A oferta selecionada não é a última versão. Por favor, atualize a página e tente novamente.",
            "label.title.forecastProgramme": "Programação",
        }
    }
};
i18next.use(initReactI18next).init({
    resources,
    lng: "pt",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});
