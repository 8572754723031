import { Close, LocationCityOutlined } from "@mui/icons-material";
import { Alert, Box, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid2, IconButton, Skeleton, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import IndieBackButton from "components/forms/IndieBackButton";
import IndieContinueButton from "components/forms/IndieContinueButton";
import IndieDesktopDatePicker from "components/forms/IndieDesktopDatePickerV2";
import IndieSaveButton from "components/forms/IndieSaveButton";
import IndieStaticSelect from "components/forms/IndieStaticSelect";
import IndieTextField from "components/forms/IndieTextField";
import ValueListWithSelectCard from "components/forms/ValueListWithSelectCard";
import SelectYesNo from "components/SelectYesNo";
import { DRF_DATE_FORMAT } from "constants";
import { AgentEntityContextProvider } from "contexts/agents/AgentEntityContext";
import { CommunityContext, CommunityContextProvider } from "contexts/communities/CommunityContext";
import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import { Form, Formik, useField, useFormikContext } from "formik";
import { t } from "i18next";
import _ from "lodash";
import moment from "moment";
import { IndieSimpleTable } from "page-sections/common/IndieSimpleTable";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAgentEntitiesAPIService } from "service/AgentEntitiesAPIService";
import { useCommunitiesAPI } from "service/CommunitiesAPIService";
import * as Yup from "yup";
import CommunityStatusSelect, { CommunityStatusList } from "../../forms/communities/CommunityStatusSelect";
import CommunityTypeSelector, { communityTypes } from "../../forms/communities/CommunityTypeSelector";
import CommunityBannerUpload from "./CommunityBannerUpload";

export const communitySharingTypes = [
    {
        code: "proportional",
        name: "Proporcional",
        description: "Partilha com coeficientes proporcionais ao consumo",
        disabled: false
    },
    {
        code: "fixed",
        name: "Fixo",
        description: "Partilha com coeficientes fixos",
        disabled: true
    },
    {
        code: "dynamic",
        name: "Dinâmico",
        description: "Partilha com coeficientes dinâmicos",
        disabled: true
    }
];

export const CommunitySharingTypeSelect = (props) => {

    const { t } = useTranslation();

    return (
        <IndieStaticSelect
            options={communitySharingTypes.map((st) => ({ value: st.code, label: st.name }))}
            label={t("label.form.sharingType")}
            sx={{ width: 250 }}
            {...props}
        />
    )
}
export const IndieLabelAndValue = ({ label, value }) => {
    const { t } = useTranslation();

    return (
        <Stack direction={"row"} spacing={2}>
            <Typography variant='subtitle2' color='textSecondary'>{t(label)}:</Typography>
            <Typography variant='body2' color='textSecondary'>{value} </Typography>
        </Stack>
    );
}

export const IndieDSOIDField = ({ label, type, ...props }) => {
    const [field, meta, helpers] = useField(props);

    return (
        <FormControl>
            <Stack direction={"row"} spacing={2} sx={{ alignItems: "flex-end" }}>
                <TextField
                    {...field}
                    {...props}
                    label={label}
                    type="number"
                    value={field.value ? field.value : (props.value || "")}
                    onChange={(e) => {
                        if (props.onChange) {
                            helpers.setValue(e.target.value);
                            props.onChange(e);
                        } else {
                            helpers.setValue(e.target.value);
                        }
                    }}
                    error={meta.touched && meta.error ? true : false}
                    variant="standard"
                    sx={{ width: 100 }}
                />
                <Typography variant="body1" color="textSecondary">/ {_.upperCase(type)}</Typography>
            </Stack>
            {meta.touched && meta.error ? (
                <FormHelperText id={`form_error_text_${props.name}`} error sx={{ ml: 0, pl: 0 }}>{meta.error}</FormHelperText>
            ) : null}
        </FormControl>
    );
}


const NewCommunityDialog = ({ open, handleClose, handleSuccess }) => {
    return (
        <AgentEntityContextProvider>
            <CommunityContextProvider>
                <NewCommunityDialogContent open={open} handleClose={handleClose} handleSuccess={handleSuccess} />
            </CommunityContextProvider>
        </AgentEntityContextProvider>
    )
};

const NewCommunityDialogContent = ({ open, handleClose, handleSuccess }) => {

    const { setAndShowErrorMessage, setAndShowSuccessMessage } = useContext(UserFeedbackContext);
    const agentEntitiesAPI = useAgentEntitiesAPIService();
    const communitiesAPI = useCommunitiesAPI();

    const [entities, setEntities] = useState([]);
    const [loadingEntities, setLoadingEntities] = useState(true);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [tariffModels, setTariffModels] = useState([]);

    const { getCommunitiesTariffModels } = useContext(CommunityContext);


    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const columns = useMemo(() => [
        { field: "entity.name", label: t("label.table.header.companyName"), align: "left" },
        { field: "entity.fiscal_number", label: t("label.table.header.fiscalNumber"), align: "left" },
        { field: "resp_party.name", label: t("label.table.header.respName"), align: "left" },
    ], []);

    const initialValues = {
        name: "",
        dso_id: "",
        activation_date: null,
        type: null,
        sharing_type: null,
        status: null,
        managing_entity: null,
        banner_image: null
    };

    const step1ValidationSchema = Yup.object().shape({
        type: Yup.string().required(t("error.forms.mandatory")),
    });

    const step2ValidationSchema = Yup.object().shape({
        sharing_type: Yup.string().required(t("error.forms.mandatory")),
    });

    const step3ValidationSchema = Yup.object().shape({
        tariff_model: Yup.string().required(t("error.forms.mandatory")),
    });

    const step4ValidationSchema = Yup.object().shape({
        invoice_network_costs: Yup.string().required(t("error.forms.mandatory")),
    });

    const step5ValidationSchema = Yup.object().shape({
        name: Yup.string().required(t("error.forms.mandatory")),
        dso_id: Yup.number().required(t("error.forms.mandatory")),
        activation_date: Yup.date().required(t("error.forms.mandatory")),
    });

    const step6ValidationSchema = Yup.object().shape({
        status: Yup.string().required(t("error.forms.mandatory")),
    });

    const step7ValidationSchema = Yup.object().shape({
        managing_entity: Yup.object().required(t("error.forms.mandatory")),
    });


    const validationSchemas = [
        step1ValidationSchema,
        step2ValidationSchema,
        step3ValidationSchema,
        step4ValidationSchema,
        step5ValidationSchema,
        step6ValidationSchema,
        step7ValidationSchema
    ];

    const fetchEntities = () => {
        setLoadingEntities(true);
        agentEntitiesAPI.fetch([{ id: "agent_type", value: "egac" }], 100, 1, false, (response) => {
            setEntities(response.data.results);
            setLoadingEntities(false);
        }, (error) => {
            console.error(error);
            setLoadingEntities(false);
        });
    }

    const fetchTariffModels = () => {
        getCommunitiesTariffModels().then((response) => {
            setTariffModels(response.data);
        }).catch((error) => {
            console.error(error);
            setTariffModels([]);
        });
    }

    const handleSubmit = (values) => {
        if (activeStep < 8) {
            handleNext();
        } else {
            const _values = _.cloneDeep(values);
            _values['activation_date'] = moment(_values['activation_date']).format(DRF_DATE_FORMAT);
            setSubmitting(true);
            communitiesAPI.save(_values, (response) => {
                setAndShowSuccessMessage(t("success.api.create"));
                setSubmitting(false);
                setActiveStep(0);
                if (handleSuccess) {
                    handleSuccess(response.data);
                }
            }, (error) => {
                setSubmitting(false);
                setAndShowErrorMessage(error);
            });
        }
    };

    const handleRowClick = (row, formik) => {
        setSelectedEntity(row);
        formik.setFieldValue("managing_entity", row);

    };

    useEffect(() => {
        fetchEntities();
        fetchTariffModels();

        return () => {
            setEntities([]);
        }
    }, []);

    useEffect(() => {
        setActiveStep(0);
    }, [open]);

    if (!entities || !tariffModels) {
        return (

            <Dialog open={open} fullScreen>
                <DialogTitle>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                        <LocationCityOutlined color="primary" />
                        <Typography variant="subtitle1" color="textPrimary">{t("label.menu.addCommunity")}</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Skeleton variant="rectangular" width="100%" height={400} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog open={open} fullScreen>
            <DialogTitle>
                <Stack spacing={2} direction="row" alignItems="center">
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                    <LocationCityOutlined color="primary" />
                    <Typography variant="subtitle1" color="textPrimary">{t("label.menu.addCommunity")}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Divider fullWidth sx={{ mb: 2 }} />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemas[activeStep]}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validateOnMount={false}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step key={0}>
                                    <StepLabel>{t("label.step.newCommunity.communityTypeStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={2}>
                                            <Typography variant="subtitle2">{t("label.step.newCommunity.communityTypeStep.subtitle")}</Typography>
                                            <CommunityTypeSelector name="type" />
                                            <Stack direction="row" spacing={2}>
                                                <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={1}>
                                    <StepLabel>{t("label.step.newCommunity.communitySharingTypeStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={2}>
                                            <Typography variant="subtitle2">{t("label.step.newCommunity.communitySharingTypeStep.subtitle")}</Typography>
                                            <Grid2 container spacing={2}>
                                                {communitySharingTypes.map((type, index) => (
                                                    <Grid2 item size={{ xs: 12, md: 6, lg: 3 }} key={index}>
                                                        <ValueListWithSelectCard id={`card_sharing_type_${type.code}`} name="sharing_type" code={type.code} title={type.name} description={type.description} disabled={type.disabled} />
                                                    </Grid2>
                                                ))}
                                                <Grid2 item size={{ xs: 12 }}>
                                                    {formik.errors.sharing_type && <FormHelperText id="sharing_type_helper_text" error>{formik.errors.sharing_type}</FormHelperText>}
                                                </Grid2>
                                            </Grid2>
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton variant="outlined" onClick={handleBack} />
                                                <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={2}>
                                    <StepLabel>{t("label.step.newCommunity.businessModelStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={2}>
                                            <Typography variant="subtitle2">{t("label.step.newCommunity.businessModelStep.subtitle")}</Typography>
                                            <Grid2 container spacing={2}>
                                                {tariffModels && tariffModels.map((type, index) => (
                                                    <Grid2 item size={{ xs: 12, md: 6, lg: 3 }} key={index}>
                                                        <ValueListWithSelectCard
                                                            id={`card_tariff_model_${type.code}`}
                                                            name="tariff_model"
                                                            code={type.id}
                                                            icon={type.icon}
                                                            title={t(`label.communities.tariffModel.title.${type.code}`)}
                                                            description={t(`label.communities.tariffModel.description.${type.code}`)}
                                                            disabled={type.disabled}
                                                            />
                                                    </Grid2>
                                                ))}
                                                <Grid2 item size={{ xs: 12 }}>
                                                    {formik.errors.sharing_type && <FormHelperText id="tariff_model_helper_text" error>{formik.errors.sharing_type}</FormHelperText>}
                                                </Grid2>
                                            </Grid2>
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton variant="outlined" onClick={handleBack} />
                                                <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={3}>
                                    <StepLabel>{t("label.step.newCommunity.communityNetworkCostsStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={2}>
                                            <Typography variant="subtitle2">{t("label.step.newCommunity.communityNetworkCostsStep.subtitle")}</Typography>
                                            <SelectYesNo id="invoice_network_costs" name="invoice_network_costs" label={t("label.form.invoiceNetworkCosts")} />
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton variant="outlined" onClick={handleBack} />
                                                <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={4}>
                                    <StepLabel>{t("label.step.newCommunity.communityDataStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={2}>
                                            <Typography variant="subtitle2">{t("label.step.newCommunity.communityDataStep.subtitle")}</Typography>
                                            <IndieTextField id="name" name="name" label={t("label.form.name")} sx={{ width: 200 }} />
                                            <IndieDesktopDatePicker id="activation_date" name="activation_date" label={t("label.form.activationDate")} sx={{ width: 200 }} />
                                            <IndieDSOIDField id="dso_id" name="dso_id" label={t("label.form.code")} type={formik.values?.type} />
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton variant="outlined" onClick={handleBack} />
                                                <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={5}>
                                    <StepLabel>{t("label.step.newCommunity.communityStatusStep")}</StepLabel>
                                    <StepContent>
                                        <Box maxWidth={"lg"}>
                                            <Stack spacing={2}>
                                                <Typography variant="subtitle2">{t("label.step.newCommunity.communityStatusStep.subtitle")}</Typography>
                                                <CommunityStatusSelect name="status" />
                                                <Stack direction="row" spacing={2}>
                                                    <IndieBackButton variant="outlined" onClick={handleBack} />
                                                    <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </StepContent>
                                </Step>
                                <Step key={6}>
                                    <StepLabel>{t("label.step.newCommunity.communityEGACStep")}</StepLabel>
                                    <StepContent>
                                        <Box maxWidth={"md"}>
                                            <Stack spacing={2}>
                                                {entities && entities.length > 0 && (
                                                    <IndieSimpleTable data={entities} columns={columns} loading={loadingEntities} onRowClick={(row) => {
                                                        handleRowClick(row, formik);
                                                    }} selected={selectedEntity} variant="outlined" />
                                                )}
                                                {(!entities || entities.length === 0) && (
                                                    <Alert width={"md"} severity="warning" variant="filled">{t("error.forms.newCommunity.noEGACEntities")}</Alert>
                                                )}
                                                {formik.errors.managing_entity && <FormHelperText id="managing_entity_helper_text" error>{formik.errors.managing_entity}</FormHelperText>}
                                                <Stack direction="row" spacing={2}>
                                                    <IndieBackButton variant="outlined" onClick={handleBack} />
                                                    <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </StepContent>
                                </Step>
                                <Step key={7}>
                                    <StepLabel>{t("label.step.newCommunity.bannerUploadStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={2}>
                                            <Box maxWidth={"md"}>
                                                <NewCommunityBannerUploadStep />
                                            </Box>
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton variant="outlined" onClick={handleBack} />
                                                <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={8}>
                                    <StepLabel>{t("label.step.newCommunity.confirmationStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={4}>
                                            <NewCommunityConfirmationStep />
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton variant="outlined" onClick={handleBack} disabled={submitting} />
                                                <IndieSaveButton variant="contained" id="save_button" type="submit" loading={submitting} disabled={submitting} />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

const NewCommunityBannerUploadStep = () => {

    const { t } = useTranslation();

    return (
        <Fragment>
            <Stack spacing={2}>
                <Typography variant='subtitle2' color='textSecondary'> {t("label.step.newCommunity.bannerUpload.subtitle")}</Typography>
                <Divider />
                <CommunityBannerUpload id="banner_image" fieldName="banner_image" />
            </Stack>
        </Fragment>
    )
}

const NewCommunityConfirmationStep = () => {

    const { values } = useFormikContext();
    const { t } = useTranslation();

    let statusDescription = "";
    try {
        statusDescription = CommunityStatusList.filter((s) => s.value === values.status)[0].label;
    } catch (error) {
        statusDescription = "";
    }

    let communityTypeDescription = "";
    try {
        communityTypeDescription = t(communityTypes.filter((t) => t.code === values.type)[0].name);
    } catch (error) {
        communityTypeDescription = "";
    }

    let sharingTypeDescription = "";
    try {
        sharingTypeDescription = t(communitySharingTypes.filter((t) => t.code === values.sharing_type)[0].name);
    } catch (error) {
        sharingTypeDescription = "";
    }

    return (
        <Fragment>
            <Stack spacing={2}>
                <Typography variant='subtitle2' color='textSecondary'>
                    {t("label.step.newCommunity.confirmationStep.subtitle")}
                </Typography>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunity.communityDataStep")}</Typography>
                    <Divider />
                    <IndieLabelAndValue label="label.form.name" value={values.name} />
                    <IndieLabelAndValue label="label.form.code" value={`${values.dso_id} / ${_.upperCase(values.type)}`} />
                    <IndieLabelAndValue label="label.form.status" value={t(statusDescription)} />
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunity.communityTypeStep")}</Typography>
                    <Divider />
                    <IndieLabelAndValue label="label.form.communityType" value={communityTypeDescription} />
                    <IndieLabelAndValue label="label.form.sharingType" value={sharingTypeDescription} />
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunity.communityNetworkCostsStep")}</Typography>
                    <Divider />
                    <IndieLabelAndValue label="label.form.invoiceNetworkCosts" value={values.invoice_network_costs ? t("label.yes") : t("label.no")} />
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunity.businessModelStep")}</Typography>
                    <Divider />
                    <IndieLabelAndValue label="label.step.newCommunity.businessModelStep" value={t(`label.communities.tariffModel.title.${values.tariff_model}`)} />
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunity.communityEGACStep")}</Typography>
                    <Divider />
                    <IndieLabelAndValue label="label.form.name" value={values.managing_entity?.entity.name} />
                    <IndieLabelAndValue label="label.form.fiscalNumber" value={values.managing_entity?.entity.fiscal_number} />
                    <IndieLabelAndValue label="label.form.zipCode" value={values.managing_entity?.entity.zip_code} />
                    <IndieLabelAndValue label="label.form.address" value={values.managing_entity?.entity.address} />
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunity.respParty")}</Typography>
                    <Divider />
                    <IndieLabelAndValue label="label.form.respPartyName" value={values.managing_entity?.resp_party.name} />
                    <IndieLabelAndValue label="label.form.respPartyEmail" value={values.managing_entity?.resp_party.email} />
                    <IndieLabelAndValue label="label.form.respPartyPhone" value={values.managing_entity?.resp_party.phone} />
                </Stack>
            </Stack>
        </Fragment>
    )
}

export default NewCommunityDialog;