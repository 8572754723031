import CardSectionHeader from "./typography/CardSectionHeader";

const { Card, CardContent, Divider, Stack } = require("@mui/material")

const IndieOutlinedCardSection = ({ children, title, ...props }) => {
    return (
        <Card {...props} variant="outlined" sx={{ borderRadius: "4px", height: "100%", ...props.sx }}>
            <CardContent>
                <Stack spacing={2}>
                    <CardSectionHeader title={title} />
                    <Divider />
                    {children}
                </Stack>
            </CardContent>
        </Card>
    )
}

export default IndieOutlinedCardSection;