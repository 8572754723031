import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { Autocomplete, Card, CardContent, FormControl, FormHelperText, Skeleton, Stack, TextField, Typography, useTheme } from "@mui/material";
import { Box, useMediaQuery } from "@mui/system";
import { useField } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick/lib/slider";
import { useCommunitiesAPI } from "service/CommunitiesAPIService";

export const CommunityStatusList = [
    { value: "prerecord", label: "label.community.status.prerecord" },
    { value: "submitted", label: "label.community.status.submitted" },
    { value: "analyzing", label: "label.community.status.analyzing" },
    { value: "partial_review", label: "label.community.status.partialReview" },
    { value: "total_review", label: "label.community.status.totalReview" },
    { value: "waiting_accept", label: "label.community.status.waitingAcceptance" },
    { value: "record_assignment", label: "label.community.status.cadastreAssignment" },
    { value: "certification_dgeg", label: "label.community.status.certificationDGEG" },
    { value: "certification_ord", label: "label.community.status.certificationORD" },
    { value: "active", label: "label.community.status.active" },
    { value: "suspended", label: "label.community.status.sharingSuspended" }
];

export const CommunityStatusTableFilter = ({ column, fetchData, state, ...params }) => {
    const { filterValue, setFilter } = column;

    const { t } = useTranslation();
    const theme = useTheme();
    const handleFilterChange = (columnId, event, value) => {
        setFilter(value && value.value ? value.value : undefined);
    };

    return (
        <Autocomplete
            size="small"
            id={`autocomplete_${column.id.replaceAll('.', '_')}`}
            options={CommunityStatusList}
            value={filterValue ? CommunityStatusList.filter((o) => o.value === filterValue)[0] : CommunityStatusList.filter((o) => o.value === "")[0]}
            getOptionLabel={(option) => t(option.label)}
            onChange={(e, value) => handleFilterChange("status", e, value)}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    fetchData(1, state.filters);
                }
            }}
            renderInput={(params) => <TextField {...params} id={`textfield_${column.id.replaceAll('.', '_')}`}  autoComplete='off'
                sx={{
                    backgroundColor:
                        theme.palette.mode === "light" ? "#ECEFF5" : theme.palette.divider,
                    borderRadius: "8px",
                    minWidth: "150px",
                    zIndex: (theme) => theme.zIndex.drawer - 1
                }} />}
        />
    );
};

const StatusCard = ({ status, disabled, selected, ...props }) => {

    const { t } = useTranslation();

    return (
        <Card
            onClick={props.onClick}
            id={`card_${status.code}`}
            sx={{
                cursor: !disabled ? "pointer" : "default",
                position: "relative",
                minWidth: 200, height: 300, mr: 2,
                borderColor: selected ? "primary.main" : (props.disabled ? "grey.100" : "grey.300"),
                bgcolor: selected ? "primary.main" : "disabled",
                color: selected ? "white" : "default",
            }}
            variant="outlined">
            <CardContent>
                <Stack spacing={4}>
                    <Typography variant="h6">{t(`label.community.status.${status.code}`)}</Typography>
                    <Typography variant="body2">{status.user_description}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}
const CommunityStatusSelect = ({ ...props }) => {

    const [field, meta, helpers] = useField(props);
    const [statusList, setStatusList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const isDownMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const api = useCommunitiesAPI();

    const onStatusSelected = (status) => {
        helpers.setValue(status.code);
    }

    const loadStatusList = () => {
        setLoading(true);
        api.communitiesStatus((data) => {
            setStatusList(data.filter((s) => s.code !== "deactivated"));
            setLoading(false);
        }, (error) => {
            console.error(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!statusList || statusList.length === 0) {
            loadStatusList();
        }
    }, []);

    useEffect(() => {
        if (field.value && statusList && statusList.length > 0) {
            setSelected(statusList.find(s => s.code === field.value));
        }
    }, [field.value, statusList]);

    if (loading) {
        return (
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width={100} height={40} />
                <Skeleton variant="rectangular" width={100} height={40} />
                <Skeleton variant="rectangular" width={100} height={40} />
                <Skeleton variant="rectangular" width={100} height={40} />
            </Stack>
        )
    }

    if (!statusList || statusList.length === 0) {
        return null;
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isDownMd ? 1 : 4,
        slidesToScroll: 1,
        nextArrow: <ArrowForwardIosRounded color="primary" />,
        prevArrow: <ArrowBackIosNewRounded color="primary" />,
    };

    return (
        <FormControl sx={{ width: "100%", p: 2}}>
            <Slider {...settings} style={{ height: '100%' }}>
                    {statusList.map((status) => (
                        <Box key={status.code} sx={{ height: '100%' }}>
                            <StatusCard
                                status={status}
                                selected={selected === status}
                                onClick={() => onStatusSelected(status)}
                                sx={{ height: '100%' }}
                            />
                        </Box>
                    ))}
                </Slider>
            {meta.error && meta.touched ? (
                <FormHelperText id="status_select_helper_text" error sx={{ ml: 0, pl: 0 }}>{meta.error}</FormHelperText>
            ) : null}
        </FormControl>
    )
}

export default CommunityStatusSelect;