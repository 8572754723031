import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import { createContext, useContext, useEffect, useState } from "react";
import { useCommunitiesAPI } from "service/CommunitiesAPIService";

export const CommunityContext = createContext();

export const CommunityContextProvider = ({ id, community, ...props}) => {

    const service = useCommunitiesAPI();
    const { setAndShowSuccessMessage, setAndShowErrorMessage } = useContext(UserFeedbackContext);

    // State
    const [communityinst, setCommunity] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [updating, setUpdating] = useState(false);

    const getCommunity = (id) => {
        if(!id) {
            console.error("missing ID to get community");
            return;
        };
        setSubmitting(true);
        return new Promise((resolve, reject) => {
            service.fetchById(id).then((response) => {
                setCommunity(response.data);
                setSubmitting(false);
                resolve(response);
            }).catch((e) => {
                setSubmitting(false);
                reject(e);
            })
        });
    }

    const updateCommunity = (values) => {
        setUpdating(true);
        return new Promise((resolve, reject) => {
            service.update(communityinst.id, values).then((response) => {
                setUpdating(false);
                resolve(response);
            }).catch((e) => {
                setUpdating(false);
                reject(e);
            })
        });
    }

    const updateStatus = (status) => {
        return new Promise((resolve, reject) => {
            service.updateStatus(communityinst.id, status).then((response) => {
                setCommunity(response.data);
                resolve(response);
            }).catch((e) => {
                reject(e);
            })
        });
    }

    const updateManagingEntity = (entityId) => {
        return new Promise((resolve, reject) => {
            service.updateManagingEntity(communityinst.id, entityId).then((response) => {
                setCommunity(response.data);
                resolve(response);
            }).catch((e) => {
                reject(e);
            })
        });
    }

    const createInstallation = (data) => {
        setSubmitting(true);
        return new Promise((resolve, reject) => {
            service.createInstallation(communityinst.id, data)
                .then((response) => {
                    setAndShowSuccessMessage("success.api.create");
                    setSubmitting(false);
                    resolve(response);
                })
                .catch((e) => {
                    setSubmitting(false);
                    reject(e);
                });
        });
    }

    const updateInstallation = (id, data) => {
        return new Promise((resolve, reject) => {
            service.updateInstallation(communityinst.id, id, data)
                .then((response) => {
                    setAndShowSuccessMessage("success.api.create");
                    resolve(response);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    const getInstallations = () => {
        return service.getInstallations(communityinst.id);
    }

    const getInstallationsWithMissingMembers = (filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        return service.getInstallations(communityinst.id, [
            {id:"missing_members", value:"true"}, ...filters
        ], page_size, page, sortColumns, callBack, errorCallBack);
    }

    const getMembers = (filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        service.getMembers(communityinst.id, filters, page_size, page, sortColumns, callBack, errorCallBack);
    }

    const getConsumerMembers = (filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        service.getInstallations(communityinst.id, [
            {id:"type", value:"consumers"}, ...filters
        ], page_size, page, sortColumns, callBack, errorCallBack);
    }

    const getProducerMembers = (filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        service.getInstallations(communityinst.id, [
            {id:"type", value:"producers"}, ...filters
        ], page_size, page, sortColumns, callBack, errorCallBack);
    }

    const sendRegistrationEmail = (memberId) => {
        return service.sendRegistrationEmail(communityinst.id, memberId);
    }

    const getCommunitiesTariffModels = () => {
        return service.getCommunityTariffModels();
    }


    useEffect(() => {
        if (id) {
            getCommunity(id).catch((e) => {
                // eat the exception
            });
        } else if (community) {
            setCommunity(community);
        } else {
            setCommunity(null);
        }
    }, []);

    useEffect(() => {
        if (community) {
            setCommunity(community);
        } else {
            setCommunity(null);
        }
    }, [community]);

    return (
        <CommunityContext.Provider value={{
            community: communityinst, setCommunity, getCommunity, updateCommunity, updateStatus,
            updateManagingEntity,
            submitting, updating,
            createMember: createInstallation, getInstallations,
            updateInstallation, getMembers, sendRegistrationEmail,
            getConsumerMembers, getProducerMembers, getInstallationsWithMissingMembers,
            getCommunitiesTariffModels
        }}>
            {props.children}
        </CommunityContext.Provider>
    )
}