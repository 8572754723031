import { useParams } from 'react-router-dom';
import InstallationListPage from './installation-list';
import InstallationPage from './installation-details';

const MemberRouter = () => {

    const { id } = useParams();
    // Check if `id` is a number
    if(!id) {
        return <InstallationListPage />;
    }
    const isInteger = Number.isInteger(Number(id));
    return isInteger ? <InstallationPage /> : <InstallationListPage />;
};

export default MemberRouter;