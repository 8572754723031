import IndieStaticSelect from "components/forms/IndieStaticSelect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePhysicalUnitsAPI } from "service/PhysicalUnitsAPIService";

export const PhysicalUnitProductionTypeSelect = (params) => {
    const physicalUnitsAPI = usePhysicalUnitsAPI();
    const [items, setItems] = useState([]);
    const { t } = useTranslation();
    
    const fetchData = () => {
        physicalUnitsAPI.productionTypes((data) => {
            const transformedData = data.map((o) => ({ "value": o.id, "label": o.description, }));
            setItems(transformedData);
        }, (error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <IndieStaticSelect
            {...params}
            label={t("label.form.productionType")}
            options={items}
            sx={{width: 250}}
        />
    );
};
