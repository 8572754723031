import { Avatar, Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useState } from 'react';

const CommunityTypeCard = ({ code, title, ...props}) => {
    
    const [field, , helpers] = useField(props);
    const [selected, setSelected] = useState(field.value === code);
    const theme = useTheme();

    const handleClick = () => {
        setSelected(!selected);
        helpers.setValue(code);
    }

    const checkBoxColor = props.disabled ? theme.palette.disabled : theme.palette.primary.main;

    useEffect(() => {
        setSelected(field.value === code);
    }, [field.value, code]);

    return (
        <Card id={`card_${code}`} key={code} variant="outlined" onClick={handleClick}
        sx={{
            cursor: props.disabled ? "default" : "pointer",
            width: "100%",
            height: "100%",
            position: "relative",
            borderColor: selected ? "primary.main" : "grey.300",
            bgcolor: selected ? "primary.main" : "disabled",
            color: selected ? "white" : "default",
            ...props.sx || {}
        }}
        >
            <CardContent>
                <Stack spacing={2}>
                    <Avatar  sx={{
                        bgcolor: selected ? "white" : checkBoxColor,
                        color: selected ? checkBoxColor : "white" }}>{code}</Avatar>
                    <Typography variant="h6">{title}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default CommunityTypeCard;