import { Avatar, Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { useField } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';

const ValueListWithSelectCard = ({ code, title, description, icon, ...props}) => {
    
    const [field, , helpers] = useField(props);
    const [selected, setSelected] = useState(field.value === code);
    const theme = useTheme();
    
    const handleClick = () => {
        if (props.disabled) return;
        helpers.setValue(code);
    }

    const checkBoxColor = props.disabled ? theme.palette.disabled : theme.palette.primary.main;

    useEffect(() => {
        setSelected(field.value === code);
    }, [field.value, code]);

    return (
        <Card {...props} key={code} variant="outlined" onClick={handleClick}
            sx={{
                cursor: props.disabled ? "default" : "pointer",
                width: "100%",
                height: "100%",
                position: "relative",
                borderColor: selected ? "primary.main" : (props.disabled ? "grey.100" : "grey.300"),
                bgcolor: selected ? "primary.main" : "disabled",
                color: selected ? "white" : "default",
                ...props.sx || {}
            }}
            >
            <CardContent>
                <Stack spacing={2}>
                    <Avatar sx={{
                        bgcolor: selected ? "white" : checkBoxColor,
                        color: selected ? checkBoxColor : "white" }}>
                            {icon ? (
                                <Typography component="span" sx={{ fontFamily: "Material Icons", fontSize: "25px" }}>
                                    {icon}
                                </Typography >
                            ) : _.upperCase(code[0])}
                        </Avatar>
                    <Typography variant="h6" sx={{color: props.disabled ? "grey.300" : "default"}}>{title}</Typography>
                    <Typography variant="body2" sx={{color: props.disabled ? "grey.300" : "default"}}>{description}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default ValueListWithSelectCard;