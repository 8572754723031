import { GroupAddOutlined, Home } from "@mui/icons-material";
import { Breadcrumbs, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Stack, TextField, Typography } from "@mui/material";
import BreadcrumbLink from "components/breadcrumbs/BreadcrumbLink";
import NewCommunityMemberConfigureMembersDialog from "components/dialogs/communities/NewCommunityMemberConfigureMembersDialog";
import NewCommunityDialog from "components/dialogs/communities/NewCommunityDialog";
import NewCommunityMemberConfigureInstallationDialog from "components/dialogs/communities/NewCommunityMemberConfigureInstallationDialog";
import { CommunityStatusTableFilter } from "components/forms/communities/CommunityStatusSelect";
import { CommunityTypeTableFilter } from "components/forms/communities/CommunityTypeSelector";
import IndieCancelButton from "components/forms/IndieCancelButton";
import IndieContinueButton from "components/forms/IndieContinueButton";
import IndieNewItemButton from "components/forms/IndieNewItemButton";
import IndieCard from "components/IndieCard";
import { H3 } from "components/Typography";
import { CommunityContextProvider } from "contexts/communities/CommunityContext";
import ApplicationList from "page-sections/common/ApplicationList";
import CommunityCard from "page-sections/communities/communities/card/CommunityCard";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCommunitiesAPI } from "service/CommunitiesAPIService";
import NewCommunityMemberConfigureTariffPlanDialog from "components/dialogs/communities/NewCommunityMemberConfigureTariffPlanDialog";

const CommunityList = () => {

    const { t } = useTranslation();
    const api = useCommunitiesAPI();
    const [refreshList, setRefreshList] = useState(1);

    const [openNewCommunityDialog, setOpenNewCommunityDialog] = useState(false);
    const [openNewCommunityMemberDialog, setOpenNewCommunityMemberDialog] = useState(false);
    const [openConfigureInstallationMembersDialog, setOpenConfigureInstallationMembersDialog] = useState(false);
    const [openConfigureTariffPlanDialog, setOpenConfigureTariffPlanDialog] = useState(false);
    
    const [openConfirmContinueToMemberConfigurationDialog, setOpenConfirmContinueToMemberConfigurationDialog] = useState(false);
    const [openConfirmContinueToInstallationConfigurationDialog, setOpenConfirmContinueToInstallationConfigurationDialog] = useState(false);
    const [openConfirmContinueToTariffPlanConfigurationDialog, setOpenConfirmContinueToTariffPlanConfigurationDialog] = useState(false);

    const [createdInstallation, setCreatedInstallation] = useState(null);

    const columns = useMemo(() => [
        {
            accessor: 'name',
            Header: t("label.table.header.name"),
            columnType: "link"
        },
        {
            accessor: 'type',
            Header: t("label.table.header.communityType"),
            Cell: ({ value }) => <Typography variant="body2">{t(`label.community.type.${value}`)}</Typography>,
            filterComponent: CommunityTypeTableFilter
        },
        {
            accessor: 'status',
            Header: t("label.table.header.status"),
            Cell: ({ value }) => <Typography variant="body2">{t(`label.community.status.${value}`)}</Typography>,
            filterComponent: CommunityStatusTableFilter
        },
        {
            accessor: 'dso_id',
            Header: t("label.table.header.communityNumber")
        },
        {
            accessor: 'total_members',
            Header: t("label.table.header.totalMembers"),
            canFilter: false,
            Filter: () => {
                return <TextField size="small" variant="outlined" sx={{visibility:"hidden"}}/>;
            },
        },
    ], [t]);

    const onSuccessCreateInstallation = (installation) => {
        setCreatedInstallation(installation);
        setOpenNewCommunityMemberDialog(false);
        setOpenConfirmContinueToMemberConfigurationDialog(true);
    }

    const onCancelContinueToConfigureMember = () => {
        setCreatedInstallation(null);
        setOpenConfirmContinueToMemberConfigurationDialog(false);
    }

    const onConfirmContinueToConfigureMember = () => {
        setOpenConfirmContinueToMemberConfigurationDialog(false);
        setOpenConfigureInstallationMembersDialog(true);
    }

    const onSuccessConfigureMembers = () => {
        setOpenConfigureInstallationMembersDialog(false);
        setOpenConfirmContinueToTariffPlanConfigurationDialog(true);
    }

    const onConfirmContinueToTariffPlan = () => {
        setOpenConfirmContinueToTariffPlanConfigurationDialog(false);
        setOpenConfigureTariffPlanDialog(true);
    }

    const onCloseCommunityCreation = () => {
        setOpenNewCommunityDialog(false);
    }

    const onSuccessCommunityCreation = () => {
        onCloseCommunityCreation();
        setOpenConfirmContinueToInstallationConfigurationDialog(true);
        setRefreshList(refreshList + 1);
    };

    const onConfirmConfirmContinueToInstallationConfigurationDialog = () => {
        setOpenConfirmContinueToInstallationConfigurationDialog(false);
        setOpenNewCommunityMemberDialog(true);
    }

    return (
        <Stack spacing={2}>
            <IndieCard>
                <CardContent>
                    <Stack direction="column" spacing={1}>
                        <Breadcrumbs>
                            <BreadcrumbLink value={"label.menu.home"} href="/" icon={<Home sx={{ mr: 0.5 }} fontSize="inherit" />}/>
                            <BreadcrumbLink value={"label.menu.communities"} />
                        </Breadcrumbs>
                        <H3>{t("label.menu.communities")}</H3>
                    </Stack>
                </CardContent>
            </IndieCard>
            <IndieCard>
                <CardContent>
                    <ApplicationList
                        id="communities_list"
                        application={"communities/communities"}
                        dataFetcher={api.list}
                        showAppBar={true}
                        columns={columns}
                        refresh={refreshList}
                        showGridView
                        cardComponent={CommunityCard}
                        outlined
                        appBar={[
                            <IndieNewItemButton key={"new"} id="create_community_button" variant="contained" onClick={() => setOpenNewCommunityDialog(true)} label={t("label.menu.addCommunity")}/>,
                            <IndieNewItemButton key={"newMember"} id="create_community_member_button"  variant="outlined" onClick={() => setOpenNewCommunityMemberDialog(true)} label={t("label.button.newMember")}/>
                        ]}
                    />
                </CardContent>
            </IndieCard>
            
            
            <CommunityContextProvider>
                <ConfirmContinueToInstallationConfigurationDialog open={openConfirmContinueToInstallationConfigurationDialog} onConfirm={onConfirmConfirmContinueToInstallationConfigurationDialog} onClose={() => setOpenConfirmContinueToInstallationConfigurationDialog(false)} />
                <NewCommunityMemberConfigureInstallationDialog open={openNewCommunityMemberDialog} onClose={() => setOpenNewCommunityMemberDialog(false)} onSuccess={onSuccessCreateInstallation} />
                <ConfirmContinueToMemberConfigurationDialog open={openConfirmContinueToMemberConfigurationDialog} onConfirm={onConfirmContinueToConfigureMember} onClose={onCancelContinueToConfigureMember} />
                <NewCommunityMemberConfigureMembersDialog open={openConfigureInstallationMembersDialog} onClose={() => setOpenConfigureInstallationMembersDialog(false)} installation={createdInstallation} onSuccess={onSuccessConfigureMembers} />
                <ConfirmContinueToTariffPlanConfigurationDialog open={openConfirmContinueToTariffPlanConfigurationDialog} onConfirm={onConfirmContinueToTariffPlan} onClose={() => setOpenConfirmContinueToTariffPlanConfigurationDialog(false)} />
                <NewCommunityMemberConfigureTariffPlanDialog open={openConfigureTariffPlanDialog} onClose={() => setOpenConfigureTariffPlanDialog(false)} handleSuccess={() => setOpenConfigureTariffPlanDialog(false)} />
            </CommunityContextProvider>

            {openNewCommunityDialog && <NewCommunityDialog open={openNewCommunityDialog} handleClose={onCloseCommunityCreation}
                handleSuccess={onSuccessCommunityCreation}
                />}
        </Stack>
    )
};

const ConfirmContinueToMemberConfigurationDialog = ({open, onConfirm, onClose}) => {

    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <Stack spacing={2} direction="row">
                    <GroupAddOutlined color="primary" />
                    <Typography variant="subtitle1" color="textPrimary">{t("label.button.newMember")}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Divider />
                    <DialogContentText>{t("label.dialog.confirmContinueToMemberConfiguration.message")}</DialogContentText>
                </Stack>
            </DialogContent>
            <DialogActions>
                <IndieCancelButton onClick={onClose} />
                <IndieContinueButton onClick={onConfirm} autoFocus />
            </DialogActions>
        </Dialog>
    )
}

const ConfirmContinueToInstallationConfigurationDialog = ({open, onConfirm, onClose}) => {

    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <Stack spacing={2} direction="row">
                    <GroupAddOutlined color="primary" />
                    <Typography variant="subtitle1" color="textPrimary">{t("label.menu.addCommunity")}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Divider />
                    <DialogContentText>{t("label.dialog.confirmContinueToInstallationConfigurationDialog.message")}</DialogContentText>
                </Stack>
            </DialogContent>
            <DialogActions>
                <IndieCancelButton id="cancel_button" onClick={onClose} label={t("label.form.no")}/>
                <IndieContinueButton id="confirm_button" onClick={onConfirm} autoFocus />
            </DialogActions>
        </Dialog>
    )
}

const ConfirmContinueToTariffPlanConfigurationDialog = ({open, onConfirm, onClose}) => {

    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <Stack spacing={2} direction="row">
                    <GroupAddOutlined color="primary" />
                    <Typography variant="subtitle1" color="textPrimary">{t("label.menu.addCommunity")}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Divider />
                    <DialogContentText>{t("label.dialog.confirmContinueToTariffPlanConfigurationDialog.message")}</DialogContentText>
                </Stack>
            </DialogContent>
            <DialogActions>
                <IndieCancelButton id="cancel_button" onClick={onClose} label={t("label.form.no")}/>
                <IndieContinueButton id="confirm_button" onClick={onConfirm} autoFocus />
            </DialogActions>
        </Dialog>
    )
}

export default CommunityList;