import useAxios from "utils/useAxios";

export const useTaxRegimesAPIService = () => {
    
    const { cachedRequest } = useAxios();
    const BASE_URL = '/core/taxes/regimes';

    let get = (country , callBack) => {
        cachedRequest(`fiscal-entities-${country}`, `${BASE_URL}?country=${country}`, callBack)
    }

    return {
        get : get
    }
};

