import { Alert, Grid2, Stack, Typography } from '@mui/material';
import PhysicalUnitInstallationTypeSelect from 'components/assets/PhysicalUnitInstallationTypeSelect';
import { PhysicalUnitProductionTypeSelect } from 'components/assets/PhysicalUnitProductionTypeSelect';
import { PhysicalUnitTensionLevelSelect } from 'components/assets/PhysicalUnitTensionLevelSelect';
import IndieNumberField from 'components/forms/IndieNumberField';
import IndieTextField from 'components/forms/IndieTextField';
import PhysicalUnitTypeRadio from 'components/forms/installations/PhysicalUnitTypeRadio';
import PhysicalUnitSelect from 'components/markets/PhysicalUnitSelect';
import SelectYesNo from 'components/SelectYesNo';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

const PhysicalUnitInformationForm = ({ showConsumptionData = false, showProductionData = false, showStorageData = false, showPowerTradingData = false, ...props }) => {

    const formik = useFormikContext();
    const { t } = useTranslation();

    const resetmarketPhysicalUnit = (formik) => {
        formik.setFieldValue("physical_unit.trading_physical_unit", '');
    }

    if (!formik.values?.physical_unit) {
        return <Alert severity='warn'>Sem dados de Unidade Física.</Alert>
    }

    return (
        <Grid2 container spacing={4}>
            {showConsumptionData && (
                <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Stack spacing={2}>
                        <Typography variant="subtitle2">{t("label.title.consumption")}</Typography>

                        {showPowerTradingData && (
                            <PhysicalUnitTypeRadio
                                name="physical_unit.type"
                                id="physical_unit_type"
                                disabled={props.disabled}
                            />
                        )}

                        {showPowerTradingData && (
                            <IndieTextField
                                id="physical_unit_name"
                                name="physical_unit.name"
                                label={t("label.form.name")}
                                disabled={props.disabled}
                            />
                        )}

                        <IndieTextField
                            id="physical_unit_consumption_deliverypoint"
                            name="physical_unit.consumption_deliverypoint"
                            label={t("label.form.consumptionDeliveryPoint")}
                            sx={{ width: 200 }}
                            disabled={props.disabled}
                        />

                        <IndieNumberField
                            id="physical_unit_certified_power"
                            name="physical_unit.certified_power"
                            label={t("label.form.certifiedPower")}
                            sx={{ width: 200 }}
                            disabled={props.disabled}
                        />

                        <PhysicalUnitTensionLevelSelect
                            id="physical_unit_tension_level"
                            name="physical_unit.tension_level"
                            disabled={props.disabled}
                        />

                        <SelectYesNo
                            id="physical_unit_has_evcp"
                            name="physical_unit.has_evcp"
                            label={t("label.form.hasEvcp")}
                            disabled={props.disabled}
                        />

                    </Stack>
                </Grid2>
            )}
            {showProductionData && (
                <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Stack spacing={4}>
                        <Stack spacing={2}>
                            <Typography variant="subtitle2">{t("label.step.newCommunityMember.installationDetailsStep.productionInstallationData")}</Typography>
                            <IndieTextField
                                id="physical_unit_production_deliverypoint"
                                name="physical_unit.production_deliverypoint"
                                label={t("label.form.productionDeliveryPoint")}
                                fullWidth
                                disabled={props.disabled}
                            />

                            <IndieNumberField
                                id="physical_unit_installed_power"
                                name="physical_unit.installed_power"
                                label={t("label.form.installedPowerKWh")}
                                disabled={props.disabled}
                                sx={{width: 250}} />

                            {showPowerTradingData && (
                                <IndieNumberField
                                    id="physical_unit_installed_power_kva"
                                    name="physical_unit.installed_power_kva"
                                    label={t("label.form.installedPowerKva")}
                                    disabled={props.disabled}
                                    sx={{width: 250}}
                                />
                            )}

                            <IndieNumberField
                                id="physical_unit_connection_power"
                                name="physical_unit.connection_power"
                                label={t("label.form.connectedPowerKWh")}
                                disabled={props.disabled}
                                onChange={() => resetmarketPhysicalUnit(formik)}
                                sx={{width: 250}}
                            />

                            {showPowerTradingData && (
                                <IndieNumberField
                                    id="physical_unit_connection_power_kva"
                                    name="physical_unit.connection_power_kva"
                                    label={t("label.form.connectedPowerKva")}
                                    disabled={props.disabled}
                                    sx={{width: 250}}
                                />)}

                            <IndieNumberField
                                id="physical_unit_generator_power_kwh"
                                name="physical_unit.generator_power_kwh"
                                label={t("label.form.generatorPowerKWh")}
                                disabled={props.disabled}
                                sx={{width: 250}} />

                            {showPowerTradingData && (
                                <PhysicalUnitProductionTypeSelect
                                    id="physical_unit_production_type"
                                    name="physical_unit.production_type"
                                    label={t("label.form.productionType")}
                                    formik={formik}
                                    value={formik.values?.physical_unit?.production_type}
                                    error={Boolean(formik.touched?.physical_unit?.production_type && formik.errors?.physical_unit?.production_type)}
                                    helperText={formik.touched?.physical_unit?.production_type && formik.errors?.physical_unit?.production_type}
                                    disabled={props.disabled}
                                />
                            )}

                            {showPowerTradingData && (
                                <PhysicalUnitInstallationTypeSelect
                                    id="physical_unit_installation_type"
                                    name="physical_unit.installation_type"
                                    formik={formik}
                                    value={formik.values?.physical_unit.installation_type || ''}
                                    error={Boolean(formik.touched?.physical_unit?.installation_type && formik.errors?.physical_unit?.installation_type)}
                                    helperText={formik.touched?.physical_unit?.installation_type && formik.errors?.physical_unit?.installation_type}
                                    disabled={props.disabled}
                                />
                            )}

                            <Typography variant="subtitle2">{t("label.step.newCommunityMember.installationDetailsStep.licensingDetails")}</Typography>
                            <IndieTextField
                                id="physical_unit_licencing_registration_number"
                                name="physical_unit.licencing_registration_number"
                                label={t("label.form.registrationNumber")}
                                disabled={props.disabled}
                                maxLength={100}
                            />
                            <IndieTextField
                                id="physical_unit_licencing_record_number"
                                name="physical_unit.licencing_record_number"
                                label={t("label.form.recordNumber")}
                                disabled={props.disabled}
                                maxLength={100}
                            />
                        </Stack>
                    </Stack>
                </Grid2>)}
            {showPowerTradingData && (
                <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Stack spacing={2}>
                        <Typography variant="subtitle2">{t("label.step.newCommunityMember.elctricityMarketDataStep")}</Typography>
                        <IndieTextField
                            id="physical_unit_omie_reference_number"
                            name="physical_unit.omie_reference_number"
                            label={t("label.form.omieSolicitude")}
                            disabled={props.disabled}
                            permissionGroups={["!CLIENT"]}
                            maxLength={100}
                        />

                        <PhysicalUnitSelect
                            id="physical_unit_trading_physical_unit"
                            name="physical_unit.trading_physical_unit"
                            label={t("label.form.gcsCode")}
                            installationType={formik.values?.physical_unit?.installation_type}
                            connectionPower={formik.values?.physical_unit?.connection_power}
                            handleOnChange={(e, v) => formik.setFieldValue("physical_unit.trading_physical_unit", v?.id)}
                            error={Boolean(formik.touched?.physical_unit?.trading_physical_unit && formik.errors?.physical_unit?.trading_physical_unit)}
                            helperText={formik.touched?.physical_unit?.trading_physical_unit && formik.errors?.physical_unit?.trading_physical_unit}
                            disabled={props.disabled}
                        />
                    </Stack>
                </Grid2>
            )}
            {showStorageData && (
                <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Stack spacing={2}>
                        <Typography variant="subtitle2">{t("label.step.newCommunityMember.installationDetailsStep.storageInstallationData")}</Typography>
                        <IndieNumberField
                            id="physical_unit_storage_power_kwh"
                            name="physical_unit.storage_power_kwh"
                            label={t("label.form.storagePowerKWh")}
                            sx={{width: 250}} disabled={props.disabled}/>

                        <IndieNumberField
                            id="physical_unit_storage_capacity_kwh"
                            name="physical_unit.storage_capacity_kwh"
                            label={t("label.form.storageCapacityKWh")}
                            sx={{width: 250}} disabled={props.disabled}/>
                    </Stack>
                </Grid2>)}
        </Grid2>
    )
}

export default PhysicalUnitInformationForm;