const { Card } = require("@mui/material")

const IndieCard = ({children, ...props}) => {
    return (
        <Card {...props} sx={{borderRadius: "4px", height: "100%", ...props.sx}}>
            {children}
        </Card>
    )
}

export default IndieCard;