import { Grid } from "@mui/material";
import { Small } from "components/Typography";
import { useTranslation } from "react-i18next";

const CommunityCardValue = (props) => {

    const { t } = useTranslation();

    return (
        <Grid item xs={6} textAlign="left">
            <Small color="text.secondary" fontSize={12} sx={{ display: 'block' }}>
                {t(props.title)}
            </Small>
            <Small color="warning.main" fontWeight={600}>
                {props.value} {t(props.unit)}
            </Small>
        </Grid>
    );
};

export default CommunityCardValue;