import { Autocomplete, FormHelperText, Grid2, TextField, useTheme } from "@mui/material";
import { useField } from "formik";
import CommunityTypeCard from "./CommunityTypeCard";
import { useTranslation } from "react-i18next";
import IndieStaticSelect from "../IndieStaticSelect";

export const communityTypes = [
    {
        code: "acc",
        name: "Autoconsumo Colectivo",
        description: "Os autoconsumidores que participem num ACC têm um regulamento interno que é comunicado à DGEG, no prazo máximo de três meses após a entrada em funcionamento da UPAC, e que define, pelo menos, os requisitos de acesso de novos membros e saída de participantes existentes, as maiorias deliberativas exigíveis, o modo de partilha da energia elétrica produzida para autoconsumo e o pagamento das tarifas devidas, bem como o destino dos excedentes do autoconsumo e a política de relacionamento comercial a adotar e, se for caso disso, a aplicação da respetiva receita."
    },
    {
        code: "cer",
        name: "Comunidades de Energia Renovável",
        description: "Uma Comunidade de Energia Renovável é uma pessoa coletiva constituída nos termos do Decreto-Lei n.º 15/2022, de 14 de janeiro, mediante adesão aberta e voluntária dos seus membros, sócios ou acionistas, os quais podem ser pessoas singulares ou coletivas, de natureza pública ou privada, incluindo, nomeadamente, pequenas e médias empresas ou autarquias locais, e por estes controlada"
    }
];

export const CommunityTypeTableFilter = ({ column, fetchData, state, ...params }) => {
    const { filterValue, setFilter } = column;

    const { t } = useTranslation();
    const theme = useTheme();
    const handleFilterChange = (columnId, event, value) => {
        setFilter(value && value.code ? value.code : undefined);
    };

    return (
        <Autocomplete
            size="small"
            id={`autocomplete_${column.id.replaceAll('.', '_')}`}
            options={communityTypes}
            value={filterValue ? communityTypes.filter((o) => o.code === filterValue)[0] : communityTypes.filter((o) => o.code === "")[0]}
            getOptionLabel={(option) => t(option.name)}
            onChange={(e, value) => handleFilterChange("status", e, value)}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    fetchData(1, state.filters);
                }
            }}
            renderInput={(params) => <TextField {...params} id={`textfield_${column.id.replaceAll('.', '_')}`} autoComplete='off'
                sx={{
                    backgroundColor:
                        theme.palette.mode === "light" ? "#ECEFF5" : theme.palette.divider,
                    borderRadius: "8px",
                    minWidth: "150px",
                    zIndex: (theme) => theme.zIndex.drawer - 1
                }} />}
        />
    );
};


const CommunityTypeSelector = ({ ...props }) => {
    const [, meta, ] = useField(props);


    return (
        <Grid2 container spacing={2}>
            {communityTypes.map((type, index) => (
                <Grid2 key={index} item size={{xs:12, sm:6, md:4, lg:3}}>
                    <CommunityTypeCard name="type" code={type.code} title={type.name} description={type.description} />
                </Grid2>
            ))}
            <Grid2 item size={{xs:12}}>
            {meta.error ? (
                <FormHelperText id={`${props.name}_helper_text`} error sx={{ ml: 0, pl: 0 }}>{meta.error}</FormHelperText>
            ) : null}
            </Grid2>
        </Grid2>
    )
}

const CommunityTypeSelect = (props) => {

    const { t } = useTranslation();

    return (
        <IndieStaticSelect
            label={t("label.form.type")}
            options={communityTypes.map((t) => ({value:t.code,label:t.name}))}
            sx={{width: 250}}
            {...props}
        />
    )
}

export { CommunityTypeSelect };
export default CommunityTypeSelector;