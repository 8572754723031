import { useTheme } from "@emotion/react";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TableSelectFilter = ({ column, optionsLoader, fetchData, state }) => {

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const { filterValue, setFilter } = column;

    const { t } = useTranslation();
    const theme = useTheme();
    const handleFilterChange = (event, value) => {
        setFilter(value && value.value ? value.value : undefined);
    };

    useEffect(() => {
        setLoading(true);
        optionsLoader().then((response) => {
            setOptions(response);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Skeleton variant="text" height={50} />
    }

    return (
        <Autocomplete
            size="small"
            id={`autocomplete_${column.id.replaceAll('.', '_')}`}
            options={options}
            value={filterValue ? options.filter((o) => o.value === filterValue)[0] : options.filter((o) => o.value === "")[0]}
            getOptionLabel={(option) => t(option.label)}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    fetchData(1, state.filters);
                }
            }}
            onChange={(e, value) => handleFilterChange(e, value)}
            renderInput={(params) => <TextField {...params} id={`textfield_${column.id.replaceAll('.', '_')}`} autoComplete='off'
                sx={{
                    backgroundColor:
                        theme.palette.mode === "light" ? "#ECEFF5" : theme.palette.divider,
                    borderRadius: "8px",
                    minWidth: "150px",
                    zIndex: (theme) => theme.zIndex.drawer - 1
                }} />}
        />
    );
}

export default TableSelectFilter;