import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTaxRegimesAPIService } from "service/core/TaxRegimesAPIService";
import IndieStaticSelect from "./IndieStaticSelect";

export const IndieTaxRegimesSelect = ({country, permissionGroups, ...params}) => {
    
    const api = useTaxRegimesAPIService();
    const { t } = useTranslation();
    const [items, setItems] = useState([]);

    const { hasPermissionGroup } = useAuth();

    const fetchData = () => {
        api.get(country, (data) => {
            setItems(data.map((i) =>({
                value: i.id,
                label: i.description
            })));
        });
    }

    useEffect(() => {
        fetchData();
    }, [country]);

    if(permissionGroups && ! hasPermissionGroup(permissionGroups)) {
        return "";
    }
    
    return (
        <IndieStaticSelect
            {...params}
            label={t("label.form.taxRegime")}
            options={items}
            sx={{width:300}}
            />
    );
};
