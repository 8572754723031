import { DRF_DATE_FORMAT } from '../constants';
import { useDocumentsAPI } from 'service/DocumentsAPIService';
import moment from 'moment';

export const searchByName = (listData, searchValue) => {
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item) =>
      item.name.toLocaleLowerCase().match(searchValue.toLowerCase())
    );
    return searchResult;
  } else {
    return listData;
  }
};
export const getRoute = (pathname) => {
  const str = pathname.split("/");
  return `/${str[1]}`;
};

export const formatNumber = (value, decimalCases) => {
  let _decimalCases = decimalCases || 2;
  let num = Number(value);
  return num.toFixed(_decimalCases);
};

export const formatNumberString = (originalValue = 0, decimalCases = 2) => {
  /**
   * truncates any string representing a number down to two decimal cases.
   * The standard methods of nodeJS perform roundings that cannot be accepted
   * in the platform (E.g. toFixed, toPrecision).
   */
  if (originalValue === null || originalValue === undefined) {
    originalValue = 0.00;
  }

  let matches = originalValue.toString().match("^-?\\d+(?:\\.\\d{0," + +decimalCases + "})?");

  try {
    let match = matches[0];
    return match;
  } catch (e) {
    console.warn("failed to match for " + originalValue.toString());
    console.error(e);
    return "0.00";
  }
}

export const useOpenDocument = () => {
  const documentsApi = useDocumentsAPI();

  const open = (id) => {
    documentsApi.fetchURLById(id, (response) => {
      //window.open(response.data, '_blank', 'noopener,noreferrer');
      var a = document.createElement('A');
      a.target = "_blank";
      a.href = response.data;
      a.download = response.data.substr(response.data.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }, (error) => {
      console.error(error);
    });
  }

  return {
    open: open
  };

}

export const validateFiscalNumber = (nif) => {
  const validationSets = {
    one: ['1', '2', '3', '5', '6', '8'],
    two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99']
  };
  if (!nif || nif.length !== 9) return false;
  if (!validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2))) return false;
  const total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
  const modulo11 = (Number(total) % 11);
  const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;
  return checkDigit === Number(nif[8]);
}

export const prepareDateForSubmit = (date) => {
  if (date === null || date === undefined) {
    return null;
  }

  try {
    let _date = moment(date);
    return _date.format(DRF_DATE_FORMAT);
  } catch (e) {
    return date;
  }
}

/**
 * 
 * @param {*} tabIndex
 * @returns 
 */
export const getCurrentTab = (levels, level, _default) => {

  let path = window.location.pathname;
  // remove the last slash if it exists
  if (path.endsWith("/")) {
    path = path.slice(0, -1);
  }
  const pathParts = path.split("/");
  pathParts.shift(); // remove the first empty string
  try {
    const lastPath = pathParts[pathParts.length - 1 - levels + level];
    return lastPath;
  } catch (e) {
    return _default;
  }

}

export const validateCPE = (cpe) => {
  const pattern = /^[\w]{2}[\d]{4}[\d]{12}[\w]{2}$/;

  // Check if the string is not empty and matches the pattern
  if (!cpe || pattern.test(cpe)) {
    return true; // String matches the pattern
  } else {
    return false; // String does not match the pattern or is empty
  }
}

export const validateZipCode = (zipCode) => {
  const pattern = /^\d{4}-\d{3}$/;

  // Check if the string is not empty and matches the pattern
  if (!zipCode || pattern.test(zipCode)) {
    return true; // String matches the pattern
  } else {
    return false; // String does not match the pattern or is empty
  }
}

export const validateIBAN = (iban) => {
  // Remove spaces and convert to uppercase
  if (!iban) {
    return true;
  }
  iban = iban.replace(/\s+/g, '').toUpperCase();

  // Check if the IBAN length is valid for the country code
  const ibanRegex = /^[A-Z]{2}\d{2}[A-Z\d]{11,}$/;
  if (!ibanRegex.test(iban)) {
    return false;
  }

  // Rearrange the IBAN
  const rearrangedIBAN = iban.substr(4) + iban.substr(0, 4)
    .replace(/[A-Z]/g, char => char.charCodeAt(0) - 55);

  // Calculate modulo 97 to validate IBAN
  let remainder = 0;
  for (let i = 0; i < rearrangedIBAN.length; i++) {
    const charValue = parseInt(rearrangedIBAN.charAt(i), 10);
    remainder = (remainder * 10 + charValue) % 97;
  }

  return remainder === 1;
}

export const PASSWORD_MIN_LENGTH = 10;
export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#\$%^&*_])(?=.{10,}).+$/;

export const marketPeriodLabel = (marketPeriod, resolution) => {
  /**
   * Converts a time slot number (1-96) to a string in the format H<HH>Q<Q>.
   *
   * Args:
   *     timeSlot: An integer representing a 15-minute time slot (1-96).
   *
   * Returns:
   *     A string in the format H<HH>Q<Q>, or null if the input is invalid.
   */

  if (marketPeriod < 1 || marketPeriod > 96) {
    return null; // Handle invalid input
  }

  if (resolution === "PT15M") {
    marketPeriod--; // Adjust to 0-based indexing
    let hour = Math.floor(marketPeriod / 4) + 1; // Integer division to get the hour (1-24)
    let quarter = (marketPeriod % 4) + 1; // Modulo to get the quarter (1-4)

    let hourStr = String(hour).padStart(2, '0'); // Format hour with leading zero

    return `H${hourStr}Q${quarter}`;
  } else if (resolution === "PT60M") {
    let hourStr = String(marketPeriod).padStart(2, '0'); // Format hour with leading zero
    return `H${hourStr}`;
  } else {
    return marketPeriod;
  }
}