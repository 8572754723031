import LoadingScreen from "components/LoadingScreen";
import { ModuleContextProvider } from "contexts/ModuleContext";
import DashboardLayoutV3 from "layouts/layout-v3/DashboardLayout";
import CommunitiesRouter from "pages/communities/communities/communities-router";
import InstallationListPage from "pages/communities/installations/installation-list";
import MemberRouter from "pages/communities/installations/member-router";
import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );
}; // dashboards

/**
 * ENERGIA INDIE CUSTOM ROUTES
 */ 
/* Communities Module */
const CommunitiesList = Loadable(lazy(() => import("./pages/communities/communities/communities-list")));
const CommunityInstallationsList = Loadable(lazy(() => import("./pages/communities/installations/installation-list")));
const CommunityinstallationsDetails = Loadable(lazy(() => import("./pages/communities/installations/installation-details")));

/* Assets Module */
const ConsumptionInstallationList = Loadable(lazy(() => import("./pages/consumption-installation/consumption-installation-list")));
const ConsumptionInstallationDetails = Loadable(lazy(() => import("./pages/consumption-installation/consumption-installation-details")));
const InstallationsList = Loadable(lazy(() => import("./pages/installations/installations-list")));
const InstallationsDetails = Loadable(lazy(() => import("./pages/installations/installations-details")));
const InstallationsDetailsV2 = Loadable(lazy(() => import("./pages/core/assets/installation-details")));
const ProductionUnitsList = Loadable(lazy(() => import("./pages/production-units/production-units-list")));
const ProductionUnitsDetails = Loadable(lazy(() => import("./pages/production-units/production-units-details")));
const StorageInstallationList = Loadable(lazy(() => import("./pages/storage-installation/storage-installation-list")));
/* Members Module */
const EntityList = Loadable(lazy(() => import("./pages/core/entities/entity-list")));
const EntityDetails = Loadable(lazy(() => import("./pages/core/entities/entity-details")));
/* Authentication */
const Logout = Loadable(lazy(() => import("./pages/authentication/logout")));
/* Financial */
const CommunitiesInvoicingList = Loadable(lazy(() => import("./pages/invoicing/communities/communities-invoicing-list")));
/* Agents */
const AggregatorsList = Loadable(lazy(() => import("./pages/power-trading/agents/agents-list")));
const AggregatorDetails = Loadable(lazy(() => import("./pages/power-trading/agents/agents-details")));

const AgentListPage = Loadable(lazy(() => import("./pages/core/agents/agent-list")));
const AgentDetailsPage = Loadable(lazy(() => import("./pages/core/agents/agent-details")));
const AgentRegistrationPage = Loadable(lazy(() => import("./pages/core/agents/agent-registration")));
const AgentAccountPage = Loadable(lazy(() => import("./pages/core/agents/agent-account")));
const AgentEntityDetailsPage = Loadable(lazy(() => import("./pages/core/agents/agent-entities-details")));

/* Producers */
const ProducersList = Loadable(lazy(() => import("./pages/power-trading/producers/producer-list")));
const ProducersDetail = Loadable(lazy(() => import("./pages/power-trading/producers/producer-details")));
const ProducersNew = Loadable(lazy(() => import("./pages/power-trading/producers/producer-new")));
/* Contracts */
const ContractsList = Loadable(lazy(() => import("./pages/power-trading/contracts/contract-list")));
const ContractsDetails = Loadable(lazy(() => import("./pages/power-trading/contracts/contract-details")));
const ContractBalanceDetails = Loadable(lazy(() => import("./pages/power-trading/contracts/contract-balance")));
const ContractNewFinDoc = Loadable(lazy(() => import("./pages/power-trading/contracts/contract-findoc-new")));
const ContractNewFinDocStepper = Loadable(lazy(() => import("./pages/power-trading/contracts/contract-findoc-new2")));
const ContractVoidFinDoc = Loadable(lazy(() => import("./pages/power-trading/contracts/contract-findoc-void")));
const ContractEditFinDoc = Loadable(lazy(() => import("./pages/power-trading/contracts/contract-findoc-edit")));
const ContractViewFinDoc = Loadable(lazy(() => import("./pages/power-trading/contracts/contract-findoc-view")));

const CommunitiesTariffPlansList = Loadable(lazy(() => import("./pages/communities/tariffs/tariff-plans-list")));

const TariffModellListPage = Loadable(lazy(() => import("./pages/core/tariffs/tariff-models-list")));
const TariffModelDetailsPage = Loadable(lazy(() => import("./pages/core/tariffs/tariff-model-details")));

/* Clients */
const ClientsList = Loadable(lazy(() => import("./pages/power-trading/clients/client-list")));
const ClientDetails = Loadable(lazy(() => import("./pages/core/clients/client-details")));
/* Physical Units */
const PhysicalUnitDetails = Loadable(lazy(() => import("./pages/core/physical-units/physical-unit-details")));

/* Power Trading */
const TradingFinDocsList = Loadable(lazy(() => import("./pages/power-trading/financial-docs/financial-docs-list")));
const MarketPhysicalUnitsList = Loadable(lazy(() => import("./pages/power-trading/market-units/market-physical-unit-list")));
const MarketPhysicalUnitsDetails = Loadable(lazy(() => import("./pages/power-trading/market-units/market-physical-unit-details")));
/** ***************************************************************************
 *  Energy Markets Route
 *  ***************************************************************************
 */
const DayAheadMarketPage = Loadable(lazy(() => import("./pages/power-trading/markets/market-dayahead-market")));
const IntradayMarketPage = Loadable(lazy(() => import("./pages/power-trading/markets/market-intraday-market")));
const TradingMarketOfferDetailsPage = Loadable(lazy(() => import("./pages/power-trading/markets/market-offer-details")));

const SettlementsPage = Loadable(lazy(() => import("./pages/power-trading/settlements/settlements-page")));

const DataUploadPage = Loadable(lazy(() => import("./pages/power-trading/data-upload/data-upload-list")));
/** ***************************************************************************
 *  Energy Management Routes 
 *  ***************************************************************************
 */

/** Energy Forecasts */
const ForecastTypesListPage = Loadable(lazy(() => import("./pages/core/energy-management/forecasts/forecast-type-list")));
const ForecastTypeDetailsPage = Loadable(lazy(() => import("./pages/core/energy-management/forecasts/forecast-type-details")));
const ForecastTypeInstanceAddPage = Loadable(lazy(() => import("./pages/core/energy-management/forecasts/forecast-type-instance-add")));
const ForecastTypeInstanceDetailsPage = Loadable(lazy(() => import("./pages/core/energy-management/forecasts/forecast-type-instance-details")));
/** ***************************************************************************
 *  User Management Routes 
 *  ***************************************************************************
 */
const UserRegistrationPage = Loadable(lazy(() => import("./pages/core/user/user-registration")));
const UserPasswordResetRequest = Loadable(lazy(() => import("./pages/core/user/user-password-reset-request")));
const UserPasswordReset = Loadable(lazy(() => import("./pages/core/user/user-password-reset")));
const UserRegistrationResend = Loadable(lazy(() => import("./pages/core/user/user-registration-request")));
/**
 * Reports Routes
 */
const ReportsList = Loadable(lazy(() => import("./pages/core/reports/reports-list")));
const ReportRequest = Loadable(lazy(() => import("./pages/core/reports/new-report-request")));

/** ***************************************************************************
 *  Other Routes 
 *  ***************************************************************************
 */
const TermsAndConditionsPage = Loadable(lazy(() => import("./pages/core/user/terms-and-conditions")));
/**
 * TEMPLATE ROUTES
 */
const Login = Loadable(lazy(() => import("./pages/authentication/login")));
const MarketAgentLogin = Loadable(lazy(() => import("./pages/authentication/market-agent-login")));
const Error404 = Loadable(lazy(() => import("./pages/404")));
/** ***************************************************************************
 *  Member Area Routes 
 *  ***************************************************************************
 */
const HomePage = Loadable(lazy(() => import("./pages/dashboards/home")));
const MyDetails = Loadable(lazy(() => import("./pages/core/entities/my-details")));
const SaftFiles = Loadable(lazy(() => import("./pages/core/entities/entity-saft-files")));
const MyInvoices = Loadable(lazy(() => import("./pages/invoices/my-invoices")));
const MyMembership = Loadable(lazy(() => import("./pages/communities/installations/my-membership")));

const routes = () => {
    return [
        ...authRoutes,
        {
            path: "terms",
            element: <TermsAndConditionsPage />
        },
        {
            path: "home",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path:"",
                    element: <HomePage />
                }
            ] 
        },
        {
            path: "account",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path:"agent",
                    element: <AgentAccountPage />
                },
                {
                    path:"agent/*",
                    element: <AgentAccountPage />
                },
                {
                    path:"member",
                    element: <MyDetails />
                },
                {
                    path:"member/*",
                    element: <MyDetails />
                },
            ] 
        },
        {
            path:"agent",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: "entities/:id",
                    element: <AgentEntityDetailsPage />
                },
            ]
        },
        {
            path:"saft",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path:"",
                    element: <SaftFiles />
                },
            ] 
        },
        {
            path: "myinvoices",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path:"",
                    element: <MyInvoices />
                }
            ] 
        },
        {
            path: "mymemberships",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path:"",
                    element: <MyMembership />
                }
            ] 
        },
        {
            path: "*",
            element: <Error404 />,
        },
        {
            path: "not-found",
            element: <Error404 />,
        },
        {
            path: "admin",
            element: <DashboardLayoutV3 />,
            children: adminRoutes,
        },
        {
            path: "tariff-models",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: "",
                    element: <TariffModellListPage />
                },
                {
                    path: ":id",
                    element: <TariffModelDetailsPage />
                }
            ]

        },
        {
            path: "communities",
            element: <DashboardLayoutV3 />,
            children: communitiesRoutes,
        },
        {
            path: "aggregators",
            element: <DashboardLayoutV3 />,
            children: aggregatorsRoutes,
        },
        {
            path: "markets",
            element: <DashboardLayoutV3 />,
            children: marketsRoutes,
        },
        {
            path: "reports",
            element: <DashboardLayoutV3 />,
            children: reportsRoutes,
        },
        {
            path: "data",
            element: <DashboardLayoutV3 />,
            children: [{
                path: "",
                element: <DataUploadPage />,
            },],
        },
        {
            path: "producers",
            element: <DashboardLayoutV3 />,
            children: producerRoutes,
        },
        {
            path: "clients",
            element: <DashboardLayoutV3 />,
            children: clientRoutes,
        },
        {
            path: "physical-units",
            element: <DashboardLayoutV3 />,
            children: physicalUnitRoutes,
        },
        {
            path:"power-trading",
            element: <DashboardLayoutV3 />,
            children: tradingFindocsRoutes,
        },
        {
            path: "contracts",
            element: <DashboardLayoutV3 />,
            children: contractRoutes,
        },
        {
            path: "members",
            element: <DashboardLayoutV3 />,
            children: membersRoutes,
        },
        {
            path: "entities",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: ":id/*",
                    element: <EntityDetails />
                },
                {
                    path: "",
                    element: <EntityList />
                },
                {
                    path: "add",
                    element: <EntityDetails />
                }
            ],
        },
        {
            path: "consumption-installations",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: "",
                    element: <ConsumptionInstallationList />
                },
                {
                    path: "add",
                    element: <ConsumptionInstallationDetails />
                },
                {
                    path: ":id",
                    element: <ConsumptionInstallationDetails />
                }
            ]

        },
        {
            path: "installations",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: "",
                    element: <InstallationsList />
                },
                {
                    path: "add",
                    element: <InstallationsDetails />
                },
                {
                    path: ":id",
                    element: <InstallationsDetails />
                },
            ]

        },
        {
            path: "energy-management",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: "forecasts",
                    children: forecastRoutes
                },
            ]

        },
        {
            path: "installationsv2",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: ":id",
                    element: <InstallationsDetailsV2 />
                },
            ]

        },
        {
            path: "production-units",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: "",
                    element: <ProductionUnitsList />
                },
                {
                    path: "add",
                    element: <ProductionUnitsDetails />
                },
                {
                    path: ":id",
                    element: <ProductionUnitsDetails />
                },
            ]

        },
        {
            path: "market-units",
            element: <DashboardLayoutV3 />,
            children: tradingUnitRoutes
        },
        {
            path: "storage-installatons",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: "",
                    element: <StorageInstallationList />
                },
            ]

        },
        {
            path: "invoicing",
            element: <DashboardLayoutV3 />,
            children: [
                {
                    path: "communities/*",
                    element: <CommunitiesInvoicingList />
                },
                {
                    name: "label.menu.powertrading",
                    path: "powertrading/*",
                    element: <TradingFinDocsList />,
                },
            ],
        },
    ];
};

const adminRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                path: "agents/:id/*",
                element: <AgentDetailsPage />
            },
            {
                path: "agents",
                element: <AgentListPage />
            }
            
        ]
    }
]
const communitiesRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                path: "communities",
                element: <CommunitiesList />
            },
            {
                path: "installations/*",
                element: <CommunityInstallationsList />
            },
            {
                path: "installations/production",
                element: <CommunityInstallationsList />
            },
            {
                path: "installations/consumption",
                element: <CommunityInstallationsList />
            },
            {
                path: "/communities/installations/:id/*",
                element: <CommunityinstallationsDetails />
            },
            {
                path: "/communities/communities/:community_id/*",
                element: <CommunitiesRouter />
            },
            {
                path: "clients/:id/*",
                element: <ClientDetails />
            },
        ]
    }
]

const tradingFindocsRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                name: "label.menu.powerTrading",
                path: "",
                element: <TradingFinDocsList />,
            },
            {
                name: "label.menu.powerTrading",
                path: "*",
                element: <TradingFinDocsList />,
            },
        ]
    }
];

const clientRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                name: "label.title.invoicingClients",
                path: "",
                element: <ClientsList />,
            },
            {
                name: "label.title.viewContract",
                path: ':clientId/contracts/:id/*',
                element: <ContractsDetails />,
            },
            {
                name: "label.title.invoicingClients",
                path: ":id/*",
                element: <ClientDetails />,
            },
            {
                path: ":id/contracts/:contractId/receipts/balance/:year/:month/*",
                element: <ContractBalanceDetails />
            },
        ]
    }
];

const physicalUnitRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                name: "label.title.invoicingClients",
                path: "",
                element: <ClientsList />,
            },
            {
                name: "label.title.viewContract",
                path: ':clientId/contracts/:id/*',
                element: <ContractsDetails />,
            },
            {
                name: "label.title.invoicingClients",
                path: ":id/*",
                element: <PhysicalUnitDetails />,
            },
        ]
    }
];


const reportsRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                name: "label.title.reports",
                path: "",
                element: <ReportsList />,
            },
            {
                name: "label.title.newReportRequest",
                path: "request",
                element: <ReportRequest />,
            }
        ]
    }
];

const aggregatorsRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                name: "label.title.aggregators",
                path: "",
                element: <AggregatorsList />,
            },
            {
                name: "label.title.aggregators",
                path: ":id/*",
                element: <AggregatorDetails />,
            },
        ]
    },
];

const marketsRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                name: "label.title.dailyMarkets",
                path: "dayahead/*",
                element: <DayAheadMarketPage />,
            },
            {
                name: "label.title.intradayMarkets",
                path: "intraday/*",
                element: <IntradayMarketPage />,
            },
            {
                name: "label.title.marketOfferDetails",
                path: "offers/:id/details",
                element: <TradingMarketOfferDetailsPage />,
            },
            {
                name: "label.title.marketOfferDetails",
                path: "settlements/*",
                element: <SettlementsPage />,
            },
        ]
    }
]

const forecastRoutes = [
    {
        path: "types",
        element: <ModuleContextProvider />,
        children: [
            {
                name: "label.title.forecastTypes",
                path: "",
                element: <ForecastTypesListPage />,
            },
            {
                name: "label.title.forecastTypes",
                path: ":id/instance/add",
                element: <ForecastTypeInstanceAddPage />,
            },
            {
                name: "label.title.forecastTypes",
                path: ":id/locations/:instanceId/",
                element: <ForecastTypeInstanceDetailsPage />,
            },
            {
                name: "label.title.forecastTypes",
                path: ":id/*",
                element: <ForecastTypeDetailsPage />,
            },
        ]
    },
]

const producerRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                name: "label.title.producers",
                path: "",
                element: <ProducersList />,
            },
            {
                name: "label.title.newProducer",
                path: "add/*",
                element: <ProducersNew />
            },
            {
                name: "label.title.viewContract",
                path: ':producerId/contracts/:id/*',
                element: <ContractsDetails />,
            },
            {
                path: ":producerId/contracts/:contractId/receipts/balance/:year/:month/*",
                element: <ContractBalanceDetails />
            },
            {
                name: "label.title.viewProducer",
                path: ":id/*",
                element: <ProducersDetail />,
            },
        ]
    },
]

const contractRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                path: "powertrading",
                element: <ContractsList />,
                
            },
            {
                path: "communities",
                element: <CommunitiesTariffPlansList />,
                
            },
            {
                path: ":contractId/receipts/balance/:year/:month/*",
                element: <ContractBalanceDetails />
            },
            {
                path: ':id/financial-document/new',
                element: <ContractNewFinDoc />
            },
            {
                path: ':id/financial-document/guided-new',
                element: <ContractNewFinDocStepper />
            },
            {
                path: ':id/financial-document/:findocid/void',
                element: <ContractVoidFinDoc />
            },
            {
                path: ':id/financial-document/:findocid/edit',
                element: <ContractEditFinDoc />
            },
            {
                path: ':id/financial-document/:findocid/view',
                element: <ContractViewFinDoc />
            },
            {
                path: ":id/*",
                element: <ContractsDetails />
            },
        ]
    },
];

const membersRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                path: "",
                element: <InstallationListPage />
            },
            {
                path: ":id/*",
                element: <MemberRouter />
            },
        ]
    },
]

const tradingUnitRoutes = [
    {
        path: "",
        element: <ModuleContextProvider />,
        children: [
            {
                name: "label.title.marketUnits",
                path: "",
                element: <MarketPhysicalUnitsList />,
            },
            {
                name: "label.title.aggregators",
                path: ":id/*",
                element: <MarketPhysicalUnitsDetails />,
            },
            {
                name: "label.title.aggregators",
                path: ":trading_unit_id/installations/details/:id",
                element: <InstallationsDetailsV2 />,
            },
        ]
    },
];

const authRoutes = [
    {
        path: "/",
        element: <Navigate to="/home" />,
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "login/agent",
        element: <MarketAgentLogin />
    },
    {
        path: "logout",
        element: <Logout />,
    },
    {
        path: "agent/register/:token",
        element: <AgentRegistrationPage />
    },
    {
        path: "user/register/request",
        element: <UserRegistrationResend />
    },
    {
        path: "user/register/:token",
        element: <UserRegistrationPage />
    },
    {
        path: "user/forget-password/request",
        element: <UserPasswordResetRequest />
    },
    {
        path: "user/forget-password/:token",
        element: <UserPasswordReset />
    }
];

export default routes;
