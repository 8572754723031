import { Box, Button, Stack } from "@mui/material";
import IndieUploadButton from "components/forms/IndieUploadButton";
import { useFormikContext } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CommunityBannerUpload = ({ id, fieldName, imageSrc, imageWidth = 200, showDelete=true, ...props }) => {

    const { t } = useTranslation();
    const [preview, setPreview] = useState(null);
    const formik = useFormikContext();

    const handlePhotoChange = (event) => {
        const file = event.currentTarget.files[0];
        if (file) {
            formik.setFieldValue(fieldName, file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDelete = () => {
        formik.setFieldValue(fieldName, null);
        setPreview(null);
    }

    useEffect(() => {
        if (imageSrc) {
            setPreview(imageSrc);
        }
    }, [imageSrc]);

    return (
        <Fragment>
            {preview && <Box
                sx={{
                    width: '100%',
                    paddingTop: '58.82%', // 1.7 aspect ratio (100 / 1.7)
                    position: 'relative',
                    backgroundColor: 'neutral.main',
                    backgroundImage: `url(${preview})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
           />}
            <label htmlFor="photo-upload" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <input
                    style={{ display: 'none' }}
                    id="photo-upload"
                    name="photo"
                    type="file"
                    onChange={handlePhotoChange}
                />
                <Stack spacing={2} sx={{width: "100%"}} alignItems={"center"}>
                    <IndieUploadButton id="upload_photo" fullWidth component="span" variant="contained" color="primary" label={t("label.form.uploadPhoto")} sx={{width:"200px"}}/>
                    {showDelete &&<Button id="delete" variant="outlined" color="neutral" onClick={handleDelete} sx={{width:"200px"}}>{t("label.form.delete")}</Button>}
                </Stack>
            </label>
        </Fragment>
    )
}

export default CommunityBannerUpload