import { Skeleton } from "@mui/material";
import IndieStaticSelect from "components/forms/IndieStaticSelect";
import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAddressesAPI } from "service/AddressesAPIService";

export const IndieCountryAutoComplete = ({isDisabled, ...params}) => {

    /** MESSAGES & BACKDROP STATE */
    const { setAndShowErrorMessage } = useContext(UserFeedbackContext);

    const { t } = useTranslation();
    const addressesAPI = useAddressesAPI();

    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchCountries = useCallback((data) => {
        setLoading(true);
        addressesAPI.countries(data, (response) => {
            let countriesList = response.data;
            countriesList.map((country) => {
                country.value = country.code;
                country.label = country.description;
                delete country.id;
                delete country.code;
                delete country.description;

            });
            setCountries(countriesList);
            setLoading(false);
        }, (error) => {
            setAndShowErrorMessage("error.api.couldNotLoadCountryList", error);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if(!countries || countries.length === 0) {
            fetchCountries();
        }
    }, [] );

    if(loading) {
        return <Skeleton variant="text" width={200} height={40} />;
    }

    return (
        <IndieStaticSelect options={countries} label={t("label.form.country")} {...params} disabled={isDisabled || params.disabled} />
    );
};
