import { Stack } from "@mui/system";
import IndieTextField from "components/forms/IndieTextField";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IndieCountryAutoComplete } from "./IndieCountryAutoComplete";
import IndieZipCodeField from "./IndieZipCodeField";

const { useFormikContext } = require("formik");

const AddressFormComponents = ({ fieldPrefix = null, ...props}) => {

    const formik = useFormikContext();
    const { t } = useTranslation();

    fieldPrefix = fieldPrefix ? fieldPrefix + "." : "";
    const idFieldPrefix = fieldPrefix.replace(".","_");
    const handleZipCodeChange = useCallback((value) => {
        if ((!value || value.length < 8)) { 
            formik.setFieldValue(`${fieldPrefix}address`, "");
            formik.setFieldValue(`${fieldPrefix}country`, "");
            formik.setFieldValue(`${fieldPrefix}locality`, "");
            formik.setFieldValue(`${fieldPrefix}district`, "");
            formik.setFieldValue(`${fieldPrefix}door_number`, "");
            formik.setFieldValue(`${fieldPrefix}apartment_number`, "");
            return;
        }

        if ((!value || value.length < 8)) {
            return;
        }

        formik.setFieldValue(`${fieldPrefix}zip_code`, value['zip_code']);
        formik.setFieldValue(`${fieldPrefix}locality`, value['locality_description']);
        formik.setFieldValue(`${fieldPrefix}address`, value['address']);
        
        /**
         * When setting the address we base our address info on the CTT tables. However,
         * to maintain compatibility with REN address tables we populate the district_code,
         * county_code and parish_code, which relate to the REN tables.
         */
        if (value['country']) {
            formik.setFieldValue(`${fieldPrefix}country`, value['country']['code']);
            formik.setFieldValue(`${fieldPrefix}country_object`, value['country']['code']);
        }

        if (value['district']) {
            formik.setFieldValue(`${fieldPrefix}district`, value['district']['description']);
            formik.setFieldValue(`${fieldPrefix}district_code`, value['district']['code']);
            formik.setFieldValue(`${fieldPrefix}district_object`, value['district']);
        }

        if(value['county']) {
            formik.setFieldValue(`${fieldPrefix}county`, value['county']);
            formik.setFieldValue('county_code', parseInt(value['county']));
        }

        console.info("<handleZipCodeChange");
    }, []);

    return (
        <Stack spacing={1}>
            <IndieZipCodeField name={`${fieldPrefix}zip_code`} onChange={(o) => handleZipCodeChange(o)} disabled={props.disabled} />
            <IndieTextField id={`${idFieldPrefix}address`} name={`${fieldPrefix}address`} label={t("label.form.address")} maxLength={160} disabled={props.disabled} />
            <Stack direction='row' spacing={2}>
                <IndieTextField id={`${idFieldPrefix}door_number`} name={`${fieldPrefix}door_number`} label={t("label.form.doorNumber")} maxLength={10} sx={{width:150}} disabled={props.disabled} />
                <IndieTextField id={`${idFieldPrefix}apartment_number`} name={`${fieldPrefix}apartment_number`} label={t("label.form.apartmentNumber")} maxLength={10} sx={{width:150}} disabled={props.disabled} />
            </Stack>
            <IndieTextField id={`${idFieldPrefix}locality`} name={`${fieldPrefix}locality`} label={t("label.form.locality")} sx={{ width: 200 }} disabled />
            <IndieTextField id={`${idFieldPrefix}district`} name={`${fieldPrefix}district`} label={t("label.form.district")} sx={{ width: 200 }} disabled />
            <IndieCountryAutoComplete id={`${idFieldPrefix}country`} name={`${fieldPrefix}country`} sx={{ width: 200 }} disabled />
        </Stack>
    );
}



export default AddressFormComponents;