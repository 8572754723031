import { Card, CardContent, CardMedia, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CommunityCardValue from "./CommunityCardValue";
import { Link } from "react-router-dom";

const CommunityStatusButton = ({selected = false, ...props}) => {
    let description = props.description;

    return (
        <Chip
            label={description}
            variant="outlined"
            sx={{ borderColor:  selected ? "white" : "primary.main", color: selected ? "white" : "primary.main", mt: 2 }}
            size="small"
        />
    );
}
const CommunityCardContents = ({selected, ...props}) => {


    const { t } = useTranslation();
    let community = props.community;
    let hideStats = props.hideStats;

    if (!hideStats) {
        return (
            <Grid container spacing={1} mb={2}>
                <CommunityCardValue title="label.table.header.totalMembers" value={parseFloat(community?.total_members || 0)}/>
                <CommunityCardValue title="label.table.header.totalCertifiedPower" value={parseFloat(community?.total_certified_power || 0).toFixed(2)} />
                <CommunityCardValue title="label.table.header.totalProductionInstallations" value={community?.total_production_installations || 0} />
                <CommunityCardValue title="label.table.header.totalConnectionPower" value={(community?.total_connection_power || 0).toFixed(2)} />
                <CommunityCardValue title="label.table.header.totalStoragePower" value={(community?.total_storage_power || 0).toFixed(2)} />
                <CommunityCardValue title="label.table.header.totalStorageCapacity" value={(community?.total_storage_capacity || 0).toFixed(2)} />
                <Grid xs={12} item>
                    <Divider />
                </Grid>
                <CommunityStatusButton value={community?.status} description={t(`label.community.status.${community?.status}`)} selected={selected}/>
            </Grid>
        );
    } else {
        return (
            <Grid container spacing={1} mb={2}>
                <CommunityStatusButton value={community?.status} description={t(`label.community.status.${community?.status}`)} selected={selected}/>
            </Grid>
        );
    }
};

const CommunityCard = ({ community, hideStats=false, withLink=true, selected, ...props }) => {

    if(!community) {
        console.error("CommunityCard: community is null");
        throw new Error("CommunityCard: community is null");
    }

    const renderCard = () => {
        return (
            <Card
                variant="outlined" {...props}
                sx={{
                    cursor: props.disabled ? "default" : "pointer",
                    width: "100%",
                    position: "relative",
                    mr: 1,
                    borderColor: selected ? "primary.main" : "grey.300",
                    bgcolor: selected ? "primary.main" : "disabled",
                    color: selected ? "white" : "default",
                    ...props.sx || {}
                }}>
                <CardMedia
                    component="img"
                    alt={community.name}
                    image={community.banner_image || "/static/images/comunidades_energia_banner_01.jpeg"}
                    sx={{ width: "100%", height: "200px", objectFit: "cover" }}
                />
                <CardContent sx={{position: "relative"}}>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h6">{community.name}</Typography>
                        <CommunityCardContents community={community} hideStats={hideStats} selected={selected} />
                    </Stack>
                </CardContent>
            </Card>
        )
    };

    if(withLink) {
        return (
            <Link to={`/communities/communities/${community.id}`} style={{ textDecoration: "none" }}>
                {renderCard()}
            </Link>
        );
    } else {
        return renderCard();
    }
};

export default CommunityCard;
