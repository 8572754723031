import { FormControl, FormHelperText, TextField } from "@mui/material";
import { useField } from "formik";
import useAuth from "hooks/useAuth";

const IndieTextField = ({ label, maxLength, permissionGroups, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const { hasPermissionGroup } = useAuth();

    if (permissionGroups && !hasPermissionGroup(permissionGroups)) {
        return "";
    }

    return (
        <FormControl id={`form_control_${props.name}`} fullWidth={props.fullWidth}>
            <TextField
                {...field}
                {...props}
                value={field.value ? field.value : ""}
                label={label}
                onChange={(e) => helpers.setValue(e.target.value)}
                error={meta.error}
                variant="standard"
                slotProps={{
                    htmlInput: {maxLength: maxLength ? maxLength : 50, size: maxLength || 250}, 
                }}
                sx={{width: maxLength * 2 || 250, ...props.sx}}
            />
            {meta.error ? (
                <FormHelperText id={`form_error_text_${props.name}`} error sx={{ml:0, pl:0}}>{meta.error}</FormHelperText>
            ) : null}
        </FormControl>

    );
}

export default IndieTextField;