import useAxios from "utils/useAxios";

const BASE_URL = '/community-management/communities/installations/';


export const useCommunityInstallationsAPI = () => {
    
    const { api } = useAxios();
    
    const list = (filters=[], page_size = 30, page = 1, sortColumns) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        return api.get(url);
    }

    const listConsumptionInstallations = (filters=[], page_size = 30, page = 1, sortColumns) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}&type=consumers`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        return api.get(url);
    }

    const listProductionInstallations = (filters=[], page_size = 30, page = 1, sortColumns) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}&type=producers`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        return api.get(url);
    }


    const get = (id) => {
        return api.get(BASE_URL + id);
    }
    
    return {
        list, get, listConsumptionInstallations, listProductionInstallations
    }
};