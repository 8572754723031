import { Skeleton } from "@mui/material";
import IndieStaticSelect from "components/forms/IndieStaticSelect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePhysicalUnitsAPI } from "service/PhysicalUnitsAPIService";

const PhysicalUnitInstallationTypeSelect = (params) => {
    const physicalUnitsAPI = usePhysicalUnitsAPI();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const { t } = useTranslation();
    
    const fetchData = () => {
        physicalUnitsAPI.installationTypes().then((data) => {
            const transformedData = data.map((o) => ({ "value": o.id, "label": o.description, }));
            setItems(transformedData);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    if(loading) {
        return <Skeleton variant="rectangular" width="100%" height={56} />
    }

    return (
        <IndieStaticSelect
            {...params}
            label={t("label.form.installationType")}
            options={items}
            sx={{width: 250}}
        />
    )
}

export default PhysicalUnitInstallationTypeSelect;