import { FormControl, FormHelperText, Skeleton, Stack, Typography } from "@mui/material";
import { useField } from "formik";
import ApplicationList from "page-sections/common/ApplicationList";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCommunitiesAPI } from "service/CommunitiesAPIService";
import { CommunityStatusTableFilter } from "./CommunityStatusSelect";
import { CommunityTypeTableFilter } from "./CommunityTypeSelector";

const CommunitySelectSlider = ({ ...props }) => {

    const [field, meta, helpers] = useField(props);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const api = useCommunitiesAPI();

    const columns = useMemo(() => [
        {
            accessor: 'name',
            Header: t("label.table.header.name"),
        },
        {
            accessor: 'type',
            Header: t("label.table.header.communityType"),
            Cell: ({ value }) => <Typography fontSize={13}>{t(`label.community.type.${value}`)}</Typography>,
            filterComponent: CommunityTypeTableFilter
        },
        {
            accessor: 'status',
            Header: t("label.table.header.status"),
            Cell: ({ value }) => <Typography fontSize={13}>{t(`label.community.status.${value}`)}</Typography>,
            filterComponent: CommunityStatusTableFilter
        },
        {
            accessor: 'dso_id',
            Header: t("label.table.header.communityNumber")
        },
    ], [t]);

    const onCommunitySelected = (community) => {
        helpers.setValue(community.name);
        if (props.onChange) {
            props.onChange(community);
        }
    }

    if (loading) {
        return (
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width={100} height={40} />
                <Skeleton variant="rectangular" width={100} height={40} />
                <Skeleton variant="rectangular" width={100} height={40} />
                <Skeleton variant="rectangular" width={100} height={40} />
            </Stack>
        )
    }

    return (
        <FormControl fullWidth>
            <ApplicationList
                id="community_select_list"
                dataFetcher={api.list}
                columns={columns}
                selectable
                selected={field.value}
                rowOnClick2={(row) => onCommunitySelected(row)}
                outlined
                showAppBar
            />
            {meta.error && meta.touched ? (
                <FormHelperText id="community_select_helper_text" error sx={{ ml: 0, pl: 0 }}>{meta.error}</FormHelperText>
            ) : null}
        </FormControl>
    )
}

export default CommunitySelectSlider;