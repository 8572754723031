import IndieStaticSelect from "components/forms/IndieStaticSelect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePhysicalUnitsAPI } from "service/PhysicalUnitsAPIService";

export const PhysicalUnitTensionLevelSelect = (params) => {

    const physicalUnitsAPI = usePhysicalUnitsAPI();
    const [items, setItems] = useState([]);
    const { t } = useTranslation();

    const fetchData = () => {
        physicalUnitsAPI.tensionLevels((data) => {
            const transformedData = data.map((o) => ({ "value": o.id, "label": o.description, }));
            setItems(transformedData);
        }, (error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <IndieStaticSelect
            {...params}
            label={t("label.form.tensionLevel")}
            options={items}
            sx={{ width: 200 }}
        />
    );
};
