import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const InstallationClassificationCard = ({ classification, selected, onClick, ...props }) => {

    const { t } = useTranslation();

    if (!classification) {
        console.error("InstallationClassificationCard: classification is required");
        throw new Error("InstallationClassificationCard: classification is required");
    }

    const handleClick = () => {
        if (!classification.disabled) {
            onClick();
        }
    }

    return (
        <Card
            key={classification.code}
            variant="outlined"
            onClick={handleClick}
            sx={{
                cursor: classification.disabled ? "default" : "pointer",
                height: "100%",
                borderColor: selected ? "primary.main" : "grey.300",
                bgcolor: selected ? "primary.main" : "disabled",
                color: selected ? "white" : "default",
            }}
            {...props}>
            <CardContent>
                <Stack spacing={2}>
                    <Box sx={{ bgcolor: selected ? "transparent" : "disabled", color:  selected ? "white" : "primary.main" }}>{classification.icon || classification.code}</Box>
                    <Typography variant="subtitle2">{t(`label.installationClassification.${classification.name}`)}</Typography>
                    <Typography variant="body2">{t(`label.installationClassification.${classification.description}`)}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default InstallationClassificationCard;