import { TextField, useTheme } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import 'moment/locale/pt';
import { useTranslation } from "react-i18next";

// Custom filter component for select options
export const DateTableFilter = ({ label, column, setListPage, onKeyUp, ...params }) => {

    const {i18n } = useTranslation();
    const { filterValue, setFilter } = column;
    const theme = useTheme();
    const {id} = params;

    const handleFilterChange = (date) => {
        setListPage(1);
        if(!date) {
            setFilter();
        } else {
            setFilter(moment(date).format("YYYY-MM-DD"));
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
            <DesktopDatePicker
                {...params}
                id={`picker_${id}`}
                onChange={handleFilterChange}
                size="small"
                inputFormat="yyyy-MM-DD"
                value={filterValue || null}
                renderInput={(params) => (
                    <TextField
                        id={`text_field_${id}`}
                        size="small"
                        fullWidth
                        {...params}
                        onKeyUp ={onKeyUp}
                        
                        sx={{
                            backgroundColor:
                                theme.palette.mode === "light" ? "#ECEFF5" : theme.palette.divider,
                            borderRadius: "8px",
                            input: {textAlign: column.align || "left"}
                        }}
                    />
                )} />
        </LocalizationProvider>
    );
};
