import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import { useRoutes } from "react-router-dom";
import routes from "routes";
import { createCustomTheme } from "theme";
import "./i18n";
import { UserFeedbackContextProvider } from "contexts/UserFeedbackContext";
import 'material-icons/iconfont/material-icons.css';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const App = () => {

  const content = useRoutes(routes());
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
        refetchOnMount: false, // default: true
        refetchOnReconnect: false, // default: true
        retry: 1,
        retryDelay: 1000,
        cacheTime: 5 * 60 * 1000, // 10 minutes
      },
    },
  })
  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,

  });
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RTL>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <UserFeedbackContextProvider>
              {content}
            </UserFeedbackContextProvider>
          </QueryClientProvider>
        </RTL>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
