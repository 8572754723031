import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, Link, Stack } from "@mui/material";
import { Small } from "components/Typography";
import { CenteredSmallBox } from "components/flexbox/CenteredSmallBox";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import IndieTextField from "components/forms/IndieTextField";
import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import { Form, Formik } from "formik";
import useAuth from "hooks/useAuth";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { validateFiscalNumber } from "utils/utils";
import * as Yup from "yup";
import { DEFAULT_PAGE_TITLE } from "../../constants";

const MobileLogin = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const { setAndShowErrorMessage, setAndShowSuccessMessage } = useContext(UserFeedbackContext);

    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        username: Yup.mixed()
        .test('valid-username', 'Utilizador deve ser um NIF válido ou email.', (value) => {
            const isFiscalNumber = /^\d{9}$/.test(value) && validateFiscalNumber(value);
            const isEmail = Yup.string().email().isValidSync(value);
            return isFiscalNumber || isEmail;
        }),
        password: Yup.string()
            .required(t("error.forms.mandatory")),
    });

    const submitLogin = (values) => {
        setLoading(true);
        auth.login(values.username, values.password)
            .then(() => {
                setAndShowSuccessMessage(t("message.form.login.sucess"));
                setLoading(false);
                navigate("/");
            })
            .catch((error) => {
                console.error(error);
                setAndShowErrorMessage(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        document.title = DEFAULT_PAGE_TITLE;
    }, []);

    return (
        <CenteredSmallBox>
            <Formik
                initialValues={{
                    username: "",
                    password: "",
                    submit: null,
                    remember: true,
                }}

                validationSchema={validationSchema}
                onSubmit={submitLogin}
                validateOnMount={false}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {() => (
                    <Form>
                        <Card sx={{ p: 6, borderRadius: 6 }}>
                            <CardContent>
                                <Stack spacing={6}>
                                    <FlexRowAlign>
                                        <img src="/static/logo/ie-logo-horizontal.png" height="100px" alt="Indie Energy" />
                                    </FlexRowAlign>
                                    <Stack spacing={2}>
                                        <IndieTextField
                                            id="username"
                                            name="username"
                                            label={t("label.form.username")}
                                            disabled={loading}
                                            sx={{width: "100%"}}
                                        />

                                        <IndieTextField
                                            id="password"
                                            name="password"
                                            type="password"
                                            label={t("label.form.password")}
                                            disabled={loading}
                                            sx={{width: "100%"}}
                                        />

                                        {loading ? (
                                            <LoadingButton loading fullWidth variant="contained">
                                                {t("label.button.signin")}
                                            </LoadingButton>
                                        ) : (
                                            <Button fullWidth type="submit" variant="contained">
                                                {t("label.button.signin")}
                                            </Button>
                                        )}
                                    </Stack>
                                    <Stack spacing={2} sx={{ pt: 4, textAlign: "center"}}>
                                        <Link component={RouterLink} to="/user/forget-password/request"> <Small color="secondary.red">{t("label.form.forgotPassword")}</Small> </Link>
                                        <Link component={RouterLink} to="/user/register/request"><Small>Ainda não se registou? Peça uma nova chave de registo aqui</Small></Link>
                                        <Link href="mailto:suporte@indie-energy.com" target="_blank"><Small>Problemas? Contacte o nosso suporte.</Small></Link>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Form>
                )}
            </Formik>
        </CenteredSmallBox>
    );
}

export default MobileLogin;