import { useState } from "react";

const { IconButton, CircularProgress, FormControl, TextField, InputAdornment } = require("@mui/material")
const { default: SearchIcon } = require("icons/SearchIcon")

const IndieSearchField = ({placeholder, onSearch, loading, searchOnChange = false, ...props}) => {

    const [value, setValue] = useState(props.value);

    const renderIconButton = (loading) => {
        if(loading) {
            return <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <CircularProgress size="1.5rem"/>
            </IconButton>
        } else {
            return <IconButton id="indie_search_icon_button" type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => onSearch(value)}>
                <SearchIcon />
            </IconButton>
        }
    }


    return (
        <FormControl>
            <TextField
                {...props}
                variant="standard"
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    if(searchOnChange) {
                        onSearch(e.target.value);
                    }
                }}
                onKeyUp ={(e) => {
                    if(e.key === "Enter" && loading === false) {
                        onSearch(value);
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {renderIconButton(loading)}
                        </InputAdornment>
                    ),
                }}
                sx={{ width: '400px' }}
                />
        </FormControl>
    )
}


export default IndieSearchField;