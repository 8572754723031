import useAxios from "utils/useAxios";

const BASE_URL = '/community-management/communities/';

export const useCommunitiesAPI = () => {
    
    const {api, nonCachedRequest, cachedRequest} = useAxios();

    const list = (filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        let url = BASE_URL + `?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const fetchById = (id) => {
        if (!id || id == null) {
            throw new Error("ID is required to fetch community details");
        }
        return api.get(`${BASE_URL}${id}`);
    }

    const members = (id, page, page_size = 10, filters=[], callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/members?page_size=5`;
        if (page != null) {
            url += `&page=${page}`;
        }
        filters.map((filter) => url += `&${filter.id.replace('.','__')}__contains=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const sales_docs = (id, page, page_size = 10, filters=[], callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/sales_docs?page_size=${page_size}`;
        if (page != null) {
            url += `&page=${page}`;
        }
        filters.map((filter) => url += "&" + filter.id  +"__contains=" + filter.value);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const purchase_docs = (id, page, page_size = 10, filters=[], callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/purchase_docs?page_size=${page_size}`;
        if (page != null) {
            url += `&page=${page}`;
        }
        filters.map((filter) => url += "&" + filter.id  +"__contains=" + filter.value);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const invoices = (id, page, page_size = 10, filters=[], callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/invoices?page_size=${page_size}`;
        if (page != null) {
            url += `&page=${page}`;
        }
        filters.map((filter) => url += "&" + filter.id  + (filter.type ? "__" + filter.type: "") + "=" + filter.value);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const save = (data, callBack, errorCallBack) => {

        const formData = new FormData();
        Object.keys(data).forEach(key => {
            let value = data[key];
        
            if (value === null || value === undefined) {
              formData.append(key, null);
            } else if (Array.isArray(value)) {
              formData.append(key, JSON.stringify(value));
            } else if (typeof value === 'object' && key !== 'banner_image') {
                formData.append(key, JSON.stringify(value));
            } else {
              formData.append(key, value);
            }
          });

        api.post(BASE_URL, formData).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        })
    };

    const update = (id, data) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            let value = data[key];
        
            if (value === null || value === undefined) {
              formData.append(key, null);
            } else if (Array.isArray(value)) {
              formData.append(key, JSON.stringify(value));
            } else if (typeof value === 'object' && key !== 'banner_image') {
                formData.append(key, JSON.stringify(value));
            } else {
              formData.append(key, value);
            }
          });
        return api.put(BASE_URL + id + "/", formData);
    };

    const updateStatus = (id, status) => {
        return api.post(`${BASE_URL}${id}/update_status/`, {status})
    }

    const updateManagingEntity = (id, entityId) => {
        return api.post(`${BASE_URL}${id}/update_managing_entity/`, {managing_entity: entityId})
    }

    const document_links = (id, page, page_size = 10, filters=[], callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/viewDocuments?page_size=${page_size}`;
        if (page != null) {
            url += `&page=${page}`;
        }
        filters.map((filter) => url += "&" + filter.id  +"__contains=" + filter.value);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const summary = (id, refStartDate, refEndDate, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/kpis/?reference_date__gte=${refStartDate}&reference_date__lte=${refEndDate}`;
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const upload_files = (id, values, callBack, errorCallBack) => {
        api.post(BASE_URL + id + "/uploadDocuments/", values, {
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        })
    };

    const communitiesStatus = (callBack, errorCallBack) => {
        cachedRequest('community-status', `${BASE_URL}status/`, callBack, errorCallBack);
    }

    const createInstallation = (id, data) => {
        return api.post(BASE_URL + id + "/installations/", data);
    }

    const updateInstallation = (id, installationId, data) => {
        return api.patch(BASE_URL + id + "/installations/" + installationId + "/", data);
    }

    const getInstallations = (id, filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/installations?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    const getConsumerInstallations = (id, filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        let url = `${BASE_URL}${id}/installations?page_size=${page_size}&page=${page}&type=consumers`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    const getMembers = (communityId, filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        let url = `${BASE_URL}${communityId}/members?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    }

    const sendRegistrationEmail = (communityId, memberId) => {
        let url = `${BASE_URL}${communityId}/members/${memberId}/send_registration_email`;
        return api.get(url);
    }

    const getCommunityTariffModels = () => {
        return api.get(`${BASE_URL}tariff-models/`);
    }

    return {
        list,
        fetchById,
        members,
        sales_docs,
        purchase_docs,
        document_links,
        invoices,
        save,
        update,
        updateStatus,
        updateManagingEntity,
        summary,
        upload_files,
        communitiesStatus,
        createInstallation,
        getInstallations,
        getConsumerInstallations,
        updateInstallation,
        getMembers,
        sendRegistrationEmail,
        getCommunityTariffModels
    }
};

