import IndieNumberField from "components/forms/IndieNumberField";
import IndieStaticSelect from "components/forms/IndieStaticSelect";
import { useTranslation } from "react-i18next";

const { Stack, Typography, Skeleton } = require("@mui/material");
const { TariffsContext } = require("contexts/tariffs/TariffsContext");
const { useContext, useState, useEffect } = require("react");

const TariffFeeForm = ({ plan, type, name, ...props }) => {

    const { getPlanFees } = useContext(TariffsContext);
    const [fees, setFees] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    if(type !== "power" && type !== "energy") {
        throw new Error("Invalid type: " + type);
    }

    useEffect(() => {
        if(loading) return;
        if(!plan) return;
        
        setLoading(true);
        getPlanFees(plan).then((response) => {
            setFees(response.filter(fee => fee.fee_type === type));
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }, [plan, type, getPlanFees]);

    if(loading) {
        return <Skeleton variant="text" height="60" width={300} />
    }

    if(!fees || fees.length === 0) {
        return null;
    }
    
    return (
        <Stack spacing={2}>
            <Typography variant="subtitle2">{t(`label.form.${type}Fee`)}</Typography>
            <IndieStaticSelect 
                label={t("label.form.fee")}
                options={fees.map(fee => ({ label: fee.description, value: fee.id }))}
                id={name}
                name={name}
                sx={{ width: 300 }}
                {...props}
            />
            <IndieNumberField 
                label={t("label.form.value")}
                id={`${name}_value`}
                name={`${name}_value`}
                sx={{ width: 100 }}
                {...props}
            />
        </Stack>
    )
}

export default TariffFeeForm;