import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InstallationTypeCard = ({ type, selected, onClick, ...props }) => {

    const { t } = useTranslation();

    if (!type) {
        console.error("InstallationTypeCard: type is required");
        throw new Error("InstallationTypeCard: type is required");
    }

    const handleClick = () => {
        if (!type.disabled) {
            onClick();
        }
    }

    return (
        <Card
            key={type.code} variant="outlined" onClick={handleClick}
            sx={{
                cursor: type.disabled ? "default" : "pointer",
                height: "100%",
                borderColor: selected ? "primary.main" : "grey.300",
                bgcolor: selected ? "primary.main" : "disabled",
                color: selected ? "white" : "default",
            }} {...props}>
            <CardContent>
                <Stack spacing={2}>
                    <Avatar sx={{ bgcolor: selected ? "white" : (type.disabled ? "disabled" : "primary.main"), color:  selected ? "primary.main" : "white" }}>{type.icon || type.code}</Avatar>
                    <Typography variant="subtitle1">{t(`label.installationType.${type.name}`)}</Typography>
                    <Typography variant="body2">{t(`label.installationType.${type.description}`)}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default InstallationTypeCard;