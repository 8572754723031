import { FormControl, FormHelperText, TextField } from "@mui/material";
import { useField } from "formik";
import useAuth from "hooks/useAuth";

const IndieNumberField = ({ label, permissionGroups, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const { hasPermissionGroup } = useAuth();

    if (permissionGroups && !hasPermissionGroup(permissionGroups)) {
        return "";
    }

    return (
        <FormControl>
            <TextField
                {...field}
                {...props}
                label={label}
                type="number"
                value={field.value ? field.value : (props.value || "")}
                onChange={(e) => {
                    if (props.onChange) {
                        helpers.setValue(e.target.value);
                        props.onChange(e);
                    } else {
                        helpers.setValue(e.target.value);
                    }
                }}
                error={meta.touched && meta.error ? true : false}
                variant="standard"
                InputProps={{
                    inputProps: {
                        step: 'any', // Allows any number input without fixed increments
                        onWheel: (e) => e.target.blur(), // Prevents changing value on scroll
                        onKeyDown: (e) => {
                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault(); // Disables the arrow key behavior
                            }
                        }
                    },
                    ...props.InputProps
                }}
            />
            {meta.error ? (
                <FormHelperText id={`form_error_text_${props.name}`} error sx={{ ml: 0, pl: 0 }}>{meta.error}</FormHelperText>
            ) : null}
        </FormControl>
    );
}

export default IndieNumberField;