import { Home } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Breadcrumbs, CardContent, Input, Link, Skeleton, Stack, Tab } from "@mui/material";
import IndieCard from "components/IndieCard";
import { H3, Small } from "components/Typography";
import BreadcrumbLink from "components/breadcrumbs/BreadcrumbLink";
import _ from "lodash";
import ApplicationList from "page-sections/common/ApplicationList";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useCommunityInstallationsAPI } from "service/communities/CommunityInstallationsAPIService";

const InstallationListPage = () => {

    const { t } = useTranslation();
    
    const [currentTab, setCurrentTab] = useState(null);
    const tabs = useMemo(() => ["consumption", "production"], []);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        let tab = window.location.pathname.split("/").pop();
        setCurrentTab(_.includes(tabs, tab) ? tab : "consumption");
    }, [tabs]);

    if(!currentTab) {
        return <Skeleton variant="rectangular" height={500} />;
    }

    return (
        <Stack spacing={1}>
        <IndieCard>
                <CardContent>
                    <Stack direction="column" spacing={1}>
                        <Breadcrumbs>
                            <BreadcrumbLink value={"label.menu.home"} href="/" icon={<Home sx={{ mr: 0.5 }} fontSize="inherit" />}/>
                            <BreadcrumbLink value={"label.title.installations"} />
                        </Breadcrumbs>
                        <H3>{t("label.title.installations")}</H3>
                    </Stack>
                </CardContent>
            </IndieCard>
        <IndieCard>
            <CardContent>
                <TabContext value={currentTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}>
                            <Tab data-testid="tab_consumption" label={t("label.title.consumption")} index={0} value="consumption" component={Link} to={"/communities/installations/consumption"} />
                            <Tab data-testid="tab_production" label={t("label.title.production")} index={1} value="production" component={Link} to={"/communities/installations/production"} />
                        </TabList>
                    </Box>
                    <TabPanel value="consumption" sx={{ pt: 2 }}>
                        <ConsumptionInstallationsTab />
                    </TabPanel>
                    <TabPanel value="production" sx={{ pt: 2 }}>
                        <ProductionsInstallationsTab />
                    </TabPanel>
                </TabContext>
            </CardContent>
        </IndieCard>
    </Stack>
    );
};

const ConsumptionInstallationsTab = () => {

    const { t } = useTranslation();
    const api = useCommunityInstallationsAPI();
    const columns = useMemo(() => [
        {
            accessor: 'physical_unit.consumption_deliverypoint',
            Header: t("label.table.header.consumptionDeliverypoint"),
            Cell: ({value, row}) => (
                <Link component={RouterLink} to={`/physical-units/${row.original.physical_unit.id}`}><Small>{value}</Small></Link>
           )
        },
        {
            accessor: 'community.name',
            Header: t("label.table.header.community"),
            Cell: ({value, row}) => (
                 <Link component={RouterLink} to={`/communities/communities/${row.original.community.id}`} target="_blank"><Small>{value}</Small></Link>
            )
        },
        {
            accessor: 'consumer.name',
            Header: t("label.table.header.name")
        },
        {
            accessor: 'consumer.fiscal_number',
            Header: t("label.table.header.fiscalNumber"),
        },
        {
            accessor: 'consumer.email',
            Header: t("label.table.header.email"),
        },
        {
            accessor: 'consumer.phone',
            Header: t("label.table.header.phone"),
        },
        {
            accessor: 'consumer.username',
            Header: t("label.table.header.registered"),
            Cell: ({ value }) => value ? t("label.form.yes") : t("label.form.no"),
            Filter: () => <Input sx={{ visibility: "hidden" }} />
        },
    ], [t]);

    const dataFetcher = useCallback((filters=[], page_size = 30, page = 1, sortColumns, callback, errorCallback) => {
        api.listConsumptionInstallations(filters, page_size, page, sortColumns).then((response) => {
            callback(response);
        }).catch((error) => {
            console.error(error);
            errorCallback(error);
        });
    }, []);

    return (
        <ApplicationList
            id="consumer_installations"
            application={`communities/installations`}
            dataFetcher={dataFetcher}
            showAppBar={true}
            columns={columns}
            outlined
        />
    )
}

const ProductionsInstallationsTab = () => {

    const { t } = useTranslation();
    const api = useCommunityInstallationsAPI();
    const columns = useMemo(() => [
        {
            accessor: 'physical_unit.consumption_deliverypoint',
            Header: t("label.table.header.consumptionDeliverypoint"),
            Cell: ({value, row}) => (
                <Link component={RouterLink} to={`/physical-units/${row.original.physical_unit.id}`}><Small>{value}</Small></Link>
           )
        },
        {
            accessor: 'community.name',
            Header: t("label.table.header.community"),
            Cell: ({value, row}) => (
                 <Link component={RouterLink} to={`/communities/communities/${row.original.community.id}`} target="_blank"><Small>{value}</Small></Link>
            )
        },
        {
            accessor: 'producer.name',
            Header: t("label.table.header.name"),
            
        },
        {
            accessor: 'producer.fiscal_number',
            Header: t("label.table.header.fiscalNumber"),
        },
        {
            accessor: 'producer.email',
            Header: t("label.table.header.email"),
        },
        {
            accessor: 'producer.phone',
            Header: t("label.table.header.phone"),
        },
        {
            accessor: 'producer.receipt_tax_regime.description',
            Header: t("label.table.header.fiscalRegime"),
        },
        {
            accessor: 'producer.receipt_use_auto_invoicing',
            Header: t("label.table.header.autoInvoicing"),
            columnType: "boolean"
        },
        {
            accessor: 'producer.receipt_isatcud',
            Header: t("label.table.header.atcud"),
            columnType: "boolean"
        },
        
        {
            accessor: 'producer.username',
            Header: t("label.table.header.registered"),
            Cell: ({ value }) => value ? t("label.form.yes") : t("label.form.no"),
            Filter: () => <Input sx={{ visibility: "hidden" }} />
        },
    ], [t]);

    const dataFetcher = (filters=[], page_size = 30, page = 1, sortColumns, callback, errorCallback) => {
        api.listProductionInstallations(filters, page_size, page, sortColumns).then((response) => {
            callback(response);
        }).catch((error) => {
            console.error(error);
            errorCallback(error);
        });
    };

    return (
        <ApplicationList
            id="producer_installations"
            application={`communities/installations`}
            dataFetcher={dataFetcher}
            showAppBar={true}
            columns={columns}
            outlined
        />
    )

}

export default InstallationListPage;