import { useParams } from 'react-router-dom';
import CommunityList from './communities-list';
import CommunityDetail from './community-detail';

const CommunitiesRouter = () => {

    const { community_id } = useParams();
    // Check if `id` is a number
    if(!community_id) {
        return <CommunityList />;
    }
    const isInteger = Number.isInteger(Number(community_id));
    return isInteger ? <CommunityDetail /> : <CommunityList />;
};

export default CommunitiesRouter;