import { useTranslation } from "react-i18next";

const { useTheme } = require("@emotion/react");
const { Card, CardContent, Avatar, Typography, Stack } = require("@mui/material");
const { useField } = require("formik");
const { useState, useEffect } = require("react");

const TariffPlanTypeCard = ({ tariffType, ...props }) => {

    const [field, meta, helpers] = useField(props);
    const [selected, setSelected] = useState(field.value === tariffType);
    const theme = useTheme();

    const handleClick = () => {
        helpers.setValue(tariffType);
    }

    const { t } = useTranslation();
    const checkBoxColor = props.disabled ? theme.palette.disabled : theme.palette.primary.main;

    useEffect(() => {
        setSelected(field.value?.code === tariffType?.code || field.value === tariffType?.code);
    }, [field.value]);

    useEffect(() => {
        setSelected(field.value?.code === tariffType?.code || field.value === tariffType?.code);
    }, []);

    return (
        <Card id={`card_${tariffType.code}`} key={tariffType.code} variant="outlined" onClick={handleClick}
            sx={{
                cursor: props.disabled ? "default" : "pointer",
                width: "100%",
                position: "relative",
                borderColor: selected ? "primary.main" : "grey.300",
                bgcolor: selected ? "primary.main" : "disabled",
                color: selected ? "white" : "default",
                height: "330px",
                ...props.sx || {}
            }}
        >
            <CardContent>
                <Stack spacing={4}>
                    <Avatar sx={{
                        bgcolor: selected ? "white" : checkBoxColor,
                        color: selected ? checkBoxColor : "white"
                    }}>
                        <Typography component="span" sx={{ fontFamily: "Material Icons", fontSize: "25px" }}>
                            {tariffType.icon}
                        </Typography >
                    </Avatar>
                    <Stack spacing={1}>
                        <Typography variant="subtitle2">{t(`label.tariffPlanType.${tariffType.code}`)}</Typography>
                        <Typography variant="body2">{t(`label.tariffPlanType.${tariffType.code}.description`)}</Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default TariffPlanTypeCard;