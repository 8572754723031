import { Box, Divider, IconButton, styled, useMediaQuery } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import Scrollbar from "components/ScrollBar";
import ArrowLeftToLine from "icons/duotone/ArrowLeftToLine";
import { ProfilePopoverV2 } from "layouts/layout-parts/popovers/ProfilePopover";
import { useState } from "react";
import MobileSidebar from "./MobileSidebar";
import MultiLevelMenu from "./MultiLevelMenu";

const TOP_HEADER_AREA = 70;
const SidebarWrapper = styled(Box)(({ theme, compact }) => ({
  height: "100vh",
  position: "fixed",
  width: compact === "true" ? 86 : 280,
  transition: "all .2s ease",
  zIndex: theme.zIndex.drawer,
  backgroundColor: theme.palette.background.paper,
  "&:hover": compact === "true" && {
    width: 280,
  },
}));
const NavWrapper = styled(Box)(() => ({
  paddingLeft: 16,
  paddingRight: 16,
  height: "100%",
}));
const StyledArrow = styled(ArrowLeftToLine)(() => ({
  display: "block",
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
})); // -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
const DashboardSidebar = (props) => {
  const {
    sidebarCompact,
    showMobileSideBar,
    setShowMobileSideBar,
    setSidebarCompact,
    permissions,
  } = props;

  const [onHover, setOnHover] = useState(false);
  const downLg = useMediaQuery((theme) => theme.breakpoints.down("lg")); // Activate compact when toggle button clicked and not on hover state

  const COMPACT = sidebarCompact && !onHover ? 1 : 0; //   IF MOBILE

  const getLogo = (compact) => {
    if (compact) {
      return (
        <img src="/static/logo/ie-logo-vertical.png" alt="logo" width={37} />
      );
    } else {
      return (
        <img src="/static/logo/ie-logo-horizontal.png" alt="logo" width={150} />
      );
    }
  }


  if (downLg) {
    return (
      <MobileSidebar
        sidebarCompact={!!COMPACT}
        showMobileSideBar={!!showMobileSideBar}
        setShowMobileSideBar={setShowMobileSideBar}
      />
    );
  }

  return (
    <SidebarWrapper
      compact={sidebarCompact ? "true" : "false"}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => sidebarCompact && setOnHover(false)}
    >
      <FlexBetween pt={3} pr={2} pl={4} pb={1} height={TOP_HEADER_AREA}>
        {/* LOGO */}
        <FlexBox>
          {getLogo(COMPACT)}
        </FlexBox>
        <Box mx={"auto"}></Box>

        {/* SIDEBAR COLLAPSE BUTTON */}
        <StyledIconButton
          onClick={setSidebarCompact}
          sx={{
            display: COMPACT ? "none" : "block",
          }}
        >
          <StyledArrow />
        </StyledIconButton>
      </FlexBetween>

      {/* NAVIGATION ITEMS */}
      <Scrollbar
        autoHide
        clickOnTrack={false}

        sx={{
          overflowX: "hidden",
          height: `calc(100vh - ${TOP_HEADER_AREA}px - 10vh)`,
          marginTop: "1em",
        }}
      >
        <NavWrapper>
          <MultiLevelMenu sidebarCompact={!!COMPACT} permissions={permissions} />
        </NavWrapper>
      </Scrollbar>
      <FlexBetween sx={{ marginTop: 'auto', height: "10vh" }}>
        <Divider />
        <ProfilePopoverV2 compact={COMPACT} />
      </FlexBetween>
    </SidebarWrapper>
  );
};

export default DashboardSidebar;
