import { Home } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const BreadcrumbLink = ({value, href, icon, ...props}) => {

    const { t } = useTranslation();

    const generateLink = () => {
        if(href || props.onClick) {
            return (
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center', fontSize:"small"}}
                    color="inherit"
                    href={href}
                    {...props}
                >
                    {icon}
                    {t(value)}
                </Link>
            );
        } else {
            return (<Typography
                sx={{ display: 'flex', alignItems: 'center', fontSize:"small" }}
                color="text.primary"
                {...props}
            >
                {t(value)}
            </Typography>
            );
        }
    };

    return (
        <Fragment>
            { generateLink() }
        </Fragment>
    );
}

export default BreadcrumbLink;