import { Close, GroupAddOutlined } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, Divider, FormHelperText, Grid2, IconButton, Stack, Step, StepContent, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import IndieBackButton from "components/forms/IndieBackButton";
import IndieContinueButton from "components/forms/IndieContinueButton";
import IndieDesktopDatePicker from "components/forms/IndieDesktopDatePickerV2";
import IndieSaveButton from "components/forms/IndieSaveButton";
import IndieOutlinedCardSection from "components/IndieOutlinedCardSection";
import SelectYesNo from "components/SelectYesNo";
import { DRF_DATE_FORMAT } from "constants";
import { AgentContext, AgentContextProvider } from "contexts/AgentContext";
import { CommunityContext, CommunityContextProvider } from "contexts/communities/CommunityContext";
import { UserFeedbackContext } from "contexts/UserFeedbackContext";
import { Form, Formik, useFormikContext } from "formik";
import _ from "lodash";
import moment from "moment";
import ApplicationList from "page-sections/common/ApplicationList";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTariffsAPIService } from "service/core/TariffsAPIService";
import * as Yup from "yup";
import { IndieLabelAndValue } from "./NewCommunityDialog";
import CommunitySelectSlider from "components/forms/communities/CommunitySelectSlider";
import { PlanFilter, PlanTypeFilter, ProductFilter } from "pages/core/tariffs/tariff-models-list";
import { TariffsContextProvider } from "contexts/tariffs/TariffsContext";
import { EnergyTransactionsSelectFilter } from "components/contracts/tariffs/EnergyTransactionsSelect";

const NewCommunityMemberConfigureTariffPlanDialog = ({ open, onClose, handleSuccess }) => {

    const { setAndShowErrorMessage, setAndShowSuccessMessage } = useContext(UserFeedbackContext);
    const tariffsAPI = useTariffsAPIService();

    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    const [selectedCommunity, setSelectedCommunity] = useState(null);

    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const isMobileVersion = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    const initialValues = {
        selected_community: null,
        selected_tariff_model: null,
        selected_consumers: [],
        selected_producers: [],
        signature_date: moment(),
        start_date: moment(),
        auto_renew: true,
    };

    const step1ValidationSchema = Yup.object().shape({
        selected_community: Yup.object().required(t("error.forms.mandatory")),
    });

    const step2ValidationSchema = Yup.object().shape({
        selected_tariff_model: Yup.object().required(t("error.forms.mandatory")),
    });

    const step3ValidationSchema = Yup.object().shape({
        /*selected_consumers: Yup.array().when('selected_tariff_model', {
            is: (selectedTariffModel) => {console.log('selected_tariff_model in when:', selectedTariffModel);
                return selectedTariffModel?.plan_type.code === 'consumer' || selectedTariffModel?.plan_type.code === 'producers_2_consumer' || selectedTariffModel?.plan_type.code === 'pairs'},
            then: Yup.array().min(1, t("error.forms.newCommunityTariffPlan.atLeastOneConsumer")).required(t("error.forms.newCommunityTariffPlan.consumersRequired")),
            otherwise: Yup.array().notRequired(),
        }),
        selected_producers: Yup.array().when('selected_tariff_model', {
            is: (selectedTariffModel) => {console.log('selected_tariff_model in when for producers:', selectedTariffModel);
                return selectedTariffModel?.plan_type.code === 'producer' || selectedTariffModel?.plan_type.code === 'pairs'},
            then: Yup.array().min(1, t("error.forms.newCommunityTariffPlan.atLeastOneProducer")).required(t("error.forms.newCommunityTariffPlan.producersRequired")),
            otherwise: Yup.array().notRequired(),
        validatePair: Yup.boolean().when('selected_tariff_model', {
            is: (selectedTariffModel) => {console.log('selected_tariff_model in validatePair:', selectedTariffModel); console.log(selectedTariffModel?.plan_type.code); console.log(selectedTariffModel?.plan_type.code === 'pairs');
                return selectedTariffModel?.plan_type.code === 'pairs'},
            then: Yup.boolean().test('is-valid-pair', t('error.forms.newCommunityTariffPlan.exactlyOneConsumerOneProducer'), function(value) {
                console.log('Inside then of validatePair');
                const { selected_consumers, selected_producers } = this.parent;
                console.log('selected_consumers:', selected_consumers);
                console.log('selected_producers:', selected_producers);
                console.log('value in validatePair test:', value);
                if (selected_consumers?.length === 1 && selected_producers?.length === 1) {
                    return true;
                }
                return false;
            }),
            otherwise: Yup.boolean().notRequired(),
        }),*/
    });

    const step4ValidationSchema = Yup.object().shape({
        signature_date: Yup.date().required(t("error.forms.mandatory")),
        start_date: Yup.date().required(t("error.forms.mandatory")),
        auto_renew: Yup.string().required(t("error.forms.mandatory")),
    });

    const step5ValidationSchema = Yup.object().shape({
    });

    const validationSchemas = [step1ValidationSchema, step2ValidationSchema, step3ValidationSchema, step4ValidationSchema, step5ValidationSchema];

    const handleSubmit = (values) => {
        if (activeStep < 4) {
            handleNext();
        } else {
            const _values = _.cloneDeep(values);
            _values['signature_date'] = moment(_values['signature_date']).format(DRF_DATE_FORMAT);
            _values['start_date'] = moment(_values['start_date']).format(DRF_DATE_FORMAT);
            setSubmitting(true);

            tariffsAPI.createCommunityTariffPlan(_values, (response) => {
                setAndShowSuccessMessage(t("success.api.create"));
                setSubmitting(false);
                setActiveStep(0);
                if (handleSuccess) {
                    handleSuccess(response.data);
                }
            }, (error) => {
                setSubmitting(false);
                setAndShowErrorMessage(error);
            });
        }
    };

    const handleCommunityRowClick = (row, formik) => {
        setSelectedCommunity(row);
        console.log(JSON.stringify(row))
        formik.setFieldValue("selected_community", row);
    };

    useEffect(() => {
        setActiveStep(0);
    }, [open]);

    return (
        <Dialog open={open} fullScreen>
            <DialogTitle>
                <Grid2 container>
                    <Grid2 item size={{ xs: 9, sm: 6 }}>
                        <Stack spacing={2} direction="row" alignItems={"center"}>
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                            <GroupAddOutlined color="primary" />
                            <Typography variant="subtitle1" color="textPrimary">{t("label.button.newMember")}</Typography>
                        </Stack>
                    </Grid2>
                    <Grid2 item size={{ xs: 3, sm: 6 }} alignItems={"center"}>
                        <Box sx={{ display: 'flex', flexGrow: 1, height: "100%", alignItems: "center", justifyContent: "flex-end" }}>
                            <Stepper activeStep={2} orientation="horizontal">
                                <Step key={0}>
                                    <StepLabel>{!isMobileVersion && t("label.title.configureInstallation")}</StepLabel>
                                </Step>
                                <Step key={1}>
                                    <StepLabel>{!isMobileVersion && t("label.button.configureMember")}</StepLabel>
                                </Step>
                                <Step key={2}>
                                    <StepLabel>{!isMobileVersion && t("label.title.configureTariffPlan")}</StepLabel>
                                </Step>
                            </Stepper>
                        </Box>
                    </Grid2>
                </Grid2>
            </DialogTitle>
            <DialogContent>
                <Divider fullWidth sx={{ mb: 2 }} />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemas[activeStep]}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step key={0}>
                                    <StepLabel>{t("label.step.newCommunityTariffPlan.communityCodeStep")}</StepLabel>
                                    <StepContent>
                                        <Box maxWidth={"lg"}>
                                            <Stack spacing={2}>
                                                <CommunitySelectSlider name="selected_community" onChange={(c) => handleCommunityRowClick(c, formik)} />
                                                {formik.errors.selected_community && <FormHelperText id="selected_community_helper_text" error>{formik.errors.selected_community}</FormHelperText>}
                                                <Stack direction="row" spacing={2}>
                                                    <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </StepContent>
                                </Step>
                                <Step key={1}>
                                    <StepLabel>{t("label.step.newCommunityTariffPlan.tariffModelStep")}</StepLabel>
                                    <StepContent>
                                        <TariffsContextProvider>
                                            <AgentContextProvider id={selectedCommunity?.agent.id}>
                                                <CommunityContextProvider community={selectedCommunity}>
                                                    <SelectTariffModelStep handleBack={handleBack}/>
                                                </CommunityContextProvider>
                                            </AgentContextProvider>
                                        </TariffsContextProvider>
                                    </StepContent>
                                </Step>
                                <Step key={2}>
                                    <StepLabel>{t("label.step.newCommunityTariffPlan.communityMembersStep")}</StepLabel>
                                        <StepContent>
                                            <CommunityContextProvider community={selectedCommunity}>
                                                <SelectConsumersProducersStep handleBack={handleBack} />
                                            </CommunityContextProvider>
                                    </StepContent>
                                </Step>
                                <Step key={3}>
                                    <StepLabel>{t("label.step.newCommunityTariffPlan.planConfigStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={2}>
                                            <IndieDesktopDatePicker id="signature_date" name="signature_date" label={t("label.form.signatureDate")} sx={{ width: 200 }} />
                                            <IndieDesktopDatePicker id="start_date" name="start_date" label={t("label.form.startDate")} sx={{ width: 200 }} />
                                            <SelectYesNo id="auto_renew" name="auto_renew" label={t("label.form.autoRenew")} />
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton variant="outlined" onClick={handleBack} />
                                                <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                                <Step key={4}>
                                    <StepLabel>{t("label.step.newCommunityTariffPlan.confirmationStep")}</StepLabel>
                                    <StepContent>
                                        <Stack spacing={4}>
                                            <NewCommunityTariffPlanConfirmationStep />
                                            <Stack direction="row" spacing={2}>
                                                <IndieBackButton variant="outlined" onClick={handleBack} disabled={submitting} />
                                                <IndieSaveButton variant="contained" id="save_button" type="submit" loading={submitting} disabled={submitting} />
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                            </Stepper>
                            {/**<pre>{JSON.stringify(formik.errors)}</pre>*/}
                            {/**<pre>{JSON.stringify(formik.values)}</pre>*/}
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const NewCommunityTariffPlanConfirmationStep = () => {

    const { values } = useFormikContext();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Stack spacing={2}>
                <Typography variant='subtitle2' color='textSecondary'>
                    {t("label.step.newCommunityTariffPlan.confirmationStep.subtitle")}
                </Typography>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityTariffPlan.communityCodeStep")}</Typography>
                    <Divider />
                    <IndieLabelAndValue label="label.form.community" value={values.selected_community?.community} />
                    <IndieLabelAndValue label="label.form.name" value={values.selected_community?.name} />
                    <IndieLabelAndValue label="label.form.totalMembers" value={values.selected_community?.total_members} />
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityTariffPlan.tariffModelStep")}</Typography>
                    <Divider />
                    <IndieLabelAndValue label="label.form.name" value={values.selected_tariff_model?.name} />
                    <IndieLabelAndValue label="label.form.planType" value={t(`label.tariffPlanType.${values.selected_tariff_model?.plan_type.code}`)} />
                    <IndieLabelAndValue label="label.form.product" value={t(`label.product.${values.selected_tariff_model?.product.code}`)} />
                    <IndieLabelAndValue label="label.form.plan" value={values.selected_tariff_model?.plan.description} />
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityTariffPlan.communityMembersStep")}</Typography>
                    <Divider />
                    {values.selected_consumers.length > 0 && (
                        <Stack spacing={1}>
                            <Typography variant='subtitle2' color='textSecondary'> {t("label.form.consumers")} </Typography>
                            {values.selected_consumers.map((consumer, index) => (
                                <Fragment key={consumer.id}>
                                    <IndieLabelAndValue label={t("label.form.name")} value={consumer.oferee_party.name} />
                                    <IndieLabelAndValue label={t("label.form.fiscalNumber")} value={consumer.oferee_party.fiscal_number} />
                                    <IndieLabelAndValue label={t("label.form.consumptionDeliveryPoint")} value={consumer.physical_unit.consumption_deliverypoint} />
                                    {index < values.selected_consumers.length - 1 && (
                                        <Box sx={{ width: '18%', margin: '0 auto' }}>
                                            <Divider />
                                        </Box>
                                    )}
                                </Fragment>
                            ))}
                        </Stack>
                    )}
                    {values.selected_producers.length > 0 && (
                        <Stack spacing={1}>
                            <Typography variant='subtitle2' color='textSecondary'> {t("label.form.prosumers")} </Typography>
                            {values.selected_producers.map((producer, index) => (
                                <Fragment key={producer.id}>
                                    <IndieLabelAndValue label={t("label.form.name")} value={producer.oferee_party.name} />
                                    <IndieLabelAndValue label={t("label.form.fiscalNumber")} value={producer.oferee_party.fiscal_number} />
                                    <IndieLabelAndValue label={t("label.form.consumptionDeliveryPoint")} value={producer.physical_unit.consumption_deliverypoint} />
                                    {index < values.selected_producers.length - 1 && (
                                        <Box sx={{ width: '18%', margin: '0 auto' }}>
                                            <Divider />
                                        </Box>
                                    )}
                                </Fragment>
                            ))}
                        </Stack>
                    )}
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='subtitle2' color='textSecondary'>{t("label.step.newCommunityTariffPlan.planConfigStep")}</Typography>
                    <Divider />
                    <IndieLabelAndValue label={t("label.form.signatureDate")} value={values.signature_date ? values.signature_date.format("DD/MM/YYYY") : ""} />
                    <IndieLabelAndValue label={t("label.form.startDate")} value={values.start_date ? values.start_date.format("DD/MM/YYYY") : ""} />
                    <IndieLabelAndValue label={t("label.form.autoRenew")} value={values.auto_renew ? t("label.form.yes") : t("label.form.no")} />
                </Stack>
            </Stack>
        </Fragment>
    )
}

const SelectTariffModelStep = ({ handleBack }) => {

    const { t } = useTranslation();
    const { getCommunitiesTariffModels } = useContext(AgentContext);
    const { community } = useContext(CommunityContext);

    const formik = useFormikContext();

    const columns = useMemo(() => [
        { accessor: "name", Header: t("label.table.header.name"), },
        {
            accessor: "plan_type.id",
            Header: t("label.table.header.planType"),
            Cell: ({ row }) => t(`label.tariffPlanType.${row.original.plan_type.code}`),
            filterComponent: ({ column, fetchData, state }) => <PlanTypeFilter column={column} fetchData={fetchData} state={state} />,
        },
        {
            accessor: "product.id",
            Header: t("label.table.header.product"),
            Cell: ({ row }) => t(`label.product.${row.original.product.code}`),
            filterComponent: ({ column, fetchData, state }) => <ProductFilter column={column} fetchData={fetchData} state={state} />,
        },
        {
            accessor: "energy_transaction.id",
            Header: t("label.table.header.product"),
            Cell: ({ row }) => t(`label.energyTransaction.${row.original.energy_transaction.code}`),
            filterComponent: ({ column, fetchData, state }) => <EnergyTransactionsSelectFilter column={column} fetchData={fetchData} state={state} />,
        },
        {
            accessor: "plan.id",
            Header: t("label.table.header.plan"),
            Cell: ({ row }) => {
                return <Typography variant="body2">{row.original.plan.description}</Typography>
            },
            filterComponent: ({ column, fetchData, state }) => <PlanFilter column={column} fetchData={fetchData} state={state} />,
        },
    ], [t]);

    const getCommunitiesTariffModelsWrapper = (filters=[], page_size, page, sortColumns, callBack, errorCallBack) => {
        const communityFilter = { id: "community", value: community.id};
        filters.push(communityFilter);
        getCommunitiesTariffModels(filters, page_size, page, sortColumns, callBack, errorCallBack)
    }


    const onRowChange = (row) => {
        formik.setFieldValue("selected_tariff_model", row);
    };

    return (
        <Box maxWidth={"lg"}>
            <Stack spacing={2}>
                <ApplicationList
                    id="tariff_models_list"
                    dataFetcher={getCommunitiesTariffModelsWrapper}
                    columns={columns}
                    rowOnClick2={onRowChange}
                    selectable
                    selected={formik.values.selected_tariff_model}
                    outlined
                    showAppBar
                />

                {formik.errors.selected_tariff_model && <FormHelperText id="selected_tariff_model_helper_text" error>{formik.errors.selected_tariff_model}</FormHelperText>}
                <Stack direction="row" spacing={2}>
                    <IndieBackButton variant="outlined" onClick={handleBack} />
                    <IndieContinueButton id="continue_button" variant="contained" type="submit" />
                </Stack>
            </Stack>
        </Box>
    )
}

const SelectConsumersProducersStep = ({ handleBack }) => {

    const { t } = useTranslation();
    const formik = useFormikContext();
    const selectedTariffModel = formik.values.selected_tariff_model;
    const { getConsumerMembers, getProducerMembers } = useContext(CommunityContext);

    const consumersColumns = useMemo(() => [
        { accessor: 'oferee_party.name', Header: t("label.table.header.name"), },
        { accessor: 'oferee_party.fiscal_number', Header: t("label.table.header.fiscalNumber"), },
        { accessor: 'physical_unit.consumption_deliverypoint', Header: t("label.table.header.consumptionDeliverypoint") },
    ], [t]);

    const producersColumns = useMemo(() => [
        { accessor: 'oferee_party.name', Header: t("label.table.header.name"), },
        { accessor: 'oferee_party.fiscal_number', Header: t("label.table.header.fiscalNumber"), },
        { accessor: 'physical_unit.consumption_deliverypoint', Header: t("label.table.header.consumptionDeliverypoint") },
    ], [t]);

    const handleRowClick = (selectedItems, formik, fieldName) => {
        formik.setFieldValue(fieldName, selectedItems);
    };

    return (
        <Stack spacing={2}>
            <Stack spacing={2} direction={"row"}>
                {(selectedTariffModel?.plan_type.code === 'consumer' || selectedTariffModel?.plan_type.code === 'pairs' || selectedTariffModel?.plan_type.code === 'producers_2_consumer') && (
                    <Box flex={1} maxWidth={"md"}>
                        <IndieOutlinedCardSection title={t("label.form.consumers")}>
                            <ApplicationList
                                id="consumers_list"
                                dataFetcher={getConsumerMembers}
                                columns={consumersColumns}
                                rowOnClick2={(selectedValues) => handleRowClick(selectedValues, formik, "selected_consumers")}
                                selectable
                                multiple
                                selected={formik.values.selected_consumers}
                                outlined
                                showAppBar
                            />
                            {formik.errors.selected_consumers && <FormHelperText id="selected_consumers_helper_text" error>{formik.errors.selected_consumers}</FormHelperText>}
                        </IndieOutlinedCardSection>
                    </Box>
                )}
                {(selectedTariffModel?.plan_type.code === 'producer' || selectedTariffModel?.plan_type.code === 'pairs') && (
                    <Box flex={1} maxWidth={"md"}>
                        <IndieOutlinedCardSection title={t("label.menu.producers")}>
                            <ApplicationList
                                id="producers_list"
                                dataFetcher={getProducerMembers}
                                columns={producersColumns}
                                rowOnClick2={(selectedValues) => handleRowClick(selectedValues, formik, "selected_producers")}
                                selectable
                                multiple
                                selected={formik.values.selected_producers}
                                outlined
                                showAppBar
                            />
                            {formik.errors.selected_producers && <FormHelperText id="selected_producers_helper_text" error>{formik.errors.selected_producers}</FormHelperText>}
                        </IndieOutlinedCardSection>
                    </Box>
                )}
            </Stack>
            <Stack direction="row" spacing={2}>
                <IndieBackButton variant="outlined" onClick={handleBack} />
                <IndieContinueButton id="continue_button" variant="contained" type="submit" />
            </Stack>
        </Stack>
    )

}
export default NewCommunityMemberConfigureTariffPlanDialog;