import { set } from "lodash";
import IndieCheckbox from "../IndieCheckbox";
import IndieIBANTextField from "../IndieIBANTextField";
import { IndieTaxRegimesSelect } from "../IndieTaxRegimesSelect";

const { Stack, Grid2, Typography } = require("@mui/material");
const { default: IndieFiscalNumberTextField } = require("../IndieFiscalNumberTextField");
const { default: IndieTextField } = require("../IndieTextField");
const { default: IndieNumberField } = require("../IndieNumberField");
const { default: AddressFormComponents } = require("components/addresses/AddressFormComponents");
const { useTranslation } = require("react-i18next");
const { useEntitiesAPI } = require("service/EntitiesAPIService");
const { useFormikContext } = require("formik");
const { useContext, useState, useEffect } = require("react");
const { validateFiscalNumber } = require("utils/utils");
const { UserFeedbackContext } = require("contexts/UserFeedbackContext");

const EntityFormFields = ({ company = false, disableFiscalNumber, showReceiptFormFields = false, showBankFormFields, disableReceiptFormFields = false, prefix, manual, ...props }) => {

    const _prefix = prefix ? prefix + "." : "";
    const id_prefix = prefix ? prefix + "_" : "";

    const { t } = useTranslation();
    const api = useEntitiesAPI();
    const { setAndShowErrorMessage } = useContext(UserFeedbackContext);
    const formik = useFormikContext();

    const [loadingEntity, setLoadingEntity] = useState(false);
    const [existingEntity, setExistingEntity] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const fetchEntityByFiscalNumber = async (event) => {
        const fiscalNumber = event.target.value;
        if (validateFiscalNumber(fiscalNumber)) {

            if (manual) {
                return;
            }

            setLoadingEntity(true);
            api.fetchByFiscalNumber(fiscalNumber, (response) => {
                let results = response.data.results;
                if (results.length > 0) {
                    let result = results[0];
                    formik.setFieldValue(`${_prefix}id`, result.id);
                    formik.setFieldValue(`${_prefix}name`, result.name);
                    formik.setFieldValue(`${_prefix}phone`, result.phone);
                    formik.setFieldValue(`${_prefix}email`, result.email);
                    formik.setFieldValue(`${_prefix}cae`, result.cae);
                    formik.setFieldValue(`${_prefix}zip_code`, result.zip_code);
                    formik.setFieldValue(`${_prefix}address`, result.address);
                    formik.setFieldValue(`${_prefix}locality`, result.locality);
                    formik.setFieldValue(`${_prefix}district`, result.district);
                    formik.setFieldValue(`${_prefix}country`, result.country);
                    formik.setFieldValue(`${_prefix}district_code`, result.district_code);
                    formik.setFieldValue(`${_prefix}county`, result.county);

                    if (result.iban) {
                        formik.setFieldValue(`${_prefix}iban`, result.iban);
                    } else {
                        formik.setFieldValue(`${_prefix}iban`, "");
                    }

                    setExistingEntity(true);
                }
                setLoadingEntity(false);
            }, (error) => {
                setLoadingEntity(false);
                setAndShowErrorMessage(error);
            });
        } else if (fiscalNumber.length === 0) {
            formik.setFieldValue(`${_prefix}id`, null);
            formik.setFieldValue(`${_prefix}name`, "");
            formik.setFieldValue(`${_prefix}phone`, "");
            formik.setFieldValue(`${_prefix}email`, "");
            formik.setFieldValue(`${_prefix}cae`, "");
            formik.setFieldValue(`${_prefix}zip_code`, "");
            formik.setFieldValue(`${_prefix}address`, "");
            formik.setFieldValue(`${_prefix}locality`, "");
            formik.setFieldValue(`${_prefix}district`, "");
            formik.setFieldValue(`${_prefix}country`, "");
            formik.setFieldValue(`${_prefix}district_code`, "");
            formik.setFieldValue(`${_prefix}county`, "");
            formik.setFieldValue(`${_prefix}iban`, "");
            formik.setFieldValue(`${_prefix}iban_disabled`, false);
            setExistingEntity(false);
        }
    }

    useEffect(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);

    useEffect(() => {
        setExistingEntity(formik.values && formik.values[prefix] && formik.values[prefix]["id"] !== null && formik.values[prefix]["id"] !== "" && formik.values[prefix]["id"] !== undefined ? true : false);
    }, []);

    return (
        <Grid2 container spacing={4}>
            <Grid2 item size={{ xs: 12, sm: 6, lg: 4 }}>
                <Stack spacing={3}>
                    <Typography variant="subtitle2">{t("label.title.personalCompanyInformation")}</Typography>
                    <Stack spacing={1}>
                        <IndieFiscalNumberTextField
                            id={`${id_prefix}fiscal_number`}
                            name={`${_prefix}fiscal_number`}
                            label={t("label.form.fiscalNumber")}
                            sx={{ width: 200 }}
                            onChangeField={fetchEntityByFiscalNumber}
                            loading={loadingEntity}
                            disabled={props.disabled || disableFiscalNumber}
                            manual
                        />
                        <IndieTextField id={`${id_prefix}name`} name={`${_prefix}name`} label={company ? t("label.form.companyName") : t("label.form.name")} sx={{ width: 350 }} disabled={disabled} maxLength={200} />
                        <IndieTextField id={`${id_prefix}phone`} name={`${_prefix}phone`} label={t("label.form.phone")} sx={{ width: 200 }} disabled={disabled} maxLength={150} />
                        <IndieTextField id={`${id_prefix}email`} name={`${_prefix}email`} label={t("label.form.email")} sx={{ width: 200 }} disabled={disabled} maxLength={150} />
                        {company ? <IndieNumberField id={`${id_prefix}cae`} name={`${_prefix}cae`} label={t("label.form.cae")} sx={{ width: 200 }} disabled={disabled} /> : null}
                        {company ? (
                            <IndieTextField
                                id={`${id_prefix}access_perm_certificate`} name={`${_prefix}access_perm_certificate`}
                                label={t("label.form.permanentCertificate")} sx={{ width: 200 }}
                                type="password" disabled={disabled} />
                            ) : null}
                    </Stack>
                </Stack>
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 6, lg: 4 }}>
                <Stack spacing={3}>
                    <Typography variant="subtitle2">{t("label.title.addressInformation")}</Typography>
                    <AddressFormComponents fieldPrefix={prefix} disabled={disabled} />
                </Stack>
            </Grid2>
            {(showReceiptFormFields || showBankFormFields) ? (
                <Grid2 item size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Stack spacing={3}>
                        <Typography variant="subtitle2">{t("label.title.fiscalFinancialInformation")}</Typography>
                        <Stack spacing={1}>
                            {showReceiptFormFields ? (
                                <IndieTaxRegimesSelect
                                    id={`${id_prefix}receipt_tax_regime`}
                                    name={`${_prefix}receipt_tax_regime`}
                                    label={t("label.form.taxRegime")}
                                    disabled={disableReceiptFormFields || disabled}
                                    country="PT"
                                />
                            ) : null}
                            {showReceiptFormFields ? <IndieCheckbox id={`${id_prefix}receipt_has_open_activity`} name={`${_prefix}receipt_has_open_activity`} label={t("label.form.hasOpenActivity")} disabled={disableReceiptFormFields || disabled} /> : null}
                            {showBankFormFields ? <IndieIBANTextField id={`${id_prefix}iban`} name={`${_prefix}iban`} label={t("label.form.paymentIBAN")} sx={{ width: 400 }} disabled={disabled} /> : null}
                        </Stack>
                    </Stack>
                </Grid2>
            ) : null }
        </Grid2>
    );
};

export default EntityFormFields;