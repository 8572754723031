import useAxios from "utils/useAxios";

export const useTariffsAPIService = () => {
    
    const { api, cachedRequest2, nonCachedRequest } = useAxios();
    const BASE_URL = '/core/tariffs';
    const COMMUNITIES_URL = '/community-management/tariffs';
    const POWERTRADING_URL = '/powertrading/tariffs';


    const planTypes = (businessType = null) => {
        if(businessType !== null) {
            return cachedRequest2(`${businessType}-plan-types`, `${BASE_URL}/plan-types?business_type=${businessType}`);
        } else {
            return cachedRequest2(`all-plan-types`, `${BASE_URL}/plan-types`);
        }
        
    }

    const planTypeProducts = (planTypeId) => {
        if(planTypeId) {
            return api.get(`${BASE_URL}/products?plan_type=${planTypeId}`);
        } else {
            return api.get(`${BASE_URL}/products`);
        }
        
    }

    const productPlans = (productId) => {
        if(productId) {
            return cachedRequest2(`product-${productId}-plans`, `${BASE_URL}/plans?product=${productId}`);
        } else {
            return cachedRequest2(`product-plans`, `${BASE_URL}/plans`);
        }
        
    }

    const planFees = (planId) => {
        return api.get(`${BASE_URL}/fees?plan=${planId}`);
    }

    const energyTransactions = () => {
        return cachedRequest2('energy-transactions', `${BASE_URL}/energy-transactions`);
    }

    const receiptMethods = () => {
        return cachedRequest2('receipt-methods', `${BASE_URL}/receipt-methods`);
    }

    const receiptTerms = () => {
        return cachedRequest2('receipt-terms', `${BASE_URL}/receipt-terms`);
    }

    const paymentMethods = () => {
        return cachedRequest2('payment-methods', `${BASE_URL}/payment-methods`);
    }

    const paymentTerms = () => {
        return cachedRequest2('payment-terms', `${BASE_URL}/payment-terms`);
    }

    const invoicingCycles = () => {
        return cachedRequest2('billing-cycles', `${BASE_URL}/billing-cycles`);
    }

    const submitTariffModel = (tariffModel) => {
        return api.post(`${BASE_URL}/models/`, tariffModel);
    }

    const getTariffModels = (filters=[], page_size = 30, page = 1, sortColumns, callBack, errorCallBack) => {
        let url = BASE_URL + `/models?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        nonCachedRequest(url, callBack, errorCallBack);
    };

    const getTariffModel = (id) => {
        return api.get(`${BASE_URL}/models/${id}`);
    }

    const updateTariffModel = (id, tariffModel) => {
        return api.patch(`${BASE_URL}/models/${id}/`, tariffModel);
    }

    const deleteTariffModel = (id) => {
        return api.delete(`${BASE_URL}/models/${id}`);
    }

    const copyTariffModel = (id) => {
        return api.post(`${BASE_URL}/models/${id}/copy/`);
    }

    const getCommunitiesTariffsList = (filters=[], page_size = 30, page = 1, sortColumns) => {
        let url = COMMUNITIES_URL + `?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        return api.get(url);
    }

    const getPowerTradingTariffsList = (filters=[], page_size = 30, page = 1, sortColumns) => {
        let url = POWERTRADING_URL + `?page_size=${page_size}&page=${page}`;
        if (sortColumns) {
            url += `&ordering=${sortColumns}`
        }
        filters.map((filter) => url += `&${filter.id.replaceAll('.','__')}=${filter.value}`);
        return api.get(url);
    }

    const createCommunityTariffPlan = (data, callBack, errorCallBack) => {

        const requestData = {
            community_id: data.selected_community?.id, 
            tariff_model_id: data.selected_tariff_model?.id, 
            producers: data.selected_producers.map(producer => producer.id), 
            consumers: data.selected_consumers.map(consumer => consumer.id), 
            signature_date: data.signature_date,  
            start_date: data.start_date,  
            automatic_renewal: data.auto_renew === "1",  
        };

        api.post(COMMUNITIES_URL + '/create/', requestData).then((response) => {
            callBack(response);
        }).catch((e) => {
            errorCallBack(e);
        })
    };

    return {
        planTypes,
        planTypeProducts,
        productPlans,
        planFees,
        energyTransactions,
        receiptMethods,
        receiptTerms,
        paymentMethods,
        paymentTerms,
        invoicingCycles,
        submitTariffModel,
        getTariffModels,
        getTariffModel,
        updateTariffModel,
        deleteTariffModel,
        copyTariffModel,
        getCommunitiesTariffsList,
        getPowerTradingTariffsList,
        createCommunityTariffPlan
    }
};

